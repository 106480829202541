<template lang="pug">
div(class="invoiceFooter")
    .row()
      .col-sm-6.mt-1.invoiceFooterLeft(style="page-break-inside: avoid;")
        PaymentConditions.mt-1(:preview="true" :documentType="documentType")
        TotalTva.mt-2(v-if="documentType == null")
      div.col-sm-6.mt-2.invoiceFooterRight(v-if="documentType == null")
        TotalDocument(@updateTvaDetails='updateTvaDetails' :canEditDiscount='true' :preview="true" ref="TotalDocument" v-if="!documentOptions.hideTotal")
        div.print-signature.mt-2.border.rounded.content-total(style="page-break-inside: avoid;" v-show="document.nature==0")
            | Date et signature du client précédée de la mention "Lu et approuvé, bon pour accord" :
    .print-footer.mb-1(ref="printFooter")
        span(v-html="document.footer")
</template>
<script>
import PaymentConditions from "@/components/invoice/builder/PaymentConditions";
import TotalDocument from "@/components/invoice/builder/TotalDocument";
import AddGlobalDiscount from "@/components/invoice/builder/AddGlobalDiscount";
import TotalTva from "@/components/invoice/builder/TotalTva";
import { mapGetters } from "vuex";

export default {
  props: {
    documentType: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      tvaDetails: [],
      printFooterHeight: 0,
    };
  },
  computed: {
    ...mapGetters(["document"]),
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
  },
  methods: {
    updateTvaDetails(val) {
      this.tvaDetails = val;
    },
  },
  components: {
    PaymentConditions,
    TotalDocument,
    AddGlobalDiscount,
    TotalTva,
  },
};
</script>
