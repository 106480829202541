<template lang="pug">
  //- div {{invoiceHeader[dataKey].inputType}} 
  div(v-if="invoiceHeader[dataKey].inputType == 1") 
    TextCol(:disabled="disabled" @emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :invoiceHeader="invoiceHeader" :edit="edit" :editFocus="editFocus")
  //- div(v-else-if="invoiceHeader[dataKey].inputType == 2") 
  //-   NumberCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :invoiceHeader="invoiceHeader" :edit="edit" :editFocus="editFocus")
  div(v-else-if="invoiceHeader[dataKey].inputType == 21")
    QuillEditorCol(:disabled="disabled" @emitEditFocus="emitEditFocus" :line="line" :dataKey="dataKey" :invoiceHeader="invoiceHeader" :canEdit="true" :edit="edit" :editFocus="editFocus" :mouseOn="mouseOn")
</template>
<script>
import TextCol from '@/components/invoice/builder/table/cols/TextCol.vue'
import NumberCol from '@/components/invoice/builder/table/cols/NumberCol.vue'
import QuillEditorCol from '@/components/invoice/builder/table/cols/QuillEditorCol.vue'

export default {
  data () {
    return {}
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      required: true
    },
    invoiceHeader: {
      type: Object,
      required: true
    },
    dataKey: {
      type: String,
      required: true
    },
    editFocus: {
      type: Boolean,
      required: true
    },
    mouseOn: {
      type: Boolean,
      required: true
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    }
  },
  components: {
    TextCol,
    NumberCol,
    QuillEditorCol
  }
}
</script>
