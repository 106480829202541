<template lang="pug"> 
  td
    div(v-if="invoiceHeader[dataKey].inputType == 24") 
      div
        span {{ line.isOption == true ? 'Sous-total des options' : 'Sous-total' }}
    div(v-else-if="dataKey == 'subtotal'")
      div(style="text-align:right; width: 100%;" v-if="!documentOptions.hidePriceHtColumn")
        span {{ formatCurrency(line.total) }}
    div(v-else-if="invoiceHeader[dataKey].inputType == 1")
      div
        span {{line[dataKey]}}
</template>

<script>
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    indexArray: {
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    invoiceHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    formatCurrency,
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  computed: {
    ...mapGetters(["document"]),
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
  },
};
</script>
