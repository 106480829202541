<template lang="pug">
  div(v-if="!preview || (preview && deduction && deduction.punctualDeductions && deduction.punctualDeductions.length > 0)")
    div.mb-1(v-if="deduction && deduction.punctualDeductions && deduction.punctualDeductions.length > 0 && isDisplayPunctualDeduction")
      div.d-flex.align-items-center.w-100(v-for="(item, index) in deduction.punctualDeductions") 
        span(@mouseover="showTools = true" @mouseleave="showTools = false") {{ item.label }} de {{ formatPercent(item.percent) }} soit {{ formatCurrency(item.number) }} TTC
            feather-icon.feather-icon-punctual-deduction.cursor-pointer.ml-1.feather(v-if="!preview && showTools && !disabled" icon="Edit3Icon" size="18"  @click="showUpdatePunctualDeduction(index)" v-b-tooltip.hover.top="'Modifier'") 
            feather-icon.feather.cursor-pointer(v-if="!preview && showTools && !disabled" icon="XIcon" size="18"  @click="deletePunctualDeduction(index, item)" v-b-tooltip.hover.top="'Retirer'")
    div.mb-1.mt-1.d-flex(v-if='editPunctualDeduction && !preview')
        .d-flex.align-items-center
            input.input-punctual-deduction.form-control.form-control-sm.mr-1(v-model='label' name='punctual_deduction_label')
            div(style='min-width: fit-content;') de
            input.input-number-punctual-deduction.form-control.form-control-sm.ml-1.mr-1(@blur="changeValueByPercent" v-model='percent' name='punctual_deduction_percent' type="number")
            div(style='min-width: fit-content;') % soit
            input.input-number-punctual-deduction.form-control.form-control-sm.ml-1.mr-1(@blur="changePercentByValue" v-model='number' name='punctual_deduction_number' type="number")
            div(style='min-width: fit-content;') € TTC
            b-button.form-control-sm.p-0.ml-1(v-if="!disabled" style="padding:0px 5px !important" variant="primary" type="small" @click="pushPunctualDeduction") 
                feather-icon(icon='SaveIcon' size="20")
            b-button.form-control-sm.p-0.ml-1(v-if="!disabled" style="padding:0px 5px !important" variant="outline-primary" @click="togglePunctualDeduction(false)") 
                feather-icon(icon='XIcon' size="20")
</template>
<script>
import { BButton } from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import {
  formatCurrency,
  formatPercent,
} from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  data() {
    return {
      showTools: false,
      isDisplayPunctualDeduction: true,
      number: 0,
      percent: 0,
      label: null,
      isUpdateStatus: false,
      index: null,
    };
  },
  props: {
    preview: {
      default: false,
    },
    editPunctualDeduction: {
      type: Boolean,
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  components: {
    BButton,
  },
  computed: {
    ...mapGetters(["document"]),
    deduction: {
      get() {
        return this.$store.getters["deduction"];
      },
      set(value) {
        return this.$store.commit("SET_DEDUCTION", value);
      },
    },
  },
  methods: {
    ...mapActions(["createDeduction", "updateDeduction"]),
    formatCurrency,
    formatPercent,
    changePercentByValue(res) {
      this.percent =
        Math.round((this.number / this.document.totalTtc) * 100 * 100) / 100;
    },
    changeValueByPercent(res) {
      if (res.target.value > 100) {
        this.percent = 100;
        this.number =
          Math.round((this.percent / 100) * this.document.totalTtc * 100) / 100;
      } else if (res.target.value < -100) {
        this.percent = -100;
        this.number =
          Math.round((this.percent / 100) * this.document.totalTtc * 100) / 100;
      } else {
        this.number =
          Math.round((this.percent / 100) * this.document.totalTtc * 100) / 100;
      }
    },
    pushPunctualDeduction() {
      if (this.number && this.label) {
        if (this.isUpdateStatus == true) {
          this.deduction.punctualDeductions[this.index].number = this.number;
          this.deduction.punctualDeductions[this.index].label = this.label;
          this.deduction.punctualDeductions[this.index].percent = this.percent;
          this.isUpdateStatus = false;
        } else {
          this.deduction.punctualDeductions.push({
            number: this.number,
            label: this.label,
            percent: this.percent,
          });
        }
        this.isDisplayPunctualDeduction = true;
        if (this.deduction.id == 0) {
          this.createDeduction({ deduction: this.deduction });
        } else {
          this.updateDeduction({ deduction: this.deduction });
        }
        this.togglePunctualDeduction(false);
      }
    },
    togglePunctualDeduction(bool = false) {
      this.label = null;
      this.number = 0;
      this.percent = 0;
      this.isDisplayPunctualDeduction = true;
      this.$emit("togglePunctualDeduction", bool);
    },
    showUpdatePunctualDeduction(index) {
      this.isUpdateStatus = true;
      this.index = index;
      this.isDisplayPunctualDeduction = false;
      this.number = this.deduction.punctualDeductions[index].number;
      this.percent = this.deduction.punctualDeductions[index].percent;
      this.label = this.deduction.punctualDeductions[index].label;
      this.$emit("togglePunctualDeduction", true);
    },
    deletePunctualDeduction(index, item) {
      this.deduction.punctualDeductions.splice(index, 1);
      this.updateDeduction({ deduction: this.deduction });
    },
  },
};
</script>

<style scoped>
.input-number-punctual-deduction {
  border: solid 1px #ccc !important;
  background-color: white !important;
  border-radius: 3px;
  padding: 8px;
  width: 65px;
  height: 30px;
  border-radius: 0.357rem;
}

.input-punctual-deduction {
  border: solid 1px #ccc !important;
  background-color: white !important;
  border-radius: 3px;
  padding: 8px;
  width: 180px;
  height: 30px;
  border-radius: 0.357rem;
}
.feather-icon-punctual-deduction {
  margin-right: 5px;
}

.datepicker-punctual-deduction {
  height: 30px !important;
  width: 200px;
}
</style>

<style>
.datepicker-punctual-deduction > .text-break {
  padding: 0rem 1rem !important;
}
</style>
