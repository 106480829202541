<template lang="pug">
  div(v-if="!preview || (preview && deduction.prorataAccountPrice>0)")
    div.d-flex.mb-1(v-if="isDisplayProrataAccount && !editProrataAccount")
        span(@mouseover="showTools = true" @mouseleave="showTools = false") Compte de prorata {{ deduction.prorataAccountLabel }} de {{ formatPercent(deduction.prorataAccountPercent) }} soit {{ formatCurrency(deduction.prorataAccountPrice) }} TTC
            feather-icon.cursor-pointer.feather-icon-prorata-account.ml-1.feather(v-if="!preview && showTools && !disabled" icon="Edit3Icon" size="18"  @click="showUpdateProrataAccount" v-b-tooltip.hover.top="'Modifier'") 
            feather-icon.cursor-pointer.feather(v-if="!preview && showTools && !disabled" icon="XIcon" size="18"  @click="deleteProrataAccount" v-b-tooltip.hover.top="'Retirer'")
    div.mb-1.mt-1.d-flex(v-if='editProrataAccount && !preview')
        .d-flex.align-items-center
            div(style='min-width: fit-content;') Prorata
            input.input-prorata-account.form-control.form-control-sm.ml-1.mr-1(v-model='label' name='prorata_account_label' style='width:120px; max-width:120px')
            div(style='min-width: fit-content;') de
            input.input-number-prorata-account.form-control.form-control-sm.ml-1.mr-1(@blur="changeValueByPercent" v-model='percent' name='prorata_account_percent' type="number" )
            div(style='min-width: fit-content;') % soit 
            input.input-number-prorata-account.form-control.form-control-sm.ml-1.mr-1(@blur="changePercentByValue" v-model='number' name='prorata_account_number' type="number")
            div(style='min-width: fit-content;') € TTC
            b-button.form-control-sm.p-0.ml-1(style="padding:0px 5px !important" variant="primary" type="small" @click="pushProrataAccount") 
                feather-icon(icon='SaveIcon' size="20")
            b-button.form-control-sm.p-0.ml-1(style="padding:0px 5px !important" variant="outline-primary" @click="toggleProrataAccount(false)") 
                feather-icon(icon='XIcon' size="20")
                
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { BButton } from "bootstrap-vue";
import {
  formatCurrency,
  formatPercent,
} from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  data() {
    return {
      showTools: false,
      number: 0,
      label: null,
      percent: 0,
      isUpdateStatus: false,
    };
  },
  props: {
    preview: {
      default: false,
    },
    editProrataAccount: {
      type: Boolean,
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  components: {
    BButton,
  },
  computed: {
    ...mapGetters(["institutionSettingsActive", "document"]),
    deduction: {
      get() {
        return this.$store.getters["deduction"];
      },
      set(value) {
        return this.$store.commit("SET_DEDUCTION", value);
      },
    },
    referencielTVAsListInstitution() {
      return this.institutionSettingsActive.referencielTvas.map((elem) => {
        return {
          id: elem.referencielTva.id,
          label: elem.referencielTva.label,
          value: elem.referencielTva.value,
        };
      });
    },
    isDisplayProrataAccount() {
      return this.deduction
        ? this.deduction.prorataAccountLabel != null ||
            this.deduction.prorataAccountPrice != null ||
            this.deduction.prorataAccountPercent != null
        : true;
    },
  },
  methods: {
    ...mapActions(["createDeduction", "updateDeduction"]),
    formatCurrency,
    formatPercent,
    changePercentByValue(res) {
      if (res.target.value < 0) {
        this.number = 0;
        this.percent =
          Math.round((this.number / this.document.totalTtc) * 100 * 100) / 100;
      } else {
        this.percent =
          Math.round((this.number / this.document.totalTtc) * 100 * 100) / 100;
      }
    },
    changeValueByPercent(res) {
      if (res.target.value < 0) {
        this.percent = 0;
        this.number =
          Math.round((this.percent / 100) * this.document.totalTtc * 100) / 100;
      } else if (res.target.value > 100) {
        this.percent = 100;
        this.number =
          Math.round((this.percent / 100) * this.document.totalTtc * 100) / 100;
      } else {
        this.number =
          Math.round((this.percent / 100) * this.document.totalTtc * 100) / 100;
      }
    },
    pushProrataAccount() {
      if (this.label && this.number && this.percent) {
        this.deduction.prorataAccountLabel = this.label;
        this.deduction.prorataAccountPrice = this.number;
        this.deduction.prorataAccountPercent = this.percent;
        this.toggleProrataAccount(false);
        if (this.deduction.id == 0) {
          this.createDeduction({ deduction: this.deduction });
        } else {
          this.updateDeduction({ deduction: this.deduction });
        }
      }
    },
    toggleProrataAccount(bool = false) {
      this.label = null;
      this.number = 0;
      this.percent = 0;
      this.$emit("toggleProrataAccount", bool);
    },
    showUpdateProrataAccount() {
      this.isUpdateStatus = true;
      this.number = this.deduction.prorataAccountPrice;
      this.percent = this.deduction.prorataAccountPercent;
      this.label = this.deduction.prorataAccountLabel;
      this.$emit("toggleProrataAccount", true);
    },
    deleteProrataAccount() {
      this.deduction.prorataAccountLabel = null;
      this.deduction.prorataAccountPrice = null;
      this.deduction.prorataAccountPercent = null;
      this.updateDeduction({ deduction: this.deduction });
    },
  },
};
</script>

<style scoped>
.input-number-prorata-account {
  border: solid 1px #ccc !important;
  background-color: white !important;
  border-radius: 3px;
  padding: 8px;
  width: 65px !important;
  min-width: 65px;
  height: 30px;
  border-radius: 0.357rem;
}

.input-prorata-account {
  border: solid 1px #ccc !important;
  background-color: white !important;
  border-radius: 3px;
  padding: 8px;
  width: 180px;
  height: 30px;
  border-radius: 0.357rem;
}

.feather-icon-prorata-account {
  margin-right: 5px;
}

.datepicker-prorata-account {
  height: 30px !important;
  width: 200px;
}
</style>

<style>
.datepicker-prorata-account > .text-break {
  padding: 0rem 1rem !important;
}
</style>
