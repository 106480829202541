<template lang="pug">
    input(v-if="!disabled" @focus="emitEditFocus(true)" @blur="blurFunction(false)" class="w-100" :class="[edit && canEdit ? 'edit' : 'no-edit', line.isDisabled && dataKey!=='situationProgressQuantity' && dataKey!=='situationProgress' ? 'disabled' :'']" :disabled="!edit" placeholder="" v-model="line[dataKey]") 
    div(v-else style="margin-top: 7px")
      span(:style="'color:'+document.institutionColor+' !important;'") {{line[dataKey]}}
</template>
<script>
import TextCol from "@/components/invoice/builder/table/cols/TextCol.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["document"]),
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    invoiceHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    disabled: {
      default: false,
    },
  },
  components: {
    TextCol,
  },
  methods: {
    ...mapActions(["updateDocumentDetail", "updateCreditDetail"]),
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
    blurFunction(res) {
      this.emitEditFocus(res);
      if (this.line.titre) {
        this.line.description = this.line.titre;
      }
      this.document.nature == 5 || this.document.nature == 6
        ? this.updateCreditDetail({ creditDetail: this.line })
        : this.updateDocumentDetail({ documentDetail: this.line });
    },
  },
};
</script>
