<template lang="pug"> 
  div(v-if="invoiceHeader[dataKey].inputType == 24") 
    div.content-span-document-detail(style='line-height:34px!important')
      span {{ line.isOption == true ? 'Sous-total des options' : 'Sous-total' }}
  div(v-else-if="dataKey == 'subtotal'")
    div.content-span-document-detail(v-if="!document.hidePriceHtColumn" style='line-height:34px!important')
      //- span {{ calculateSubTotalLine({...line, indexArray: indexArray}, line.isOption, false) }}
      span {{ (line.total).toFixed(2) }}
      span {{invoiceHeader[dataKey].unit}}
  div(v-else-if="invoiceHeader[dataKey].inputType == 1")  
    TextCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="false" :dataKey="dataKey" :invoiceHeader="invoiceHeader" :edit="edit" :editFocus="editFocus")
</template>
<script>
import TitleCol from "@/components/invoice/builder/table/cols/TitleCol.vue";
import TextCol from "@/components/invoice/builder/table/cols/TextCol.vue";
import { calculateSubTotalLine } from "@/types/api-orisis/library/DocumentOperations";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    indexArray: {
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    invoiceHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    calculateSubTotalLine,
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  components: {
    TitleCol,
    TextCol,
  },
  computed: {
    ...mapGetters(["document"]),
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
  },
};
</script>
