<template lang="pug">
div.builder-header.px-2(v-show="!isLoadingDocument")
  div
    ul#pills-tab.nav.nav-pill(role='tablist')
      li.nav-item(@click="changeDocumentTabActive('document-edit')")
        div.nav-link.cursor-pointer.text-center(:class="documentTabActive == 'document-edit' ? 'active' : ''")
          feather-icon(icon="Edit2Icon" size="15") 
          |  Edition
      li.nav-item(v-if="checkIfPreviewIsPossible()" @click="showPreviewPdf")
        div.nav-link.cursor-pointer.text-center(:class="documentTabActive == 'document-pdf' ? 'active' : ''")
          feather-icon(icon="EyeIcon" size="15") 
          |  Prévisualisation
      li.nav-item(v-if="checkFunctionUser(43, 'all')" @click="changeDocumentTabActive('attachments')")
        div.nav-link.cursor-pointer.text-center(:class="documentTabActive == 'attachments' ? 'active' : ''")
          feather-icon(icon="FolderIcon" size="15") 
          |  Documents
      li.nav-item(@click="changeDocumentTabActive('internal-note')")
        div.nav-link.cursor-pointer.text-center(:class="documentTabActive == 'internal-note' ? 'active' : ''")
          feather-icon(icon="LockIcon" size="15") 
          |  Notes
  div.d-flex.align-items-center.ml-auto
    b-modal(ref="modalSendEmail" :title="`Envoyer votre ${ natureTranslate(document.nature).toLowerCase() } par email`" centered ok-title="Envoyer" @ok="sendEmail" cancel-title="Annuler")
      label(for="selectRecipients") Envoyer à :
      ejs-multiselect( id="selectRecipients" placeholder='Selectionnez des destinataires' popupHeight='300px' :allowCustomValue='true' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :itemTemplate="itemTemplate" :fields='customFields' :dataSource='companyContacts' :hideSelectedItem="true" v-model="emailForm.mailTos")
      label.mt-1(for="selectCC") Envoyer en copie à :
      ejs-multiselect( id="selectCC" placeholder='Selectionnez des copies' popupHeight='300px' :allowCustomValue='true' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :itemTemplate="itemTemplate" :fields='customFields' :dataSource='companyContacts' :hideSelectedItem="true" v-model="emailForm.ccs")
      label.mt-1(for="selectCC") Envoyer en copie cachée à :
      ejs-multiselect( id="selectBCC" placeholder='Selectionnez des copies cachées' popupHeight='300px' :allowCustomValue='true' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :itemTemplate="itemTemplate" :fields='customFields' :dataSource='companyContacts' :hideSelectedItem="true" v-model="emailForm.bccs")
    .form-group.form-check.mb-0.mr-1.tab-control.builderControls(v-if="documentTabActive=='document-edit'" data-tab='document-edit')
      .d-flex.align-items-center 
        b-dropdown.mr-1#dropdown-form(v-if="statusCheck.documentEditable.includes(document.status)" ref='dropdown' dropup='' variant='outline-secondary' text='Options')
          b-dropdown-form.dropdown-options
            h6.mt-1(v-if="(statusCheck.documentEditable.includes(document.status) || document.isTemplate) && (document.nature != 2 && document.nature != 5 && document.nature != 6)") Modèle
            b-form-checkbox.checkbox-options(v-if="(statusCheck.documentEditable.includes(document.status) || document.isTemplate) && (document.nature != 2 && document.nature != 5 && document.nature != 6)" @change="setTemplate" v-model="document.isTemplate")
              span.text-nowrap Définir comme modèle
            .d-flex.align-items-center.mb-1.cursor-pointer(v-if="document.isTemplate" style="font-size: 13px; font-weight: 500;" @click="toggleTemplateModal()")
              span.material-icons-outlined.mr-50(style="font-size: 19px;") edit
              span(style='-moz-text-decoration-line: underline; -moz-text-decoration-style: dotted; -moz-text-decoration-color: #666; -webkit-text-decoration-line: underline; -webkit-text-decoration-style: wavy; -webkit-text-decoration-color: red; text-decoration-line: underline; text-decoration-style: dotted; text-decoration-color: #666;') {{ document.label && document.label!=="" ? document.label : "Nom du modèle" }}
            h6.mt-1 Edition  
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="documentOptions.calculateMargin" v-if="document.nature != 2 && document.nature != 5")
              span.text-nowrap Afficher le calcul des marges
            //- b-form-checkbox.checkbox-options(@change="setHeader" v-model="documentOptions.calculateUnitPriceHtByUnitPurchasePriceHt" v-if="document.nature != 2 || document.nature != 5")
            //-   span.text-nowrap Calculer le prix de vente par rapport au prix d'achat
            b-form-checkbox.checkbox-options(@change="discountLineAction" v-model="documentOptions.showDiscountColumn" v-if="document.nature != 2 || document.nature != 5")
              span.text-nowrap Afficher la colonne des remises par ligne
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="documentOptions.showReferenceColumn" v-if="document.nature != 2 || document.nature != 5")
              span.text-nowrap Afficher la colonne des références
            b-form-checkbox.checkbox-options(v-if="document.nature == 0 && document.type !== 1" @change="saveActionOptions()" v-model="documentOptions.hideWasteCollection")
              span.text-nowrap Masquer la gestion des déchets
            b-form-checkbox.checkbox-options(v-model="documentOptions.isReverseCharge" @change="isReverseChargeRequest" :disabled="!document.nature == 0 && !document.nature == 1")
              span.text-nowrap Autoliquidation
            b-form-checkbox.checkbox-options(v-if="document.nature == 2 || document.nature == 3 || document.nature == 4" @change="saveActionOptions()" v-model="documentOptions.showSituationDetails")
              span.text-nowrap Afficher le récapitulatif de la prestation
            h6.mt-1 Prévisualisation
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.hideInstitutionLogo")
              span.text-nowrap Masquer mon logo
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.hideInstitutionName")
              span.text-nowrap Masquer ma raison sociale
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.hideInstitutionAddress")
              span.text-nowrap Masquer mon adresse
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.hideCollaboratorInCharge")
              span.text-nowrap Masquer le collaborateur en charge
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.hideAffair")
              span.text-nowrap Masquer l'affaire
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.showAffairCode")
              span.text-nowrap Masquer de code affaire
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.hideLinesQuantityEmpty" v-if="document.nature != 2 || document.nature != 5")
              span.text-nowrap Masquer les lignes sans quantité
            //- b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.showDetailWorkLine")
            //-   span.text-nowrap Afficher les composants d'ouvrage
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.hideTotal")
              span.text-nowrap Masquer le total
            b-form-checkbox.checkbox-options(v-if="document.status != 0 && document.status != 8" @change="saveActionOptions()" v-model="documentOptions.showWaterMark")
              span.text-nowrap Afficher le filigrane
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.showTermsOfSales")
              span.text-nowrap Afficher les conditions de vente
            b-form-checkbox.checkbox-options(v-if="(document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4) && initialDocument && document.quoteId" @change="saveActionOptions()" v-model="documentOptions.hideInitialQuote")
              span.text-nowrap Masquer le devis initial
            b-form-checkbox.checkbox-options(v-if="(document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4) && initialDocument && document.orderFormCustomerId" @change="saveActionOptions()" v-model="documentOptions.hideInitialOrderFormCustomer")
              span.text-nowrap Masquer la commande initiale
            h6.mt-1 Document
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.hideUser")
              span.text-nowrap Masquer le contact
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="documentOptions.hideIndexColumn")
              span.text-nowrap Masquer la colonne des index
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="documentOptions.hideQuantityColumn")
              span.text-nowrap Masquer la colonne des quantités
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="documentOptions.hideUnitColumn")
              span.text-nowrap Masquer la colonne des unités
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="documentOptions.hideUnitPriceHtColumn")
              span.text-nowrap Masquer la colonne des prix unitaire HT
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="documentOptions.hideReferencielTvaColumn")
              span.text-nowrap Masquer la colonne des TVA
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="documentOptions.hidePriceHtColumn")
              span.text-nowrap Masquer la colonne des totaux HT
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="documentOptions.hideBankAccount" v-if="document.nature == 0 || document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4")
              span.text-nowrap Masquer le compte bancaire associé
            h6.mt-1 Client
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.hideCompany")
              span.text-nowrap Masquer le client
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.showCompanyCode")
              span.text-nowrap Masquer le code client
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.showCompanyAddressLabel")
              span.text-nowrap Afficher le libellé de l'adresse
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.showCompanyMail")
              span.text-nowrap Afficher le mail
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.showCompanyPhoneNumber")
              span.text-nowrap Afficher le téléphone
            b-form-checkbox.checkbox-options(@change="saveActionOptions()" v-model="documentOptions.showCompanySiret")
              span.text-nowrap Afficher le SIRET
            b-form-checkbox.checkbox-options(@input="saveActionOptions()" v-model="documentOptions.showCompanyTva")
              span.text-nowrap Afficher le numéro de TVA intracommunautaire
    div(v-if="statusCheck.documentDraft.includes(document.status)")
      b-button.mr-1.btn-invoice-builder-header(@click="cancel" :variant="'outline-danger'")
        | Supprimer
    div.d-flex.align-items-center.ml-auto(v-if="(statusCheck.documentEditable.includes(document.status)) && documentDetails.length > 0")
      b-button-group
        b-dropdown.dropdown-options.pr-1(text='Finaliser le document' variant='success' dropup)
          b-dropdown-item.cursor-pointer(v-if="document.nature != 5 && document.nature != 6" @click="finalizedDocument(1, false)" :disabled="!statusCheck.documentDraft.includes(document.status)" :style="!statusCheck.documentDraft.includes(document.status) ? 'opacity:0.5' : ''") 
            span.chip.justify-content-start
              span.point.bg-warning
              span.label.text-warning Passer en validation
          b-dropdown-item.cursor-pointer(@click="finalizedDocument(2, false)" :disabled="!statusCheck.documentEditable.includes(document.status)" :style="!statusCheck.documentEditable.includes(document.status) ? 'opacity:0.5' : ''") 
            span.chip.justify-content-start
              span.point.bg-primary
              span.label.text-primary Valider le document
          //- b-dropdown-item.cursor-pointer(@click="openEmailModal" :disabled="document.status >= 2 && document.status !== 8" :style="document.status >= 2 && document.status !== 8 ? 'opacity:0.5' : ''") 
          //-   span.chip.justify-content-start
          //-     span.point.bg-primary
          //-     span.label.text-primary Valider et envoyer le document
    div
        b-button-group(v-if="statusCheck.documentEditable.includes(document.status)")
          //- b-dropdown.dropdown-options(text='Enregistrer' variant='primary' dropup)
          //-   b-dropdown-item.cursor-pointer(@click="finalizedDocument(2, false)") 
          //-     span Enregistrer en tant que modèle
          //-   b-dropdown-item.cursor-pointer(@click="checkStatusBeforeSave(false)") 
          //-     span Enregistrer le document
          b-dropdown.dropdown-options(style='height:38px' variant='primary' dropup v-if="documentTabActive == 'document-pdf'")
            template.p-0.m-0(#button-content)
              span(class="material-icons-outlined mr-50" style="font-size:16px") upload_file
              | Aperçu
            b-dropdown-item.cursor-pointer(@click="forceFileDownload(document.path)")
              span Télécharger
            b-dropdown-item.cursor-pointer(v-if="document.nature == 0 && documentType == null" @click="showDetailedDocumentWithoutPrices()")
              span Afficher le document détaillé sans les prix
            b-dropdown-item.cursor-pointer(v-if="document.nature == 0 && documentType != null" @click="showOriginalDocument()")
              span Afficher le document original
          b-button.btn-invoice-builder-header.m-0(@click="checkStatusBeforeSave(false)" variant='primary')
            | Enregistrer
          b-button.btn-invoice-builder-header.bl-white(@click="checkStatusBeforeSave(true)" style="border-left: 1px solid #ccc !important;" variant='primary')
            feather-icon(icon='LogOutIcon')
        b-button-group(v-else-if="document.path")
          b-button.btn-invoice-builder-header.m-0(v-if="[1,2,3,4].includes(document.nature) && workspaceAuthorized" variant='primary' @click="openEmailModal")
            | Envoyer
            feather-icon.ml-50(icon='SendIcon')
          b-dropdown.dropdown-options(style='height:38px' variant='primary' dropup v-if="documentTabActive == 'document-pdf'")
            template.p-0.m-0(#button-content)
              span(class="material-icons-outlined mr-50" style="font-size:16px") upload_file
              | Aperçu
            b-dropdown-item.cursor-pointer(@click="forceFileDownload(document.path)")
              span Télécharger
            b-dropdown-item.cursor-pointer(v-if="document.nature == 0 && documentType == null" @click="showDetailedDocumentWithoutPrices()")
              span Afficher le document détaillé sans les prix
            b-dropdown-item.cursor-pointer(v-if="document.nature == 0 && documentType != null" @click="showOriginalDocument()")
              span Afficher le document original
          //- b-button.btn-invoice-builder-header.m-0(variant='primary' @click="forceFileDownload(document.path)" style="border-left:1px solid #ccc !important; border-right:1px solid #ccc !important")
          //-   | Télécharger
          //-   feather-icon.ml-50(icon='DownloadIcon')
          b-button.btn-invoice-builder-header(variant='primary' @click="cancel" style="border-left:1px solid #ccc !important")
            | Fermer 
            feather-icon.ml-50(icon='LogOutIcon')
        b-button.btn-invoice-builder-header(v-else variant='primary' @click="cancel" style="border-left:1px solid #ccc !important")
          | Fermer 
          feather-icon.ml-50(icon='LogOutIcon')

  b-modal(ref='template-modal' :title="'Enregistrer en tant que modèle'")
    div
      b-row.mt-50.mb-50
        b-col.py-0(cols='12')
          b-form-group(label="Nom du modèle")
            ejs-textbox(id="documentLabel" placeholder="Nom du modèle" v-model="document.label" name="Label")
    //.mt-1.cancel-modal-buttons
    template(#modal-footer="{ ok, cancel }")
      .w-100.m-0.py-50
        b-button(variant="primary" class="float-right" @click="toggleTemplateModal()") Enregistrer
</template>

<script>
import { statusCheck } from "@/types/api-orisis/enums/enums";
import { calculateTotalDocumentLine } from "@/types/api-orisis/library/DetailDocumentOperations";
import { getTotalDeduction } from "@/types/api-orisis/library/DocumentOperations";
import { natureTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import axios from "axios";
import {
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
} from "bootstrap-vue";
import Vue from "vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
import { checkFunctionUser } from "@/auth/utils.ts";
var itemVue = Vue.component("itemTemplate", {
  template:
    "<div style='display: flex; flex-direction: column; min-height: 30px;'><span style='font-weight: 600'>{{ data.contact.lastName}} {{ data.contact.firstName}} &lt{{ data.contact.email}}&gt</span><span>{{ data.label}}</span></div>",
  data() {
    return {
      data: {},
    };
  },
});

export default {
  data() {
    return {
      documentType: null,
      companyContacts: [],
      emailForm: {
        invoiceId: null,
        mailTos: [],
        ccs: [],
        bccs: [],
      },
      customFields: {
        text: "contact.email",
        value: "contact.email",
      },
      itemTemplate: function (e) {
        return {
          template: itemVue,
        };
      },
      mandatoryInformationAlertArray: [],
      calculateTotalDocumentLine,
      getTotalDeduction,
      statusCheck,
    };
  },
  created() {
    this.setupHeader({ nature: this.document.nature });
  },
  methods: {
    ...mapActions([
      "getCompanyById",
      "updateQuote",
      "updateInvoice",
      "updateCredit",
      "deleteQuotes",
      "deleteInvoices",
      "deleteCredits",
      "setupHeader",
      "loopDocumentDetails",
      "changeDocumentTabActive",
      "updateDocumentDetail",
      "cancelInvoice",
      "updateCreditInvoice",
      "sendInvoice",
      "updateQuoteOption",
      "updateInvoiceOption",
      "updateCreditOption",
    ]),
    checkFunctionUser,
    natureTranslate,
    showDetailedDocumentWithoutPrices() {
      // this.showPreviewPdf();
      this.documentType = "detailedWithoutPrices";
      this.$emit("documentTypeChanged", this.documentType);
    },
    showOriginalDocument() {
      // this.showPreviewPdf();
      this.documentType = null;
      this.$emit("documentTypeChanged", this.documentType);
    },
    async validDocument(action, exit, document) {
      return new Promise(async (resolve) => {
        if (action == 1) {
          if (document.nature == 0) {
            await this.updateQuote({
              quote: { ...document, status: 1 },
              updateState: true,
            });
          } else if (
            document.nature == 1 ||
            document.nature == 2 ||
            document.nature == 3 ||
            document.nature == 4
          ) {
            await this.updateInvoice({
              invoice: { ...document, status: 1 },
              updateState: true,
            });
          } else {
            await this.updateCredit({
              credit: { ...document, status: 1 },
              updateState: true,
            });
          }
          if (this.documentTabActive == "document-pdf") {
            this.$emit("changePreviewKey");
          } else {
            await this.changeDocumentTabActive("document-pdf");
          }
          resolve();
        } else if (action == 2) {
          this.$bvModal
            .msgBoxConfirm(
              "Attention, en passant votre document en statut 'validé' celui-ci sera regénéré sans filigrane et ne sera plus modifiable.",
              {
                title: "Valider le document",
                size: "sm",
                okVariant: "primary",
                okTitle: "Oui, confirmer",
                cancelTitle: "Annuler",
                cancelVariant: "outline-primary",
                hideHeaderClose: true,
                centered: true,
              }
            )
            .then(async (value) => {
              if (value) {
                this.$store.commit("SET_IS_VALIDATING_DOCUMENT", true);
                if (document.nature == 0) {
                  await this.updateQuote({
                    quote: { ...document, status: 2 },
                    updateState: true,
                  });
                } else if (
                  document.nature == 1 ||
                  document.nature == 2 ||
                  document.nature == 3 ||
                  document.nature == 4
                ) {
                  await this.updateInvoice({
                    invoice: { ...document, status: 2 },
                    updateState: true,
                  });
                } else {
                  await this.updateCredit({
                    credit: { ...document, status: 2 },
                    updateState: true,
                  });
                }
                // Si la tab affiché correspond déjà à l'aperçu alor son rafrachit le composant pour que l'aperçu se regénère
                if (this.documentTabActive == "document-pdf") {
                  this.$emit("changePreviewKey");
                } else {
                  await this.changeDocumentTabActive("document-pdf");
                }
                this.$store.commit("SET_IS_VALIDATING_DOCUMENT", false);
                resolve();
              }
            });
        }
      });
    },
    async finalizedDocument(action, exit) {
      if (!this.document.companyId) {
        this.$bvToast.toast(
          "Veuillez sélectionner un client afin de finaliser votre document.",
          {
            title: `Erreur`,
            variant: "danger",
            solid: true,
          }
        );
      } else {
        if (this.document.nature == 0) {
          this.checkMandatoryInformation().then(async (res) => {
            let next = true;
            for (let i = 0; i < res.length; i++) {
              const element = res[i];
              if (next == true) {
                await this.$swal({
                  title: element.message,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: element.button,
                  cancelButtonText: "Corriger",
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.value) {
                    next = true;
                  } else {
                    next = false;
                  }
                });
              }
            }
            if (next == true) {
              await this.validDocument(
                action,
                exit,
                structuredClone(this.document)
              );
            }
          });
        } else if (this.document.nature == 5 && this.isTotalCredit) {
          this.$swal({
            title:
              "Lorsque vous faites un avoir à 100%, la facture s'annule automatiquement",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Finaliser et annuler la facture",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              if (this.initialInvoice.status == 6) {
                this.document.status = 2;
                await this.validDocument(
                  action,
                  exit,
                  structuredClone(this.document)
                );
              } else {
                this.document.status = 6;
                let totalDeduction = await this.getTotalDeduction();
                let creditRemainingToPayTtc = structuredClone(
                  Number(this.document.totalTtc) -
                    totalDeduction -
                    this.initialInvoice.remainingToPayTtc
                );
                let creditAmountPayment =
                  creditRemainingToPayTtc >= 0
                    ? structuredClone(this.initialInvoice.remainingToPayTtc)
                    : structuredClone(this.document.totalTtc);
                let entries = new Map([
                  [this.document.id, creditAmountPayment],
                ]);
                let credits = Object.fromEntries(entries);
                await this.validDocument(
                  action,
                  exit,
                  structuredClone(this.document)
                ).then(async () => {
                  await this.updateCreditInvoice({
                    invoiceId: this.initialInvoice.id,
                    credits: credits,
                  });
                });
              }
              await this.cancelInvoice({
                invoiceId: this.document.invoiceId,
              });
              this.isTotalCredit = false;
            }
          });
        } else if (this.document.nature == 5 && !this.isTotalCredit) {
          if (this.initialInvoice.status == 6) {
            this.document.status = 2;
            await this.validDocument(
              action,
              exit,
              structuredClone(this.document)
            );
          } else {
            this.document.status = 6;
            await this.$swal({
              title:
                "Souhaitez-vous déduire cet avoir sur la facture " +
                this.initialInvoice.documentReference +
                " ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then(async (result) => {
              if (result.value) {
                let creditRemainingToPayTtc =
                  this.document.totalTtc -
                  this.initialInvoice.remainingToPayTtc;
                let creditAmountPayment =
                  creditRemainingToPayTtc >= 0
                    ? this.initialInvoice.remainingToPayTtc
                    : this.document.totalTtc;
                let entries = new Map([
                  [this.document.id, creditAmountPayment],
                ]);
                let credits = Object.fromEntries(entries);
                await this.validDocument(
                  action,
                  exit,
                  structuredClone(this.document)
                ).then(async () => {
                  await this.updateCreditInvoice({
                    invoiceId: this.initialInvoice.id,
                    credits: credits,
                  });
                });
              } else {
                await this.validDocument(
                  action,
                  exit,
                  structuredClone(this.document)
                );
              }
            });
          }
        } else {
          await this.validDocument(
            action,
            exit,
            structuredClone(this.document)
          );
        }
      }
    },
    showPreviewPdf() {
      this.$emit("changePreviewKey");
      this.$nextTick(() => {
        this.changeDocumentTabActive("document-pdf");
      });
    },
    sendEmail() {
      this.emailForm.invoiceId = this.document.id;
      this.sendInvoice({
        data: this.emailForm,
      })
        .then(() => {
          this.$bvModal.hide("modalSendEmail");
          this.$bvToast.toast("Email envoyé avec succès", {
            title: "Succès",
            variant: "success",
            solid: true,
          });
          if (this.document.status == 2) {
            // Si le document est validé
            this.document.status = 9;
            this.saveAction(false);
          }
          this.$router.push({ name: "invoices" });
        })
        .catch((error) => {
          this.$bvToast.toast("Erreur lors de l'envoi de l'email", {
            title: "Erreur",
            variant: "danger",
            solid: true,
          });
        });
    },
    openEmailModal() {
      this.getCompanyById({ companyId: this.document.companyId }).then(
        (res) => {
          let newContactsList = [
            {
              contact: {
                displayLabel: null,
                email: res.email,
                id: 0,
                lastName: res.name,
              },
              label: res.name,
            },
          ].concat(res.contacts); // Concat de l'email de la company et des contacts
          this.companyContacts = newContactsList.filter(
            (el) => el.contact.email
          );
          this.$refs.modalSendEmail.show();
        }
      );
    },
    saveAction(bool) {
      if (this.document.nature == 0) {
        this.updateQuote({ quote: this.document, updateState: bool });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.updateInvoice({ invoice: this.document, updateState: bool });
      } else {
        this.updateCredit({ credit: this.document, updateState: bool });
      }
    },
    saveActionOptions() {
      if (this.document.nature == 0) {
        this.updateQuoteOption({
          quoteOption: this.documentOptions,
          updateState: false,
        });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.updateInvoiceOption({
          invoiceOption: this.documentOptions,
          updateState: false,
        });
      } else {
        this.updateCreditOption({
          creditOption: this.documentOptions,
          updateState: false,
        });
      }
    },
    async checkStatusBeforeSave(exit) {
      if (this.document.status == 8) {
        this.document.status = 0;
        if (this.document.nature == 0) {
          this.$store.getters.quotesList.unshift(this.document);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          this.$store.getters.invoicesList.unshift(this.document);
        } else {
          this.$store.getters.creditsList.unshift(this.document);
        }
      }
      await this.saveDocument(exit);
    },
    checkIfPreviewIsPossible() {
      if (this.document.companyId) {
        return true;
      } else {
        return false;
      }
    },
    async saveDocument(exit) {
      return await new Promise(async (resolve, reject) => {
        if (this.document.nature == 0) {
          await this.updateQuote({
            quote: this.document,
            updateState: true,
          }).then(async (res) => {
            if (exit) {
              this.$tabs.close({ to: "/quotes" });
            }
          });
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          await this.updateInvoice({
            invoice: this.document,
            updateState: true,
          }).then(async (res) => {
            if (exit) {
              this.$tabs.close({ to: "/invoices" });
            }
          });
        } else {
          await this.updateCredit({
            credit: this.document,
            updateState: true,
          }).then(async (res) => {
            if (exit) {
              this.$tabs.close({ to: "/credits" });
            }
          });
        }
      });
    },
    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute("download", this.document.documentReference);
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    setHeader() {
      this.setupHeader({ nature: this.document.nature });
      this.saveActionOptions();
    },
    async discountLineAction() {
      await this.setHeader();
      if (!this.documentOptions.showDiscountColumn) {
        for (let i = 0; i < this.documentDetails.length; i++) {
          const documentDetail = this.documentDetails[i];
          if (documentDetail.discount > 0) {
            documentDetail.discount = 0;
            let total = this.calculateTotalDocumentLine(documentDetail);
            this.updateDocumentDetail({
              documentDetail: { ...documentDetail, total: total },
            });
          }
        }
      }
    },
    setTemplate(value) {
      if (value) {
        this.toggleTemplateModal();
      } else {
        this.saveAction(false);
      }
    },
    toggleTemplateModal() {
      this.$refs["template-modal"].toggle("#toggle-btn");
      this.saveAction(false);
    },
    isReverseChargeRequest(value) {
      if (value) {
        this.$bvModal
          .msgBoxConfirm(
            "Attention, toutes les TVA du document seront passées à 0 %.",
            {
              title: "Activer l'autoliquidation",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.saveActionOptions(false);
              this.loopDocumentDetails({ referencielTvaId: 5 });
            } else {
              this.documentOptions.isReverseCharge = false;
            }
          });
      } else {
        this.documentOptions.isReverseCharge = false;
      }
    },
    checkMandatoryInformation() {
      return new Promise(async (resolve, reject) => {
        let mandatoryInformationAlertArray = [];
        if (this.document.type > 1 && this.document.nature == 0) {
          mandatoryInformationAlertArray =
            await this.checkMandatoryInformationWorkStartDate(
              mandatoryInformationAlertArray
            );
          mandatoryInformationAlertArray =
            await this.checkMandatoryInformationEstimatedDuration(
              mandatoryInformationAlertArray
            );
          mandatoryInformationAlertArray =
            await this.checkMandatoryInformationWasteManagement(
              mandatoryInformationAlertArray
            );
        } else {
          resolve(mandatoryInformationAlertArray);
        }
        resolve(mandatoryInformationAlertArray);
      });
    },
    checkMandatoryInformationWorkStartDate(mandatoryInformationAlertArray) {
      if (
        this.document.quoteBTP.workStartDate == null &&
        (this.document.type == 3 || this.document.type == 2)
      ) {
        mandatoryInformationAlertArray.push({
          message:
            "La date de début des travaux est une mention obligatoire vis à vis de la loi",
          button: "Continuer sans la date",
        });
        return mandatoryInformationAlertArray;
      }
      return mandatoryInformationAlertArray;
    },
    checkMandatoryInformationEstimatedDuration(mandatoryInformationAlertArray) {
      if (
        this.document.quoteBTP.estimatedDurationNumber == null &&
        (this.document.type == 3 || this.document.type == 2)
      ) {
        mandatoryInformationAlertArray.push({
          message:
            "La durée approximative des travaux est une mention obligatoire vis à vis de la loi",
          button: "Continuer sans la durée",
        });
        return mandatoryInformationAlertArray;
      }
      return mandatoryInformationAlertArray;
    },
    checkMandatoryInformationWasteManagement(mandatoryInformationAlertArray) {
      if (
        !(
          this.document.quoteBTP.wasteVolumeFrom >= 0 &&
          this.document.quoteBTP.wasteVolumeTo >= 0 &&
          this.document.quoteBTP.quoteBTPWasteCollectionPointIds &&
          this.document.quoteBTP.quoteBTPWasteCollectionPointIds.length > 0 &&
          this.document.quoteBTP.quoteBTPWasteTypeIds &&
          this.document.quoteBTP.quoteBTPWasteTypeIds.length > 0 &&
          (this.document.type == 3 || this.document.type == 2)
        ) &&
        this.document.nature == 0
      ) {
        mandatoryInformationAlertArray.push({
          message:
            "Les informations concernant l'enlèvement des déchets sont des mentions obligatoires vis à vis de la loi",
          button: "Continuer sans ces informations",
        });
        return mandatoryInformationAlertArray;
      }
      return mandatoryInformationAlertArray;
    },
    cancel() {
      if (this.document.status !== 8 && this.document.status !== 0) {
        this.closeTab();
      } else {
        this.$bvModal
          .msgBoxConfirm(
            `Votre document n'ayant aucun statut, vous êtes sur le point de le supprimer. Attention, cette action est irreversible.`,
            {
              title: "Êtes-vous sûr de vouloir supprimer votre document ?",
              size: "sm",
              okVariant: "danger",
              okTitle: "Supprimer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              if (this.document.status == 8 || this.document.status == 0) {
                if (this.document.nature == 0) {
                  this.deleteQuotes({
                    quoteIds: [this.document.id],
                    route: this.$route.name,
                  }).then(() => {
                    this.closeTab();
                  });
                } else if (
                  this.document.nature == 1 ||
                  this.document.nature == 2 ||
                  this.document.nature == 3 ||
                  this.document.nature == 4
                ) {
                  this.deleteInvoices({
                    invoiceIds: [this.document.id],
                    route: this.$route.name,
                  }).then(() => {
                    this.closeTab();
                  });
                } else if (
                  this.document.nature == 5 ||
                  this.document.nature == 6
                ) {
                  this.deleteCredits({
                    creditIds: [this.document.id],
                    route: this.$route.name,
                  }).then(() => {
                    this.closeTab();
                  });
                }
              }
            }
          });
      }
    },
    closeTab() {
      if (
        this.$route.params.routeOrigine &&
        this.$route.params.routeOrigine !== ""
      ) {
        this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
      } else {
        this.$tabs.close();
      }
    },
  },
  computed: {
    ...mapGetters([
      "documentTabActive",
      "documentPDF",
      "isLoadingDocumentPDF",
      "initialInvoice",
      "deduction",
      "isLoadingDocument",
      "workspaceSelected",
      "initialDocument",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
    isTotalCredit: {
      get() {
        return this.$store.getters["isTotalCredit"];
      },
      set(value) {
        return this.$store.commit("SET_IS_TOTAL_CREDIT", value);
      },
    },
    workspaceAuthorized() {
      return this.workspaceSelected.id == 14 || this.workspaceSelected.id == 3;
    },
  },
  components: {
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BButton,
    BDropdownDivider,
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
.vl {
  border-left: 1px solid #8a8e93;
  width: 1px;
  height: 16px;
  background: rgb(255, 255, 255);
}

.btn-invoice-builder-header {
  padding: 8px;
  height: 38px;
}

.bl-white {
  border-left-color: red !important;
}

.dropdown-options {
  height: 100%;
}

.checkbox-options {
  margin-bottom: 10px;
}

.checkbox-options > label {
  font-size: 13px !important;
}
#invoiceContainer .dropdown-menu {
  width: auto !important;
}
</style>
