<template lang="pug">
div(v-if="(isWorkLineWithElement() || !invoiceHeader[dataKey].editable) || disabled || preview")
  div.content-span-document-detail
    span(v-model="line[dataKey]") 
    span {{ invoiceHeader[dataKey].prefix }}
    span {{ formatNumberToString(line[dataKey], document.situationType && (dataKey == 'situationProgressQuantity' || dataKey == 'previousSituationProgressQuantity') ? 4 : 2) }}
    span {{ invoiceHeader[dataKey].unit }}
.marginCol(v-else-if="!preview && documentOptions.calculateMargin && (dataKey == 'unitPriceHt' || dataKey == 'disbursedNetHt')")
  .marginCol-content
    input.w-100.margin-detail(@focus="emitEditFocus(true)" @blur="blurFunction(false, dataKey)" :style="line[dataKey] < 0 ? 'color:red!important' : ''" :disabled="line.isDisabled" :state="line['disbursedNetHt'] >= 0 && line['disbursedNetHt'] != null ? null : false" type='number' :class="edit ? 'edit' : 'no-edit' " v-model="line[dataKey]")
    .hide-margin-detail(v-if="!(dataKey == 'disbursedNetHt' && productProvidersRelated.length == 0)")
      div.d-flex
        .w-100(v-if="dataKey == 'unitPriceHt'")
          b-form-group(label='Marge U. HT' label-for='marginBrutHt')
            ejs-numerictextbox(:enabled="false" format="c2" :showSpinButton="false" v-model="line['marginBrutHt']")
        .w-100(v-else-if="dataKey == 'disbursedNetHt' && productProvidersRelated.length > 0")
          b-form-group(label='Produit fournisseur associé' label-for='productProviderRelated')
            b-form-select.edit.select-product-provider-related(@change="changeProductProviderRelated" v-model="line['productProviderId']")
              b-form-select-option(v-for="option in productProvidersRelated" :key="option.id" :value="option.id") {{ (option.reference ? option.reference : 'Aucune référence') +' - '+ (option.label ? option.label : 'Produit sans libellé') }}
input.w-100(v-else @focus="emitEditFocus(true)" @blur="blurFunction(false, dataKey)" :disabled="line.isDisabled && dataKey!=='situationProgressQuantity' && dataKey!=='situationProgress' ? true : false" type="number" min='0' :step='0.01' :placeholder="dataKey == 'situationProgress' ? line[dataKey] : ''"  :class="[edit && canEdit ? 'edit' : 'no-edit', line.isDisabled && dataKey!=='situationProgress' && dataKey!=='situationProgressQuantity' ? 'disabled' :'']" v-model="line[dataKey]")             
</template>

<script>
import { applyDeduction } from "@/types/api-orisis/library/DeductionOperations.ts";
import { calculateSubTotalLineBySection } from "@/types/api-orisis/library/DocumentOperations";
import {
  formatNumber,
  formatNumberDecimal,
  formatNumberToString,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      calculateSubTotalLineBySection,
    };
  },
  computed: {
    ...mapGetters([
      "document",
      "deduction",
      "productProvidersList",
      "catalog",
      "institutionSettingsActive",
    ]),
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
    productProvidersRelated() {
      return this.productProvidersList
        ? this.productProvidersList.filter(
            (el) => el.productId == this.line.productId && el.productId
          )
        : [];
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
  },
  methods: {
    ...mapActions(["updateDocumentDetail", "updateCreditDetail"]),
    formatNumber,
    formatNumberToString,
    formatNumberDecimal,
    applyDeduction,
    isWorkLineWithElement() {
      if (
        this.dataKey == "unitPriceHt" ||
        this.dataKey == "disbursedNetHt" ||
        this.dataKey == "marginCoefficient"
      ) {
        if (this.line.type == 6) {
          let workLineElements = this.documentDetails.filter(
            (el) => el.parentId == this.line.id
          );
          if (workLineElements && workLineElements.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    changeProductProviderRelated(val) {
      let product = this.catalog.find((el) => el.id == this.line.productId);
      let productProvider = this.productProvidersList.find(
        (el) => el.id == val
      );
      this.line.productProviderId = productProvider.id;
      this.line["marginCoefficient"] = product.priceHT
        ? productProvider.netPrice
          ? this.formatNumber(product.priceHT / productProvider.netPrice)
          : 0
        : getMarginCoefficient(this.line, this.institutionSettingsActive);
      this.line["unitPriceHt"] = product.priceHT
        ? Math.round(product.priceHT * 100) / 100
        : productProvider.netPrice
        ? Math.round(
            productProvider.netPrice *
              getMarginCoefficient(this.line, this.institutionSettingsActive)
          )
        : this.line["unitPriceHt"];
      this.line["disbursedNetHt"] = productProvider.netPrice
        ? productProvider.netPrice
        : this.line["dryDisbursed"];

      this.line["marginBrutHt"] =
        product.priceHT == 0
          ? this.formatNumber(
              this.line["unitPriceHt"] - this.line["disbursedNetHt"]
            )
          : productProvider.grossMarginHT
          ? productProvider.grossMarginHT
          : this.line["grossMarginHT"];

      this.line["marginRate"] =
        product.priceHT == 0
          ? this.formatNumber(
              (this.line["marginBrutHt"] / this.line["unitPriceHt"]) * 100
            )
          : productProvider.marginRate
          ? productProvider.marginRate
          : this.line["marginRate"];
      this.line["total"] = this.formatNumber(
        this.line["unitPriceHt"] * this.line["quantity"]
      );
      function getMarginCoefficient(line, institutionSettingsActive) {
        if (
          (line.type == 4 || line.type == 14) &&
          institutionSettingsActive.supplyMarginDefault &&
          institutionSettingsActive.supplyMarginDefault > 0
        ) {
          return institutionSettingsActive.supplyMarginDefault;
        } else if (
          (line.type == 5 || line.type == 17) &&
          institutionSettingsActive.manPowerMarginDefault &&
          institutionSettingsActive.manPowerMarginDefault > 0
        ) {
          return institutionSettingsActive.manPowerMarginDefault;
        } else if (
          line.type == 6 &&
          institutionSettingsActive.laborMarginDefault &&
          institutionSettingsActive.laborMarginDefault > 0
        ) {
          return Number(institutionSettingsActive.laborMarginDefault);
        } else if (
          (line.type == 7 || line.type == 15) &&
          institutionSettingsActive.subContractingMarginDefault &&
          institutionSettingsActive.subContractingMarginDefault > 0
        ) {
          return institutionSettingsActive.subContractingMarginDefault;
        } else if (
          (line.type == 8 || line.type == 16) &&
          institutionSettingsActive.equipementMarginDefault &&
          institutionSettingsActive.equipementMarginDefault > 0
        ) {
          return institutionSettingsActive.equipementMarginDefault;
        } else if (
          (line.type == 9 || line.type == 18) &&
          institutionSettingsActive.variousMarginDefault &&
          institutionSettingsActive.variousMarginDefault > 0
        ) {
          return institutionSettingsActive.variousMarginDefault;
        } else {
          return 1;
        }
      }
    },
    change(res, calculateSubTotal = false) {
      const decimals = this.document.situationType ? 4 : 2;
      this.line[this.dataKey] = formatNumber(this.line[this.dataKey], decimals);
      if (
        this.line[this.dataKey] < this.line["previousSituationProgress"] &&
        this.dataKey == "situationProgress"
      ) {
        this.line["situationProgress"] = this.line["previousSituationProgress"];
        this.change(
          { ...res, target: { value: this.line["previousSituationProgress"] } },
          true
        );
      } else if (
        this.line[this.dataKey] > 100 &&
        (this.dataKey == "situationProgress" || this.dataKey == "discount")
      ) {
        this.line[this.dataKey] = 100;
        this.change({ ...res, target: { value: 100 } }, true);
      } else if (
        this.line[this.dataKey] > this.line["quantity"] &&
        this.dataKey == "quantityDeducted"
      ) {
        this.line["quantityDeducted"] = this.line["quantity"];
        this.change({ ...res, target: { value: this.line["quantity"] } }, true);
      } else if (
        this.line["situationProgressQuantity"] > this.line["quantity"]
      ) {
        this.line["situationProgressQuantity"] = this.line["quantity"];
        this.change({ ...res, target: { value: this.line["quantity"] } }, true);
      } else if (
        this.line["situationProgressQuantity"] <
        this.line["previousSituationProgressQuantity"]
      ) {
        this.line["situationProgressQuantity"] =
          this.line["previousSituationProgressQuantity"];
        this.change(
          {
            ...res,
            target: { value: this.line["previousSituationProgressQuantity"] },
          },
          true
        );
      } else {
        // On traite les lignes

        if (this.document.nature != 6) {
          let advancement =
            this.document.nature == 4 && !this.document.situationType
              ? (this.line["situationProgress"] -
                  this.line["previousSituationProgress"]) /
                100
              : this.document.nature == 4 && this.document.situationType
              ? (this.line["situationProgressQuantity"] -
                  this.line["previousSituationProgressQuantity"]) /
                this.line["quantity"]
              : 1;
          let discount =
            Math.round(((100 - this.line["discount"]) / 100) * 100) / 100;
          this.line["total"] = formatNumber(
            this.line["unitPriceHt"] *
              this.line["quantity"] *
              advancement *
              discount
          );
        } else {
          this.line["total"] = formatNumber(
            this.line["unitPriceHt"] * this.line["quantityDeducted"]
          );
        }
      }

      if (this.line.type == 6) {
        let workLineElements = this.documentDetails.filter(
          (el) => el.parentId == this.line.id
        );
        for (let index = 0; index < workLineElements.length; index++) {
          const element = workLineElements[index];
          element.situationProgress = formatNumber(this.line.situationProgress);
          let priceBrutHt =
            Math.round(element.unitPriceHt * element.quantity * 100) / 100;
          let advancement =
            this.document.nature == 4
              ? (element.situationProgress -
                  element.previousSituationProgress) /
                100
              : 1;
          let advancementPriceBrutHt =
            Math.round(priceBrutHt * advancement * 100) / 100;
          let discount = element.discount
            ? Math.round(((100 - element.discount) / 100) * 100) / 100
            : 1;
          element.total = formatNumber(advancementPriceBrutHt * discount);
          this.document.nature == 5 || this.document.nature == 6
            ? this.updateCreditDetail({ creditDetail: element })
            : this.updateDocumentDetail({ documentDetail: element });
        }
      } else if (
        this.line.type == 14 ||
        this.line.type == 15 ||
        this.line.type == 16 ||
        this.line.type == 17 ||
        (this.line.type == 18 && this.dataKey !== "situationProgress")
      ) {
        let workLine = this.documentDetails.find(
          (el) => el.id == this.line.parentId
        );
        let workLineElements = this.documentDetails.filter(
          (el) => el.parentId == this.line.parentId
        );
        let totalPriceHt = 0;
        let totalDryDisbursed = 0;
        let discountWorkLineElement = 0;
        let totalWorkLineElements = 0;
        for (let index = 0; index < workLineElements.length; index++) {
          const element = workLineElements[index];
          totalWorkLineElements += element.total;
          discountWorkLineElement = element.discount
            ? Math.round(((100 - element.discount) / 100) * 100) / 100
            : 1;
          totalPriceHt += formatNumber(
            element.unitPriceHt * element.quantity * discountWorkLineElement
          );
          totalDryDisbursed += formatNumber(
            element.disbursedNetHt * element.quantity
          );
        }
        workLine.total = formatNumber(
          totalWorkLineElements * workLine.quantity
        );
        workLine.situationProgress = formatNumber(
          (workLine.total / totalPriceHt) * 100
        );

        workLine.unitPriceHt = formatNumber(totalPriceHt);
        workLine.disbursedNetHt = formatNumber(totalDryDisbursed);

        workLine.marginCoefficient = formatNumber(
          totalPriceHt / totalDryDisbursed
        );

        workLine.marginBrutHt = formatNumber(
          workLine.unitPriceHt - workLine.disbursedNetHt
        );
        workLine.marginRate = formatNumber(
          workLine.unitPriceHt == 0
            ? 0
            : (workLine.marginBrutHt / workLine.unitPriceHt) * 100
        );
        this.document.nature == 5 || this.document.nature == 6
          ? this.updateCreditDetail({ creditDetail: workLine })
          : this.updateDocumentDetail({ documentDetail: workLine });
      }
      if (calculateSubTotal) this.calculateSubTotalLineBySection(this.line);
    },
    async blurFunction(res, hiddenFields = null) {
      this.$emit("emitEditFocus", res);
      if (this.documentOptions.calculateMargin) {
        this.unitPriceHtChange(hiddenFields);
      }
      await this.change(null, true);
      this.document.nature == 5 || this.document.nature == 6
        ? await this.updateCreditDetail({ creditDetail: this.line })
        : await this.updateDocumentDetail({ documentDetail: this.line });
      this.applyDeduction();
    },
    unitPriceHtChange(field) {
      if (field == "disbursedNetHt") {
        this.line["disbursedNetHt"] = formatNumber(this.line["disbursedNetHt"]);
        if (this.line["disbursedNetHt"] && this.line["disbursedNetHt"] > 0) {
          this.line["unitPriceHt"] = formatNumber(
            this.line["disbursedNetHt"] * this.line["marginCoefficient"]
          );
        } else {
          this.line["marginCoefficient"] = 0;
        }
      } else if (field == "marginCoefficient") {
        this.line["marginCoefficient"] = formatNumber(
          this.line["marginCoefficient"]
        );
        if (this.line["disbursedNetHt"] > 0) {
          this.line["unitPriceHt"] = formatNumber(
            this.line["disbursedNetHt"] * this.line["marginCoefficient"]
          );
        } else {
          this.line["disbursedNetHt"] = formatNumber(
            this.line["unitPriceHt"] / this.line["marginCoefficient"]
          );
        }
      } else if (field == "unitPriceHt") {
        this.line["unitPriceHt"] = formatNumber(this.line["unitPriceHt"]);
        this.line["marginCoefficient"] = this.line["disbursedNetHt"]
          ? formatNumber(this.line["unitPriceHt"] / this.line["disbursedNetHt"])
          : 0;
      }
      this.line["marginBrutHt"] = formatNumber(
        this.line["unitPriceHt"] - this.line["disbursedNetHt"]
      );
    },
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
  },
  props: {
    preview: {
      default: false,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    invoiceHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    mouseOn: {
      type: Boolean,
    },
    disabled: {
      default: false,
    },
  },
};
</script>

<style lang="scss">
.e-input-group {
  & .e-input-group-icon:hover {
    display: block !important;
  }
}
</style>
