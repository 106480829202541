<template lang="pug">
  div(v-if="invoiceHeader[dataKey].inputType == 1") 
    TextCol(:disabled="disabled" @emitEditFocus="emitEditFocus" :line="line" :canEdit="false" :dataKey="dataKey" :invoiceHeader="invoiceHeader" :edit="edit" :editFocus="editFocus" :mouseOn="mouseOn")
  div(v-else-if="invoiceHeader[dataKey].inputType == 10") 
    SelectCol(:disabled="disabled" @emitEditFocus="emitEditFocus" :line="line" :canEdit="false" :dataKey="dataKey" :invoiceHeader="invoiceHeader" :edit="edit" :editFocus="editFocus" :mouseOn="mouseOn") 
  div(v-else-if="invoiceHeader[dataKey].inputType == 24") 
    SelectCatalog(:disabled="disabled" @emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :invoiceHeader="invoiceHeader" :edit="edit" :editFocus="editFocus" :mouseOn="mouseOn")
  div(v-else-if="invoiceHeader[dataKey].inputType == 2") 
    NumberCol(:disabled="disabled" @emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :invoiceHeader="invoiceHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus")
</template>

<script>
import TextCol from "@/components/invoice/builder/table/cols/TextCol.vue";
import SelectCatalog from "@/components/invoice/builder/table/cols/SelectCatalog.vue";
import SelectCol from "@/components/invoice/builder/table/cols/SelectCol.vue";
import NumberCol from "@/components/invoice/builder/table/cols/NumberCol.vue";

export default {
  data() {
    return {};
  },
  props: {
    mouseOn: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    invoiceHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  components: {
    TextCol,
    SelectCatalog,
    SelectCol,
    NumberCol,
  },
};
</script>

<style>
.input {
  padding: 0.375rem 0.75rem;
}
.no-edit {
  border: none;
}
</style>
