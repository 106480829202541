<template lang="pug"> 
  <div style="height:34px!important">&nbsp;</div>
</template>
<script>
import BreakPageCol from '@/components/invoice/builder/table/cols/BreakPageCol.vue'

export default {
  data () {
    return {}
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      required: true
    },
    invoiceHeader: {
      type: Object,
      required: true
    },
    dataKey: {
      type: String,
      required: true
    }
  },
  components: {
    BreakPageCol
  }
}
</script>
