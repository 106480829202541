<template lang="pug"> 
  td
    div(v-if="invoiceHeader[dataKey].inputType == 1") 
      span {{invoiceHeader[dataKey].prefix }} 
      span {{invoiceHeader[dataKey].numberToFixed ? (line[dataKey]).toFixed(invoiceHeader[dataKey].numberToFixed) : line[dataKey]}}
      span {{invoiceHeader[dataKey].unit}}
    div(v-else-if="invoiceHeader[dataKey].inputType == 25")  
      span(:style="'color:'+document.institutionColor+' !important'") {{line[dataKey]}}
</template>
<script>
import { mapGetters } from 'vuex'
import TitleCol from '@/components/invoice/builder/table/cols/TitleCol.vue'
import TextCol from '@/components/invoice/builder/table/cols/TextCol.vue'

export default {
  data () {
    return {}
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      required: true
    },
    invoiceHeader: {
      type: Object,
      required: true
    },
    dataKey: {
      type: String,
      required: true
    },
    editFocus: {
      type: Boolean,
      required: true
    },
   
  },
  computed: {
    ...mapGetters(['document'])
  },
  methods: {
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    }
  },
  components: {
    TitleCol,
    TextCol
  }
}
</script>
