<template lang="pug">
  .quote-card(v-if="!preview" :style="!document.affairId && disabled ? 'opacity: 0;' : ''")
    b-form-group(v-if="!document.affairId && !disabled" label-for='affair')
      validation-provider(#default='{ errors }' name='Affaire' rules='required')
        v-select.no-printting#company(:loading='isLoadingAffair' :state='errors.length > 0 ? false : null' :value='document.affairName' @input='setValue' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='getOptions()' :placeholder='placeholder')
          template(v-slot:no-options='')
            template  {{alertSelectCompany ? 'Sélectionnez un client pour obtenir la liste de ses affaires' : 'Votre client ne dispose d\'aucune affaire'}}
          li.border-bottom.p-1.py-50(slot='list-header')
            b-button.w-100.d-flex.justify-content-center.align-items-center(v-if="document.type == 4" @click='newLease()' variant='primary' size='sm')
              | + Nouveau bail
              span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                | open_in_new
            b-button.w-100.d-flex.justify-content-center.align-items-center(v-else @click='newAffair()' variant='primary' size='sm')
              | + Nouvelle affaire
              span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                | open_in_new
        small.text-danger {{ errors[0] }}
    .card-info(v-else @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
      .card-tools.pr-0(v-if="showCardTool && !disabled")
        feather-icon(icon="RefreshCwIcon" size="18" v-b-tooltip.hover.top="'Actualiser la fiche affaire'" @click="refreshAffair(affairId)")
        feather-icon(icon="ExternalLinkIcon" size="18" v-b-tooltip.hover.top="`Gérer l'affaire`" @click="navigateToAffairDetails(document.affairId)")
        feather-icon(v-if='document.nature == 0 || document.nature == 1' icon="XIcon" size="18" @click="resetValue"  v-b-tooltip.hover.top="`Changer d'affaire`")
      span {{ document.affairCode ? document.affairCode + ' - ' : "" }}{{ document.affairName }}
      div(v-if="document.affairAddress != null")
        span {{ document.affairAddress }}
      div(v-if="document.affairAddressComplement != null")
        span {{ document.affairAddressComplement }}
      div(v-if="document.affairZipCode != null || document.affairCity != null")
        span {{ document.affairZipCode }} {{ document.affairCity }}
      div(v-if="document.affairCountry != null")
        span {{ document.affairCountry }}
  .quote-card(v-else-if="preview && document.affairName!==null")
    .card-info
      span {{ document.affairCode && !documentOptions.showAffairCode ? document.affairCode + ' - ' : ''}}{{ document.affairName }}
      div(v-if="document.affairAddress != null")
        span {{ document.affairAddress }}
      div(v-if="document.affairAddressComplement != null")
        span {{ document.affairAddressComplement }}
      div(v-if="document.affairZipCode != null || document.affairCity != null")
        span {{ document.affairZipCode }} {{ document.affairCity }}
      div(v-if="document.affairCountry != null")
        span {{ document.affairCountry }}
</template>

<script>
import vSelect from "vue-select";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";

configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  components: {
    "v-select": vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    preview: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      default: false,
    },
    affairId: {
      default: 0,
    },
  },
  data() {
    return {
      value: null,
      showCardTool: false,
      required,
      alertSelectCompany: false,
    };
  },
  computed: {
    ...mapGetters(["isLoadingAffair"]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
  },
  created() {
    this.getAffairs({});
  },
  methods: {
    ...mapActions(["getAffairs", "getAffairById", "getNewLease"]),
    getOptions() {
      if (this.document.companyId) {
        this.alertSelectCompany = false;
        let optionsFiltered = this.options.filter(
          (opt) => opt.company?.id == this.document.companyId
        );
        return optionsFiltered.map((elem) => {
          return {
            label: (elem.code ? elem.code + " - " : "") + elem.name,
            value: elem.id,
          };
        });
      } else {
        this.alertSelectCompany = true;
        return []; //this.options.map((elem)=>{return {label:elem.name, value:elem.id}})
      }
    },
    navigateToAffairDetails(affairId) {
      if (affairId && affairId !== 0) {
        this.$router.push({ name: "affair", params: { id: affairId } });
      }
    },
    setValue(value) {
      this.$emit(
        "setValue",
        this.options.find((elem) => {
          return elem.id == value.value;
        })
      );
    },
    refreshAffair(id) {
      this.getAffairById({ affairId: id }).then((res) => {
        this.document.affairName = res.name;
        this.document.affairCode = res.code;
        this.document.affairAddress = res.address;
        this.document.affairAddressComplement = res.addressComplement;
        this.document.affairZipCode = res.zipCode;
        this.document.affairCity = res.city;
        this.document.affairCountry = res.country;
        this.$emit("saveAction");
      });
    },
    resetValue() {
      let value = {
        id: null,
        name: null,
        address: null,
        addressComplement: null,
        city: null,
        zipCode: null,
        country: null,
      };
      this.value = null;
      this.$emit("setValue", value);
    },
    newAffair(id) {
      this.$router.push({
        name: "new-affair",
        params: {
          id: 0,
          companyId: this.document.companyId,
          title: "Nouvelle affaire",
          tips: "Nouvelle affaire",
          routeOrigine: "affairs",
        },
      });
    },
    async newLease(id) {
      await this.getNewLease({
        institutionId: this.document.institutionId,
      }).then((lease) => {
        this.$router.push({
          name: "new-lease",
          params: {
            id: 0,
            title: "Nouveau bail",
            tips: "Nouveau bail",
            routeOrigine: "leases",
          },
        });
      });
    },
    editAffair(id) {
      this.$router.push({
        name: "edit-affair",
        params: {
          id: Number(id),
          title: "Edit : " + this.document.affairName,
          tips: "Editer l'affaire : " + this.document.affairName,
          routeOrigine: "edit-quote",
        },
      });
      this.resetValue();
    },
  },

  watch: {
    "document.companyId"(newVal, oldVal) {
      if (!newVal || newVal == 0) {
        this.resetValue();
      }
    },
  },
};
</script>

<style>
.card-info {
  padding: 10px;
  position: relative;
  background-color: #f1f1f1 !important;
  border: solid 2px #f1f1f1;
  border-radius: 3px;
}
.card-tools {
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: white;  */
  padding: 10px;
  border-bottom: solid 2px #f1f1f1;
}
.card-tools > * {
  margin-right: 10px;
  cursor: pointer;
}
</style>
