<template lang="pug">
  vs-divider(color="dark" v-if="!preview") Saut de page
</template>
<script>
export default {
  data() {
    return {
    };
  },
  props: {
    preview: {
        default: false,
    },
    edit: {
      type: Boolean,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true
    },
    line: {
      type: Object,
      required: true
    },
    invoiceHeader: {
      type: Object,
      required: false
    },
    dataKey: {
      type: String,
      required: true
    }
  },
};
</script>