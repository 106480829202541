<template lang="pug">
#invoiceContainer(v-if="this.$route.fullPath.includes(document.id)")
  .content-loader-center.m-0.h-100(v-if='isLoadingDocument')
    .text-center.flex-center.h-100
      .loading-bg-inner(role='status')
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement du document...
  div(v-else style="height: calc(100% - 50px) !important")
    InvoiceBuilderHeader(:key="id" @changePreviewKey="changePreviewKey" @documentTypeChanged="type => documentType = type")
    InvoiceBuilder(v-if="documentTabActive == 'document-edit'")
    .content-tab-fullpage(v-else-if="documentTabActive == 'document-pdf'" :key="previewKey")
      InvoicePdf(:documentType="documentType")
    .content-tab-fullpage(v-else-if="documentTabActive == 'internal-note'")
      InternalNote
    .content-tab-fullpage(v-else-if="documentTabActive == 'attachments'")
      ged-viewer-fullpage(:parentFolder="document.folderId" :fromModule="document.nature == 0 ? 'quote' : (document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4) ? 'invoice' : 'credit'")
</template>

<script>
import { checkFunctionUser } from "@/auth/utils";

import InvoiceBuilderHeader from "@/components/invoice/InvoiceBuilderHeader.vue";
import InvoiceBuilder from "@/components/invoice/InvoiceBuilder.vue";
import InvoicePdf from "@/components/invoice/InvoicePdf.vue";
import InvoicePreview from "@/components/invoice/InvoicePreview.vue";
import InternalNote from "@/components/invoice/internal-note/InternalNote.vue";
import { mapGetters, mapActions } from "vuex";
import { QuoteDefaultProperties } from "@/types/api-orisis/models/DocumentModels";
import { DeductionDefaultProperties } from "@/types/api-orisis/models/DeductionModels";
import GedViewerFullpage from "@/components/ged/GedViewerFullpage";

export default {
  name: "edit-document",
  ref: "edit-document",

  data() {
    return {
      QuoteDefaultProperties,
      DeductionDefaultProperties,
      editDocumentLoaded: false,
      previewKey: 0,
      documentType: null,
    };
  },
  props: {
    id: {
      default: null,
    },
    mode: {
      default: "document-edit",
    },
  },
  watch: {
    async $route(to, from) {
      this.$destroy();
    },
  },
  async created() {
    await this.getModuleParameters({});
    if (
      (this.document.status >= 2 && this.document.status !== 8) ||
      this.document.usedUrlGed
    ) {
      await this.changeDocumentTabActive("document-pdf");
    } else {
      await this.changeDocumentTabActive(this.mode);
    }
    if (this.document.status === 0) {
      let documentDate = new Date().toISOString();
      this.$store.commit("SET_DOCUMENT", { ...this.document, documentDate });
    }
    // TODO : Mettre le document sur edit à chaque ouverture sauf exeptions
    // TODO : Que se passe t il si je relance l'application sur le devis ?
    // TODO : si je fais cette fonction, elle se déclanche aussi lorsque que je créer un document donc 2 appels
    // il faut qu'elle se déclanche uniquement en cas de reaload
    this.invoiceHeader.referencielTvaId.choice =
      this.institutionSettingsActive.referencielTvas.map((elem) => {
        return {
          id: elem.referencielTva.id,
          label: elem.referencielTva.label,
          value: elem.referencielTva.value,
        };
      });
    if (!this.unitsList || this.unitsList.length == 0) {
      await this.getUnits({}).then((res) => {
        this.invoiceHeader.unitId.choice = res;
      });
    } else {
      this.invoiceHeader.unitId.choice = this.unitsList;
    }

    this.getProducts({});
    if (this.checkFunctionUser(74, "read")) {
      this.getProductProviders({});
    }

    this.editDocumentLoaded = true;
  },
  computed: {
    ...mapGetters([
      "institutionSettingsActive",
      "isLoadingDocument",
      "documentTabActive",
      "unitsList",
      "isLoadingDocumentDetailDuplication",
      "isUpdatingDocument",
      "isDeletingDocument",
    ]),
    invoiceHeader: {
      get() {
        return this.$store.getters.getInvoiceHeader;
      },
      set(val) {
        this.$store.commit("setInvoiceHeader", { ...invoiceHeader, val });
      },
    },
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "getModuleParameters",
      "getProducts",
      "getProductProviders",
      "getUnits",
      "changeDocumentTabActive",
    ]),
    checkFunctionUser,
    changePreviewKey() {
      this.previewKey++;
    },
  },
  components: {
    InvoiceBuilder,
    InvoiceBuilderHeader,
    InvoicePreview,
    InvoicePdf,
    InternalNote,
    GedViewerFullpage,
  },
};
</script>

<style scoped>
#invoiceContainer {
  width: 98%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.content-tab-fullpage {
  padding-top: 20px !important;
  background-color: white !important;
  height: 100%;
}
</style>
