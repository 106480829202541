<template lang="pug">
.dropdown.search-select(v-if="canEdit && !preview && !disabled")
  quill-editor(ref="quillEditor" @focus="focusFunction" @blur="blurFunction(false)"  :disabled="line.isDisabled"  :class="[(editFocus && mouseOn) || (searchCatalog && mouseOn) ? 'edit' : 'no-edit', editFocus? 'focus' : '', searchCatalog && isFocusSearchCatalog ? 'searchCatalog' : '', line.isDisabled && dataKey!=='situationProgressQuantity' && dataKey!=='situationProgress' ? 'disabled' :'']" v-model="line[dataKey]" :options="editorOption")
  span.feather-search-catalog(class='material-icons' :class="editFocus && mouseOn  ? 'edit' : ''" @click="activeSearchCatalog()") search
  input(class="w-100" :ref="'fakeInputSelectCatalogDocument'+index" style="width: 0px !important; position: absolute; display:block")
  .select-results.dropdown-content(v-show="searchCatalog")
    input(class="w-100 edit" @focus="focusSearchCatalog(true)" @blur="blurSearchCatalog(false)" name="keywordsCatalog" ref="keywordsCatalog" placeholder="Saisissez vos mots-clés ou une référence pour rechercher dans le catalogue" v-model="keywordsCatalog") 
    div(v-if="keywordsCatalog.length > 1")
      div(v-for="(item, key) in productsListByTypeLine(line, catalogSortedByProductType, keywordsCatalog)")
        span.select-title {{ key }}
        div(v-if="item.length > 0")
          .d-flex.align-items-end(v-for="(product, index) in item")
            .d-flex.p-50.cursor-pointer.select-catalogue-line(style="flex:1" @click="affectValue(product)")
              div(style="width:100px") {{ product.reference }}
              div(style="flex:1")
                span(v-html="product.description")
              div.text-right(style="width:100px")
                span {{ product.priceHT ? product.priceHT.toFixed(2) : (0).toFixed(2) }} €
        div.text-center(v-else)
          span
            | Aucun article trouvé de type {{ key.toLowerCase() }}
div.content-html.d-flex.align-items-center(v-else style="margin-top: 7px; min-height: 32px !important;")
  div(v-html="line[dataKey]")
</template>

<script>
import { applyDeduction } from "@/types/api-orisis/library/DeductionOperations.ts";
import { productsListByTypeLine } from "@/types/api-orisis/library/DetailDocumentOperations";
import { calculateSubTotalLineBySection } from "@/types/api-orisis/library/DocumentOperations";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import {
  formatNumber,
  formatNumberDecimal,
  formatNumberToString,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { checkFunctionUser } from "@/auth/utils.ts";

import "quill/dist/quill.bubble.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      isFocus: false,
      isFocusSearchCatalog: false,
      lineOrigine: {},
      editorOption: {
        placeholder: "Description...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
      searchCatalog: false,
      keywordsCatalog: "",
      calculateSubTotalLineBySection,
      productsListByTypeLine,
    };
  },
  props: {
    preview: {
      default: false,
    },
    mouseOn: {
      type: Boolean,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    invoiceHeader: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
    },
    disabled: {
      default: false,
    },
  },
  watch: {
    editFocus(val) {
      if (
        val == false &&
        (this.editFocus == true || this.searchCatalog == true)
      ) {
        this.$refs["fakeInputSelectCatalogDocument" + this.index]?.focus({
          preventScroll: true,
        });
        this.searchCatalog = false;
        this.keywordsCatalog = "";
      } else if (val == false) {
        this.searchCatalog = false;
        this.keywordsCatalog = "";
      }
    },
  },
  created() {
    this.lineOrigine = JSON.parse(JSON.stringify(this.line[this.dataKey]));
    this.searchCatalog = false;
  },
  mounted() {
    this.searchCatalog = false;
  },
  computed: {
    ...mapGetters([
      "catalogSortedByProductType",
      "document",
      "catalog",
      "institutionSettingsActive",
      "productProvidersList",
    ]),
  },
  methods: {
    ...mapActions([
      "updateDocumentDetail",
      "addLine",
      "updateCreditDetail",
      "getProductById",
    ]),
    checkFunctionUser,
    formatNumber,
    formatNumberToString,
    formatNumberDecimal,
    applyDeduction,
    activeSearchCatalog() {
      this.searchCatalog = true;
      let _this = this;
      this.$nextTick(() => {
        _this.$refs.keywordsCatalog.focus({ preventScroll: true });
      });
    },
    focusSearchCatalog(res) {
      this.isFocusSearchCatalog = res;
    },
    blurSearchCatalog(res) {
      if (!this.mouseOn) {
        this.$emit("emitEditFocus", false);
        this.searchCatalog = false;
      }
    },
    focusFunction(res) {
      this.$emit("emitEditFocus", true);
    },
    blurFunction(res) {
      this.$emit("emitEditFocus", false);
      if (structuredClone(this.line[this.dataKey]) !== this.lineOrigine) {
        this.document.nature == 5 || this.document.nature == 6
          ? this.updateCreditDetail({ creditDetail: this.line })
          : this.updateDocumentDetail({ documentDetail: this.line });
      }
    },
    async affectValue(val) {
      if (
        (this.line.type == 14 ||
          this.line.type == 15 ||
          this.line.type == 16 ||
          this.line.type == 17 ||
          this.line.type == 18) &&
        val.productType == 3
      ) {
        this.$toast.error(
          "Vous ne pouvez pas ajouter un ouvrage dans un ouvrage"
        );
      } else {
        if (this.checkFunctionUser(34, "all")) {
          let productProvidersRelated = this.productProvidersList.filter(
            (item) => item.productId == val.id
          );
          if (productProvidersRelated.length > 0) {
            this.line.productProviderId = productProvidersRelated[0].id;
            this.line["marginCoefficient"] = val.priceHT
              ? productProvidersRelated[0].netPrice
                ? this.formatNumber(
                    val.priceHT / productProvidersRelated[0].netPrice
                  )
                : 0
              : getMarginCoefficient(this.line, this.institutionSettingsActive);

            this.line["unitPriceHt"] = val.priceHT
              ? Math.round(val.priceHT * 100) / 100
              : productProvidersRelated[0].netPrice
              ? Math.round(
                  productProvidersRelated[0].netPrice *
                    getMarginCoefficient(
                      this.line,
                      this.institutionSettingsActive
                    )
                )
              : this.line["unitPriceHt"];
            this.line["disbursedNetHt"] = productProvidersRelated[0].netPrice
              ? productProvidersRelated[0].netPrice
              : this.line["dryDisbursed"];

            this.line["marginBrutHt"] =
              val.priceHT == 0
                ? this.formatNumber(
                    this.line["unitPriceHt"] - this.line["disbursedNetHt"]
                  )
                : productProvidersRelated[0].grossMarginHT
                ? productProvidersRelated[0].grossMarginHT
                : this.line["grossMarginHT"];

            this.line["marginRate"] =
              val.priceHT == 0
                ? this.formatNumber(
                    (this.line["marginBrutHt"] / this.line["unitPriceHt"]) * 100
                  )
                : productProvidersRelated[0].marginRate
                ? productProvidersRelated[0].marginRate
                : this.line["marginRate"];
          } else {
            this.line.productProviderId = null;
            this.line.marginCoefficient = 0;
            this.line.marginRate = 0;
            this.line.disbursedNetHt = 0;
            this.line.marginBrutHt = 0;
            this.line.unitPriceHt = val.priceHT ? val.priceHT : 0;
          }
        } else {
          this.line["marginCoefficient"] = val.priceHT
            ? val.dryDisbursed
              ? this.formatNumber(val.priceHT / val.dryDisbursed)
              : 0
            : getMarginCoefficient(this.line, this.institutionSettingsActive);
          this.line["unitPriceHt"] = val.priceHT
            ? Math.round(val.priceHT * 100) / 100
            : val.dryDisbursed
            ? Math.round(
                val.dryDisbursed *
                  getMarginCoefficient(
                    this.line,
                    this.institutionSettingsActive
                  )
              )
            : this.line["unitPriceHt"];

          this.line["disbursedNetHt"] = val.dryDisbursed
            ? val.dryDisbursed
            : this.line["dryDisbursed"];

          this.line["marginBrutHt"] = this.formatNumber(
            this.line["unitPriceHt"] - this.line["disbursedNetHt"]
          );

          this.line["marginRate"] =
            val.priceHT == 0
              ? this.formatNumber(
                  (this.line["marginBrutHt"] / this.line["unitPriceHt"]) * 100
                )
              : val.marginRate
              ? val.marginRate
              : this.line["marginRate"];
        }
        this.line["description"] = val.description
          ? val.description
          : val.label;

        this.line["quantity"] = val.quantity;
        this.line["discount"] = val.discount ? val.discount : 0;
        this.line["productId"] = val.id;
        this.line["reference"] = val.reference ? val.reference : null;
        this.line["unitId"] = val.unit ? val.unit.id : this.line["unitId"];
        this.line["total"] =
          Math.round(val.quantity * this.line["unitPriceHt"] * 100) / 100;
        this.line["referencielTvaId"] = val.referencielTvaId
          ? val.referencielTvaId
          : this.line["referencielTvaId"];

        if (
          this.line.type == 14 ||
          this.line.type == 15 ||
          this.line.type == 16 ||
          this.line.type == 17 ||
          this.line.type == 18
        ) {
          switch (val.productType) {
            case 0:
              this.line.type = 14;
              break;
            case 1:
              this.line.type = 15;
              break;
            case 2:
              this.line.type = 16;
              break;
            case 4:
              this.line.type = 1;
              break;
            case 5:
              this.line.type = 17;
              break;
            case 6:
              this.line.type = 18;
              break;
            default:
              this.line.type = 14;
              break;
          }
        } else {
          switch (val.productType) {
            case 0:
              this.line.type = 4;
              break;
            case 1:
              this.line.type = 7;
              break;
            case 2:
              this.line.type = 8;
              break;
            case 3:
              this.line.type = 6;
              break;
            case 4:
              this.line.type = 1;
              break;
            case 5:
              this.line.type = 5;
              break;
            case 6:
              this.line.type = 9;
              break;
            default:
              this.line.type = 9;
              break;
          }
        }

        this.document.nature == 5 || this.document.nature == 6
          ? await this.updateCreditDetail({ creditDetail: this.line })
          : await this.updateDocumentDetail({ documentDetail: this.line });
        await this.calculateSubTotalLineBySection(this.line);

        // On va ajouter des lignes pour gérer les composant d'un ouvrage si la ligne est un ouvrage
        if (val.productType == 3) {
          this.getProductById({ productId: val.id }).then(async (res) => {
            for (let index = 0; index < res.subProducts.length; index++) {
              const line = res.subProducts[index];
              line = {
                ...line,
                unit: line.unit ? line.unit.id : null,
                total: line.quantity ? line.priceHT * line.quantity : 1,
                unitPriceHt: line.priceHT,
                dryDisbursed: line.disbursedNetHt,
                referencielTvaId: this.line.referencielTvaId,
                review: true,
                type:
                  line.productType == 0
                    ? 14
                    : line.productType == 1
                    ? 15
                    : line.productType == 2
                    ? 16
                    : line.productType == 5
                    ? 17
                    : 18,
                parentId: this.line.id,
                disbursedNetHt: line.dryDisbursed,
                marginBrutHt: line.grossMarginHT,
                marginRate: line.marginRate,
              };

              // if (
              //   (line.type == 4 || line.type == 14) &&
              //   this.institutionSettingsActive.supplyMarginDefault &&
              //   this.institutionSettingsActive.supplyMarginDefault > 0
              // ) {
              //   line.marginCoefficient =
              //     this.institutionSettingsActive.supplyMarginDefault;
              // } else if (
              //   (line.type == 5 || line.type == 17) &&
              //   this.institutionSettingsActive.manPowerMarginDefault &&
              //   this.institutionSettingsActive.manPowerMarginDefault > 0
              // ) {
              //   line.marginCoefficient =
              //     this.institutionSettingsActive.manPowerMarginDefault;
              // } else if (
              //   line.type == 6 &&
              //   this.institutionSettingsActive.laborMarginDefault &&
              //   this.institutionSettingsActive.laborMarginDefault > 0
              // ) {
              //   line.marginCoefficient = Number(
              //     this.institutionSettingsActive.laborMarginDefault
              //   );
              // } else if (
              //   (line.type == 7 || line.type == 15) &&
              //   this.institutionSettingsActive.subContractingMarginDefault &&
              //   this.institutionSettingsActive.subContractingMarginDefault > 0
              // ) {
              //   line.marginCoefficient =
              //     this.institutionSettingsActive.subContractingMarginDefault;
              // } else if (
              //   (line.type == 8 || line.type == 16) &&
              //   this.institutionSettingsActive.equipementMarginDefault &&
              //   this.institutionSettingsActive.equipementMarginDefault > 0
              // ) {
              //   line.marginCoefficient =
              //     this.institutionSettingsActive.equipementMarginDefault;
              // } else if (
              //   (line.type == 9 || line.type == 18) &&
              //   this.institutionSettingsActive.variousMarginDefault &&
              //   this.institutionSettingsActive.variousMarginDefault > 0
              // ) {
              //   line.marginCoefficient =
              //     this.institutionSettingsActive.variousMarginDefault;
              // } else {
              //   line.marginCoefficient = 1;
              // }

              // if (
              //   line.disbursedNetHt > 0 &&
              //   (line.disbursedNetHt == line.unitPriceHt ||
              //     line.unitPriceHt == 0)
              // ) {
              //   line.unitPriceHt = formatNumber(
              //     line.disbursedNetHt * line.marginCoefficient
              //   );
              // }

              if (this.document.nature == 0) {
                await this.addLine({
                  documentDetail: {
                    ...line,
                    quoteId: this.document.id,
                    invoiceId: null,
                  },
                  indexArray: this.index + index + 1,
                  isCredit: false,
                  review: line.review,
                });
              } else if (
                this.document.nature == 1 ||
                this.document.nature == 2 ||
                this.document.nature == 3 ||
                this.document.nature == 4
              ) {
                await this.addLine({
                  documentDetail: {
                    ...line,
                    invoiceId: this.document.id,
                    quoteId: null,
                  },
                  indexArray: this.index + index + 1,
                  isCredit: false,
                  review: line.review,
                });
              } else if (this.document.nature == 6) {
                await this.addLine({
                  documentDetail: {
                    ...line,
                    invoiceId: null,
                    quoteId: null,
                    creditId: this.document.id,
                  },
                  indexArray: this.index + index + 1,
                  isCredit: true,
                  review: line.review,
                });
              }
            }
          });
        }

        this.keywordsCatalog = "";
        this.searchCatalog = false;
        this.applyDeduction();
      }

      function getMarginCoefficient(line, institutionSettingsActive) {
        if (
          (line.type == 4 || line.type == 14) &&
          institutionSettingsActive.supplyMarginDefault &&
          institutionSettingsActive.supplyMarginDefault > 0
        ) {
          return institutionSettingsActive.supplyMarginDefault;
        } else if (
          (line.type == 5 || line.type == 17) &&
          institutionSettingsActive.manPowerMarginDefault &&
          institutionSettingsActive.manPowerMarginDefault > 0
        ) {
          return institutionSettingsActive.manPowerMarginDefault;
        } else if (
          line.type == 6 &&
          institutionSettingsActive.laborMarginDefault &&
          institutionSettingsActive.laborMarginDefault > 0
        ) {
          return Number(institutionSettingsActive.laborMarginDefault);
        } else if (
          (line.type == 7 || line.type == 15) &&
          institutionSettingsActive.subContractingMarginDefault &&
          institutionSettingsActive.subContractingMarginDefault > 0
        ) {
          return institutionSettingsActive.subContractingMarginDefault;
        } else if (
          (line.type == 8 || line.type == 16) &&
          institutionSettingsActive.equipementMarginDefault &&
          institutionSettingsActive.equipementMarginDefault > 0
        ) {
          return institutionSettingsActive.equipementMarginDefault;
        } else if (
          (line.type == 9 || line.type == 18) &&
          institutionSettingsActive.variousMarginDefault &&
          institutionSettingsActive.variousMarginDefault > 0
        ) {
          return institutionSettingsActive.variousMarginDefault;
        } else {
          return 1;
        }
      }
    },
  },

  components: {
    "v-select": vSelect,
    quillEditor,
  },
};
</script>
<style>
.content-html p {
  margin-bottom: 0.5rem;
}
</style>
