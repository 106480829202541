<template lang="pug">
  div
    div.content-span-document-detail(v-if="!invoiceHeader[dataKey].editable || disabled")
      span(v-model="line[dataKey]") 
      span {{invoiceHeader[dataKey].prefix }} 
      span {{invoiceHeader[dataKey].numberToFixed ? (line[dataKey]).toFixed(invoiceHeader[dataKey].numberToFixed) : line[dataKey]}}
      span {{invoiceHeader[dataKey].unit}}
    input.w-100(:disabled="line.isDisabled" @focus="emitEditFocus(true)" @blur="blurFunction(false)" v-else :class="edit && canEdit ? 'edit' : 'no-edit'" v-model="line[dataKey]")
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["document"]),
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    invoiceHeader: {
      type: Object,
      required: false,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    ...mapActions(["updateDocumentDetail", "updateCreditDetail"]),
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
    blurFunction(res) {
      this.emitEditFocus(res);
      this.document.nature == 5 || this.document.nature == 6
        ? this.updateCreditDetail({ creditDetail: this.line })
        : this.updateDocumentDetail({ documentDetail: this.line });
    },
  },
};
</script>
