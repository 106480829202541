<template lang="pug">
div
  div(v-if="( documentType == null && document.nature == 0 || document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4 || (document.nature == 6 && !document.invoiceId))")
      div.d-flex(v-if="(!preview || (preview && ((quoteDownPaymentsRequestsList && quoteDownPaymentsRequestsList.length > 0) || deduction.holdbackNumber > 0 || (deduction.punctualDeductions && deduction.punctualDeductions.length>0) || deduction.prorataAccountPrice > 0 || (documentPaymentMethods && documentPaymentMethods.length > 0) || document.bankAccountId!==null) && !disabled))")
          h4 Conditions de paiement
          AddPaymentCondition(v-if="!preview && !disabled" @setPaymentCondition='setPaymentCondition').ml-2
      SelectValidityDate(:disabled="disabled" v-if="document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4" keyValue="limitDate" @setValue="saveAction" principalText="Date d'échéance" :preview="preview")
      QuoteDownPaymentsRequest(:disabled="disabled" :preview="preview" v-if="document.nature == 0" :editQuoteDownPaymentRequest='editQuoteDownPaymentRequest' @toggleQuoteDownPaymentsRequest='toggleQuoteDownPaymentsRequest')
      Holdback(:disabled="disabled" :preview="preview" :editHoldback='editHoldback' @toggleHoldback='toggleHoldback')
      PunctualDeduction(:disabled="disabled" :preview="preview" :editPunctualDeduction='editPunctualDeduction' @togglePunctualDeduction='togglePunctualDeduction')
      ProrataAccount(:disabled="disabled" :preview='preview' :editProrataAccount="editProrataAccount" @toggleProrataAccount='toggleProrataAccount')
      Review(:disabled="disabled" :isReview="isReview" :preview="preview" :editReview='editReview' @toggleReview='toggleReview')
      PaymentMethodChoice(:disabled="disabled" :preview="preview")
      BankAccountChoice(:disabled="disabled" v-if="!documentOptions.hideBankAccount && (document.nature != 5 && document.nature != 6)" @setValue="setBankAccount" :preview="preview").mb-1
      div.mt-1(v-if='!document.hideWasteCollection && document.type != 1 && document.nature == 0')
          WasteManagement(:disabled="disabled" :preview="preview")
  BottomNotes(:disabled="disabled" :preview="preview")
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PaymentMethodChoice from "@/components/invoice/builder/PaymentMethodChoice";
import BankAccountChoice from "@/components/invoice/builder/BankAccountChoice";
import AddPaymentCondition from "@/components/invoice/builder/AddPaymentCondition";
import WasteManagement from "@/components/invoice/builder/WasteManagement";
import QuoteDownPaymentsRequest from "@/components/invoice/builder/QuoteDownPaymentsRequest";
import Holdback from "@/components/invoice/builder/Holdback";
import PunctualDeduction from "@/components/invoice/builder/PunctualDeduction";
import ProrataAccount from "@/components/invoice/builder/ProrataAccount";
import BottomNotes from "@/components/invoice/builder/BottomNotes.vue";
import SelectValidityDate from "@/components/invoice/builder/SelectValidityDate";
import Review from "@/components/invoice/builder/Review";

export default {
  components: {
    PaymentMethodChoice,
    BankAccountChoice,
    QuoteDownPaymentsRequest,
    AddPaymentCondition,
    WasteManagement,
    Holdback,
    PunctualDeduction,
    ProrataAccount,
    BottomNotes,
    SelectValidityDate,
    Review,
  },
  props: {
    preview: {
      default: false,
    },
    disabled: {
      default: false,
    },
    documentType: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      editHoldback: false,
      editQuoteDownPaymentRequest: false,
      editPunctualDeduction: false,
      editProrataAccount: false,
      editReview: false,
      isReview: false,
    };
  },
  computed: {
    ...mapGetters([
      "quoteDownPaymentsRequestsList",
      "deduction",
      "paymentMethodsList",
      "documentPaymentMethods",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
  },
  methods: {
    ...mapActions(["updateQuote", "updateInvoice"]),
    setBankAccount(bank) {
      if (bank.id == null || bank.id == 0 || !bank) {
        this.document = {
          ...this.document,
          bankAccountId: null,
          bankAccountLabel: null,
          bankAccountName: null,
          bankAccountIban: null,
          bankAccountBic: null,
        };
        this.saveAction();
      } else {
        this.document = {
          ...this.document,
          bankAccountId: bank.id,
          bankAccountLabel: bank.label,
          bankAccountName: bank.name,
          bankAccountIban: bank.iban,
          bankAccountBic: bank.bic,
        };
        this.saveAction();
      }
    },
    saveAction() {
      if (this.document.nature == 0) {
        this.updateQuote({ quote: this.document, updateState: false });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.updateInvoice({ invoice: this.document, updateState: false });
      }
    },

    setPaymentCondition({ type, isReview = false }) {
      this.isReview = isReview;
      switch (type) {
        case "downPaymentRequest":
          this.editQuoteDownPaymentRequest = true;
          break;
        case "holdback":
          this.editHoldback = true;
          break;
        case "punctualDeduction":
          this.editPunctualDeduction = true;
          break;
        case "prorata":
          this.editProrataAccount = true;
          break;
        case "review":
          this.editReview = true;
          break;
        default:
          break;
      }
    },
    toggleHoldback(res = false) {
      this.editHoldback = res;
    },
    toggleQuoteDownPaymentsRequest(res = false) {
      this.editQuoteDownPaymentRequest = res;
    },
    togglePunctualDeduction(res = false) {
      this.editPunctualDeduction = res;
    },
    toggleProrataAccount(res = false) {
      this.editProrataAccount = res;
    },
    toggleReview(res = false) {
      this.editReview = res;
    },
  },
};
</script>
