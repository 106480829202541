<template lang="pug">
div.h-100
  b-row.my-0.h-100
    b-col.py-0.mb-1.h-100(cols='12')
      quill-editor.h-100.quill-editor-scroll(class="editor quill-fixed" @blur="saveAction" v-model="document.internalNote" :options="editorOption")
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      editorOption: {
        placeholder: 'Ecrivez une note interne lié à ce document ...',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            ['link'],
            ['clean'] 
          ]
        }
      },
    }
  },
  components: {
    quillEditor
  },
  computed: {
    document: {
      get() {
        return this.$store.getters.document; 
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      }
    },
  },
  methods:{
    ...mapActions(['updateQuote', 'updateInvoice', 'updateCredit']),
    saveAction() {
      if (this.document.nature == 0) {
        this.updateQuote({quote: this.document, updateState: false})
      } else if (this.document.nature == 1 || this.document.nature == 2 || this.document.nature == 3 || this.document.nature == 4) {
        this.updateInvoice({invoice: this.document, updateState: false})
      } else if (this.document.nature == 5 || this.document.nature == 6) {
        this.updateCredit({credit: this.document, updateState: false})
      }
    },
  },
}
</script>