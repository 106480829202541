import Vue from 'vue'
import store from '@/store'
import { ENature } from "@/types/api-orisis/enums/enums"
import { mapGetters, mapActions } from "vuex"

export default {
    name: 'IFrame',
    props: {
        documentType: {
            type: String,
            default: null,
            required: false,
          },
    },
    data() {
        return {
            iApp: null,
        }
    },
    computed: {
      ...mapGetters([
        "document",
        "documentPDF",
        "institutionSelected", 
        "workspaceSelected"
      ])
    },
    render(h) {
        return h('iframe', {
            on: {
                load: this.renderChildren
            }
        })
    },
    methods: {
        ...mapActions([
            "generateDocumentPDF",
        ]),
        async renderChildren() {
            const children = this.$slots.default
            const body = this.$el.contentDocument.body
            this.$el.contentDocument.head.innerHTML = '<meta charset="utf-8"><link href="https://v2.orisis.fr/css/pdf-generator/bootstrap-grid.css" rel="stylesheet"><link href="https://v2.orisis.fr/css/pdf-generator/main.css" rel="stylesheet"><link href="https://v2.orisis.fr/css/pdf-generator/print.css" rel="stylesheet" media="print">'

            const el = document.createElement('div') // we will mount or nested app to this element
            body.appendChild(el)

            const iApp = new Vue({
                name: 'iApp',
                store,
                data() {
                    return {
                        children: Object.freeze(children)
                    }
                },
                render(h) {
                    return h('div', this.children)
                }
            })

            iApp.$mount(el)

            let htmlPdf = encodeURI(body.innerHTML.replaceAll('<!---->', ''))
            let env=`${process.env.VUE_APP_ENV}`
            if(this.document.status < 2 || this.document.status == 8 || this.documentType == "detailedWithoutPrices"){
                let path = this.workspaceSelected.uniqueIdentifier+"/"+this.institutionSelected.uniqueIdentifier+"/Vente/Temp/"+this.document.uniqueIdentifier+".pdf"
                var data = JSON.stringify({
                    "env": env,
                    "path": path,
                    "html": htmlPdf
                });
                await this.generateDocumentPDF(data)
            }else{
                let path = this.workspaceSelected.uniqueIdentifier+"/"+this.institutionSelected.uniqueIdentifier+"/Vente/"+this.natureTranslate(this.document.nature)+"/"+this.document.documentReference+".pdf"
                var data = JSON.stringify({
                    "env": env,
                    "path": path,
                    "html": htmlPdf
                });
                await this.generateDocumentPDF(data)
            }
        },
        natureTranslate(natureId) {
            if (natureId == undefined) {
                return 'Autres'
            }else if (natureId >= 5 && natureId <= 6) {
                return 'Avoirs'
            }else if (natureId >= 1 && natureId <= 4) {
                return 'Factures'
            }else{
                return ENature[natureId];
            }
        },
    }
}