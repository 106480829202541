<template lang="pug">
    .row 
      div.col-md-6.mt-2
        PaymentConditions(:disabled="disabled")
      div.col-md-5.offset-md-1.mt-2
        AddGlobalDiscount(v-if="(document.nature == 0 || document.nature == 1 || (document.nature == 6 && !document.invoiceId)) && !disabled")
        TotalDocument(:disabled="disabled" @updateTvaDetails='updateTvaDetails' :canEditDiscount='true' ref="TotalDocument")
        TotalTva.mt-2

</template>
<script>
import PaymentConditions from "@/components/invoice/builder/PaymentConditions";
import TotalDocument from "@/components/invoice/builder/TotalDocument";
import TotalTva from "@/components/invoice/builder/TotalTva";
import AddGlobalDiscount from "@/components/invoice/builder/AddGlobalDiscount";
import { mapGetters } from "vuex";

export default {
  props: {
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      tvaDetails: [],
    };
  },
  computed: {
    ...mapGetters(["document"]),
  },
  methods: {
    updateTvaDetails(val) {
      this.tvaDetails = val;
    },
  },
  components: {
    PaymentConditions,
    TotalDocument,
    TotalTva,
    AddGlobalDiscount,
  },
};
</script>
