<template lang="pug">
tr
    td(v-for="(key, i) in Object.keys(invoiceHeader)" :key="i" v-if="invoiceHeader[key].display" :class="invoiceHeader[key].display ? invoiceHeader[key].class : ''" :style="'background:'+document.institutionColor+';color:'+document.institutionSecondaryColor+';'") 
        .header {{invoiceHeader[key].text}}    
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    documentType: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["document"]),
    invoiceHeader: {
      get() {
        switch (this.documentType) {
          case "detailedWithoutPrices":
            return {
              ...this.$store.getters.getInvoiceHeader,
              disbursedNetHt: {
                ...this.$store.getters.getInvoiceHeader.disbursedNetHt,
                display: false,
              },
              discount: {
                ...this.$store.getters.getInvoiceHeader.discount,
                display: false,
              },
              marginCoefficient: {
                ...this.$store.getters.getInvoiceHeader.marginCoefficient,
                display: false,
              },
              marginRate: {
                ...this.$store.getters.getInvoiceHeader.marginRate,
                display: false,
              },
              previousSituationProgress: {
                ...this.$store.getters.getInvoiceHeader
                  .previousSituationProgress,
                display: false,
              },
              previousSituationProgressQuantity: {
                ...this.$store.getters.getInvoiceHeader
                  .previousSituationProgressQuantity,
                display: false,
              },
              referencielTvaId: {
                ...this.$store.getters.getInvoiceHeader.referencielTvaId,
                display: false,
              },
              situationProgress: {
                ...this.$store.getters.getInvoiceHeader.situationProgress,
                display: false,
              },
              situationProgressQuantity: {
                ...this.$store.getters.getInvoiceHeader
                  .situationProgressQuantity,
                display: false,
              },
              subtotal: {
                ...this.$store.getters.getInvoiceHeader.subtotal,
                display: false,
              },
              total: {
                ...this.$store.getters.getInvoiceHeader.total,
                display: false,
              },
              unitPriceHt: {
                ...this.$store.getters.getInvoiceHeader.unitPriceHt,
                display: false,
              },
            };
            break;

          default:
            return this.$store.getters.getInvoiceHeader;
            break;
        }
      },
      set(val) {
        this.$store.commit("SET_INVOICE_HEADER", val);
      },
    },
  },
};
</script>
<!-- <style scoped>
#invoiceBuilder #builderPreview .document-table .document-head .product-line {
  padding: 0.2rem 2.5rem 0.2rem 1rem;
  font-size: 14px !important;
  /* background: #38a1f3; */
  color: #fff;
  display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    
}
</style> -->
