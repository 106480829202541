<template lang="pug">
  div(v-if="!preview || (preview && deduction && deduction.reviews && deduction.reviews.length > 0)")
    div.mb-1(v-if="deduction && deduction.reviews && deduction.reviews.length > 0 && isDisplayReview")
      div.d-flex.align-items-center.w-100.mt-1(v-for="(item, index) in deduction.reviews" :key="index") 
        span(@mouseover="showTools = true" @mouseleave="showTools = false") {{ item.label }} de {{ formatPercent(item.percent) }} soit {{ formatCurrency(item.number) }} HT (TVA {{ referencielTvaTranslate(item.referencielTvaId) }})
            feather-icon.feather-icon-review.cursor-pointer.ml-1.feather(v-if="!preview && showTools && !disabled" icon="Edit3Icon" size="18"  @click="showUpdateReview(index)" v-b-tooltip.hover.top="'Modifier'") 
            feather-icon.feather.cursor-pointer(v-if="!preview && showTools && !disabled" icon="XIcon" size="18"  @click="deleteReview(index, item)" v-b-tooltip.hover.top="'Retirer'")
    div.mb-1.mt-1(v-if='editReview && !preview')
        .d-flex.align-items-center(style='margin-bottom:5px;')
            input.input-punctual-deduction.form-control.form-control-sm.mr-1(v-model='label' name='review_label' :disabled="isReview")
            div(style='min-width: fit-content;') de
            input.input-number-review.form-control.form-control-sm.ml-1.mr-1(@blur="changeValueByPercent" v-model='percent' name='review_percent' type="number")
            div(style='min-width: fit-content;') % soit
            input.input-number-review.form-control.form-control-sm.ml-1.mr-1(@blur="changePercentByValue" v-model='number' name='review_number' type="number")
            div(style='min-width: fit-content;') € HT 
        .d-flex.align-items-center
            div.mr-1(style='min-width: fit-content;') TVA
            ejs-dropdownlist(v-model="referencielTvaId" :fields="localFields" :dataSource='referencielTVAsListAvailable')
            span(:class="includePrestation ? 'text-primary' : 'text-grey'" title="Inclure dans le total de la préstation").material-icons-outlined
                | add_home
            ejs-switch(v-model="includePrestation" :checked="includePrestation")
            b-button.form-control-sm.p-0.ml-1(style="padding:0px 5px !important" variant="primary" type="small" @click="pushReview") 
                feather-icon(icon='SaveIcon' size="20")
            b-button.form-control-sm.p-0.ml-1(style="padding:0px 5px !important" variant="outline-primary" @click="toggleReview(false)") 
                feather-icon(icon='XIcon' size="20")
        vs-divider.mt-1

</template>
<script>
import { BButton } from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import {
  formatCurrency,
  formatPercent,
} from "@/types/api-orisis/library/FormatOperations.ts";
import { referencielTvaTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { SwitchComponent } from "@syncfusion/ej2-vue-buttons";

export default {
  data() {
    return {
      showTools: false,
      isDisplayReview: true,
      number: 0,
      percent: 0,
      label: this.isReview ? "Révision de prix" : null,
      includePrestation: this.isReview ? false : true,
      referencielTvaId: null,
      isUpdateStatus: false,
      index: null,
      localFields: { text: "label", value: "id" },
    };
  },
  props: {
    preview: {
      default: false,
    },
    editReview: {
      type: Boolean,
      default: false,
    },
    disabled: {
      default: false,
    },
    isReview: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BButton,
    "ejs-switch": SwitchComponent,
  },
  computed: {
    ...mapGetters([
      "document",
      "getDocumentDetails",
      "isLoadingReferencielTVAsList",
      "institutionSettingsActive",
    ]),
    deduction: {
      get() {
        return this.$store.getters["deduction"];
      },
      set(value) {
        return this.$store.commit("SET_DEDUCTION", value);
      },
    },
    referencielTVAsListAvailable() {
      const referentielTvaIdSet = new Set();
      const referentielTvaAvailable = [];

      for (const detail of this.getDocumentDetails) {
        const referentielTvaId = detail.referencielTvaId;

        if (!referentielTvaIdSet.has(referentielTvaId)) {
          const matchingOption =
            this.institutionSettingsActive.referencielTvas.find(
              (option) => option.referencielTva.id === referentielTvaId
            ).referencielTva;
          if (matchingOption) {
            referentielTvaAvailable.push(matchingOption);
            referentielTvaIdSet.add(referentielTvaId);
          }
        }
      }
      return referentielTvaAvailable;
    },
  },
  watch: {
    editReview() {
      if (this.editReview == true && this.isUpdateStatus == false) {
        this.referencielTvaId = null;
        this.number = 0;
        this.percent = 0;
        this.label = this.isReview ? "Révision de prix" : null;
        this.includePrestation = this.isReview ? false : true;
      }
    },
  },
  methods: {
    ...mapActions(["createDeduction", "updateDeduction"]),
    formatCurrency,
    formatPercent,
    referencielTvaTranslate,
    changePercentByValue(res) {
      this.percent =
        Math.round((this.number / this.document.totalBaseNetHt) * 100 * 100) /
        100;
    },
    changeValueByPercent(res) {
      if (res.target.value > 100) {
        this.percent = 100;
        this.number =
          Math.round(
            (this.percent / 100) * this.document.totalBaseNetHt * 100
          ) / 100;
      } else if (res.target.value < -100) {
        this.percent = -100;
        this.number =
          Math.round(
            (this.percent / 100) * this.document.totalBaseNetHt * 100
          ) / 100;
      } else {
        this.number =
          Math.round(
            (this.percent / 100) * this.document.totalBaseNetHt * 100
          ) / 100;
      }
    },
    pushReview() {
      if (this.number && this.referencielTvaId) {
        if (this.isUpdateStatus == true) {
          this.deduction.reviews[this.index].number = this.number;
          this.deduction.reviews[this.index].referencielTvaId =
            this.referencielTvaId;
          this.deduction.reviews[this.index].percent = this.percent;
          this.deduction.reviews[this.index].label = this.label;
          this.deduction.reviews[this.index].includePrestation =
            this.includePrestation;
          this.isUpdateStatus = false;
        } else {
          this.deduction.reviews.push({
            number: this.number,
            referencielTvaId: this.referencielTvaId,
            percent: this.percent,
            label: this.label,
            includePrestation: this.includePrestation,
          });
        }
        this.isDisplayReview = true;
        if (this.deduction.id == 0) {
          this.createDeduction({ deduction: this.deduction });
        } else {
          this.updateDeduction({ deduction: this.deduction });
        }
        this.toggleReview(false);
      }
    },
    toggleReview(bool = false) {
      this.referencielTvaId = null;
      this.number = 0;
      this.percent = 0;
      this.label = this.isReview ? "Révision de prix" : null;
      this.includePrestation = this.isReview ? false : true;
      this.isDisplayReview = true;
      this.isUpdateStatus = bool;
      this.$emit("toggleReview", bool);
    },
    showUpdateReview(index) {
      this.isUpdateStatus = true;
      this.index = index;
      this.isDisplayReview = false;
      this.number = this.deduction.reviews[index].number;
      this.percent = this.deduction.reviews[index].percent;
      this.referencielTvaId = this.deduction.reviews[index].referencielTvaId;
      this.label = this.deduction.reviews[index].label;
      this.includePrestation = this.deduction.reviews[index].includePrestation;
      this.$emit("toggleReview", true);
    },
    deleteReview(index, item) {
      this.deduction.reviews.splice(index, 1);
      this.updateDeduction({ deduction: this.deduction });
    },
  },
};
</script>

<style scoped>
.input-number-review {
  border: solid 1px #ccc !important;
  background-color: white !important;
  border-radius: 3px;
  padding: 8px;
  width: 65px;
  height: 30px;
  border-radius: 0.357rem;
}

.dropdown-list-review {
  border: solid 1px #ccc !important;
  background-color: white !important;
  border-radius: 3px;
  padding: 8px;
  width: 180px;
  height: 30px;
  border-radius: 0.357rem;
}
.feather-icon-review {
  margin-right: 5px;
}

.datepicker-review {
  height: 30px !important;
  width: 200px;
}
</style>
