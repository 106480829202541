<template lang="pug">
.document-line(ref="documentDetails" v-show="isShowLine" @mouseover="edit = true, mouseOn = true" @mouseleave="edit=false, mouseOn=false")
    input(class="w-100" :ref="'fakeInputDocumentDetail'+index" style="width: 0px !important; position: absolute; display:block")
    .product-line.builder(:class="[line.isOption ? 'isOption' : '', (line.type==14 || line.type==15 || line.type==16 || line.type==17 || line.type==18) ? 'isWorkElement' : '', isWorkLineWithElement ? 'isWorkLineWithElement' : '', line.type == 2 ? 'align-items-center' : '']") 
      span.feather-drag-document-detail.handle.cursor-move.material-icons(:class="disabled ? 'invisible' : ''") drag_indicator
      div.type-line.no-printting(v-if="!documentOptions.hideIndexColumn") {{documentDetailTypeTranslate(line.type)}} 
      DocumentDetailCommonLine.m-0(:disabled="disabled" @emitEditFocus="changeEditFocus" v-for="(key, i) in Object.keys(invoiceHeader)" :key="i"  :dataKey="key" v-if="invoiceHeader[key].display && (line.type == 4 || line.type == 5 || line.type == 6 || line.type == 7 || line.type == 8 || line.type == 9 || line.type==14 ||line.type==15 ||line.type==16 ||line.type==17 ||line.type==18)" :class="invoiceHeader[key].class" :style="invoiceHeader[key].display ? invoiceHeader[key].style : ''" :line="line" :invoiceHeader="invoiceHeader" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index") 
      DocumentDetailSectionLine.m-0(:disabled="disabled" @emitEditFocus="changeEditFocus" v-for="(key, i) in columnTitle" :key="i"  v-if="line.type == 0 || line.type == 11" :line="line" :class="invoiceHeader[key].class" :style="invoiceHeader[key].style" :invoiceHeader="invoiceHeader" :dataKey="key" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      DocumentDetailSubTotalLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['index', 'description', 'subtotal']" :key="i" :indexArray="index-1" v-if="line.type == 13" :line="line" :class="invoiceHeader[key].class" :style="invoiceHeader[key].style" :invoiceHeader="invoiceHeader" :dataKey="key" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      DocumentDetailTextLine.m-0(:disabled="disabled" @emitEditFocus="changeEditFocus" v-for="(key, i) in columnText" :key="i"  :dataKey="key" v-if="line.type == 1" :class="invoiceHeader[key].class" :style="invoiceHeader[key].style" :line="line" :invoiceHeader="invoiceHeader" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      DocumentDetailBreakPageLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 2"  :line="line" :invoiceHeader="invoiceHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus" :index="index")
      DocumentDetailBreakLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 12"  :line="line" :invoiceHeader="invoiceHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus" :index="index")
      DocumentDetailPriceLine.m-0(:disabled="disabled" @emitEditFocus="changeEditFocus" v-for="(key, i) in ['index', 'description', 'referencielTvaId', 'total']" :key="i"  :dataKey="key" v-if="invoiceHeader[key].display && (line.type == 3)" :class="invoiceHeader[key].class" :style="invoiceHeader[key].display ? invoiceHeader[key].style : ''" :line="line" :invoiceHeader="invoiceHeader" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      vs-dropdown.more-actions(v-if="!disabled" vs-trigger-click :disabled="disabledOptions")
        span.handle.cursor-pointer(class='material-icons') expand_more
        vs-dropdown-menu
          vs-dropdown-group(v-if="line.type == 6" vs-collapse='' vs-label='Ajouter un composant' vs-icon='add')
            vs-dropdown-item(@click="newLine({type: 14, unit: 1, parentId:line.id, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type: 17, unit: 2, parentId:line.id, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type: 15, unit: 1, parentId:line.id, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Sous-traitance
            vs-dropdown-item(@click="newLine({type: 16, unit: 1, parentId:line.id, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type: 18, unit: 1, parentId:line.id, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Autre
          vs-divider.p-0.m-0(v-if="line.type == 6")
          vs-dropdown-item(v-if="line.type == 13" @click="toggleOptionLine(index)")  {{line.isOption ? "Sous-total" : "Sous-total des options"}}
          vs-dropdown-item(:disabled="line.isDisabled" v-else-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18" @click="toggleOptionLine(index)")  {{line.isOption ? "Ligne non optionnelle" : "Ligne optionnelle"}}
          vs-divider.p-0.m-0
          vs-dropdown-group(v-if='line.type==14 || line.type==15 || line.type==16 || line.type==17 || line.type==18' vs-collapse='' vs-label='Insérer au dessus' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:14, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:17, unit : 2, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:15, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Sous-traitance
            vs-dropdown-item(@click="newLine({type:16, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:18, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Autre
          vs-dropdown-group(v-else vs-collapse='' vs-label='Insérer au dessus' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:4, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:5, unit : 2, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:6, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Ouvrage
            vs-dropdown-item(@click="newLine({type:7, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Sous-traitance
            vs-dropdown-item(@click="newLine({type:8, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:9, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Autre
            vs-dropdown-item(@click="newLine({type:1, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Texte
            vs-dropdown-item(@click="newLine({type:2, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Saut de page
            vs-dropdown-item(@click="newLine({type:12, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Saut de ligne
          vs-dropdown-group(v-if='line.type==14 || line.type==15 || line.type==16 || line.type==17 || line.type==18' vs-collapse='' vs-label='Insérer en dessous' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:14, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:17, unit : 2, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:15, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Sous-traitance
            vs-dropdown-item(@click="newLine({type:16, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:18, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Autre
          vs-dropdown-group(v-else vs-collapse='' vs-label='Insérer en dessous' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:4, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:5, unit : 2, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:6, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Ouvrage
            vs-dropdown-item(@click="newLine({type:7, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Sous-traitance
            vs-dropdown-item(@click="newLine({type:8, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:9, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Autre
            vs-dropdown-item(@click="newLine({type:1, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Texte
            vs-dropdown-item(@click="newLine({type:2, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Saut de page
            vs-dropdown-item(@click="newLine({type:12, unit : 1, parentId:line.parentId, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})" v-if="line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18")  Saut de ligne
          vs-divider.p-0.m-0(v-if='line.productId == 0 || line.productId == null')
          vs-dropdown-item(v-if='line.productId == 0 || line.productId == null' @click="registerProduct({ line: line })")  Enregistrer dans le catalogue
          vs-divider.p-0.m-0(v-if='line.productId')
          vs-dropdown-item(v-if='line.productId' @click="reformProduct({ line: line})")  Mettre à jour le produit dans le catalogue
          vs-divider.p-0.m-0
          vs-dropdown-group(v-if="line.type !== 6" vs-collapse='' vs-label='Dupliquer' vs-icon='add')
            vs-dropdown-item(@click="duplicateLine({ payload: {line: line, index: index, isCredit: document.nature == 5 || document.nature == 6 ? true : false}, position: 'before' })")
              | Au dessus
            vs-dropdown-item(@click="duplicateLine({ payload: {line: line, index: index, isCredit: document.nature == 5 || document.nature == 6 ? true : false}, position: 'after' })")
              | En dessous
            vs-dropdown-item(@click="duplicateLine({ payload: {line: line, index: index, isCredit: document.nature == 5 || document.nature == 6 ? true : false}, position: 'beginning' })")
              | Au début
            vs-dropdown-item(@click="duplicateLine({ payload: {line: line, index: index, isCredit: document.nature == 5 || document.nature == 6 ? true : false}, position: 'end' })")
              | À la fin 
          vs-dropdown-group(v-if="line.type == 6" vs-collapse='' vs-label='Dupliquer' vs-icon='add')
            vs-dropdown-item(@click="duplicateWorkLineLocal(line, document.nature == 5 || document.nature == 6 ? true : false, index, 'before')")
              | Au dessus
            vs-dropdown-item(@click="duplicateWorkLineLocal(line, document.nature == 5 || document.nature == 6 ? true : false, index, 'after')")
              | En dessous
            vs-dropdown-item(@click="duplicateWorkLineLocal(line, document.nature == 5 || document.nature == 6 ? true : false, index, 'beginning')")
              | Au début
            vs-dropdown-item(@click="duplicateWorkLineLocal(line, document.nature == 5 || document.nature == 6 ? true : false, index, 'end')")
              | À la fin 
          vs-dropdown-item.text-danger(:disabled="line.isDisabled" @click="deleteLine(line)")
            | Supprimer
</template>

<script>
import DocumentDetailBreakLine from "@/components/invoice/builder/table/lines/DocumentDetailBreakLine.vue";
import DocumentDetailBreakPageLine from "@/components/invoice/builder/table/lines/DocumentDetailBreakPageLine.vue";
import DocumentDetailCommonLine from "@/components/invoice/builder/table/lines/DocumentDetailCommonLine.vue";
import DocumentDetailPriceLine from "@/components/invoice/builder/table/lines/DocumentDetailPriceLine.vue";
import DocumentDetailSectionLine from "@/components/invoice/builder/table/lines/DocumentDetailSectionLine.vue";
import DocumentDetailSubTotalLine from "@/components/invoice/builder/table/lines/DocumentDetailSubTotalLine.vue";
import DocumentDetailTextLine from "@/components/invoice/builder/table/lines/DocumentDetailTextLine.vue";
import { product } from "@/store/modules/invoices/catalog";
import { applyDeduction } from "@/types/api-orisis/library/DeductionOperations.ts";
import {
  calculateSubTotalLine,
  calculateSubTotalLineBySection,
} from "@/types/api-orisis/library/DocumentOperations";
import { documentDetailTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      edit: false,
      mouseOn: false,
      editFocus: false,
    };
  },
  computed: {
    ...mapGetters(["institutionSettingsActive", "disabledOptions"]),
    isShowLine() {
      if (
        this.line.parentId !== null &&
        this.parendIdHide == this.line.parentId
      ) {
        return false;
      } else {
        return true;
      }
    },
    invoiceHeader: {
      get() {
        return this.$store.getters.getInvoiceHeader;
      },
      set(val) {
        this.$store.commit("SET_INVOICE_HEADER", { ...invoiceHeader, val });
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
    columnText() {
      if (this.document.showReferenceColumn) {
        return ["index", "reference", "editor"];
      } else {
        return ["index", "editor"];
      }
    },
    columnTitle() {
      return ["index", "titre"];
    },
    isWorkLineWithElement() {
      if (this.line.type == 6) {
        let workLineElements = this.documentDetails.filter(
          (el) => el.parentId == this.line.id
        );
        if (workLineElements && workLineElements.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parendIdHide: {
      type: Number,
    },
    indexToFocus: {
      type: Number,
    },
    disabled: {
      default: false,
    },
  },
  watch: {
    indexToFocus(val) {
      if (val == this.index) {
        this.$nextTick(() => {
          this.$refs["fakeInputDocumentDetail" + this.index]?.focus({
            preventScroll: true,
          });
        });
      }
    },
  },
  methods: {
    calculateSubTotalLine,
    calculateSubTotalLineBySection,
    documentDetailTypeTranslate,
    applyDeduction,
    ...mapActions([
      "addLine",
      "getProductById",
      "createProduct",
      "updateProduct",
      "deleteDocumentDetails",
      "duplicateLine",
      "duplicateWorkLine",
      "updateDocumentDetail",
      "updateCreditDetail",
      "deleteCreditDetails",
    ]),
    changeEditFocus(val) {
      this.editFocus = val;
    },
    duplicateWorkLineLocal(line, isCredit, index, position) {
      let workLine = line;
      let workLineElements = this.documentDetails.filter(
        (el) => el.parentId == line.id
      );
      this.duplicateWorkLine({
        documentDetailId: line.id,
        workLineElements: workLineElements,
        isCredit: isCredit,
        index,
        position,
      });
    },
    newLine(line) {
      if (
        (line.type == 4 || line.type == 14) &&
        this.institutionSettingsActive.supplyMarginDefault &&
        this.institutionSettingsActive.supplyMarginDefault > 0
      ) {
        line.marginCoefficient =
          this.institutionSettingsActive.supplyMarginDefault;
      } else if (
        (line.type == 5 || line.type == 17) &&
        this.institutionSettingsActive.manPowerMarginDefault &&
        this.institutionSettingsActive.manPowerMarginDefault > 0
      ) {
        line.marginCoefficient =
          this.institutionSettingsActive.manPowerMarginDefault;
      } else if (
        line.type == 6 &&
        this.institutionSettingsActive.laborMarginDefault &&
        this.institutionSettingsActive.laborMarginDefault > 0
      ) {
        line.marginCoefficient = Number(
          this.institutionSettingsActive.laborMarginDefault
        );
      } else if (
        (line.type == 7 || line.type == 15) &&
        this.institutionSettingsActive.subContractingMarginDefault &&
        this.institutionSettingsActive.subContractingMarginDefault > 0
      ) {
        line.marginCoefficient =
          this.institutionSettingsActive.subContractingMarginDefault;
      } else if (
        (line.type == 8 || line.type == 16) &&
        this.institutionSettingsActive.equipementMarginDefault &&
        this.institutionSettingsActive.equipementMarginDefault > 0
      ) {
        line.marginCoefficient =
          this.institutionSettingsActive.equipementMarginDefault;
      } else if (
        (line.type == 9 || line.type == 18) &&
        this.institutionSettingsActive.variousMarginDefault &&
        this.institutionSettingsActive.variousMarginDefault > 0
      ) {
        line.marginCoefficient =
          this.institutionSettingsActive.variousMarginDefault;
      } else {
        line.marginCoefficient = 1;
      }

      let _this = this;
      if (this.document.nature == 0) {
        this.addLine({
          documentDetail: {
            ...line,
            quoteId: this.document.id,
            invoiceId: null,
            referencielTvaId:
              this.institutionSettingsActive.referencielTvas.find(
                (elem) => elem.isDefault == true
              ).referencielTva.id,
          },
          indexArray: line.indexArray,
          isCredit: this.document.nature == 6 ? true : false,
          review: line.review,
        });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.addLine({
          documentDetail: {
            ...line,
            invoiceId: this.document.id,
            quoteId: null,
            referencielTvaId:
              this.institutionSettingsActive.referencielTvas.find(
                (elem) => elem.isDefault == true
              ).referencielTva.id,
          },
          indexArray: line.indexArray,
          isCredit: this.document.nature == 6 ? true : false,
          review: line.review,
        });
      }
      this.$nextTick(() => {
        _this.$refs["fakeInputDocumentDetail" + this.index]?.focus({
          preventScroll: true,
        });
      });
    },

    async deleteLine(line) {
      this.$store.commit("SET_DISABLED_OPTIONS", true);
      let _this = this;
      let arrayIds = [];
      if (line.type == 6) {
        let workLineElements = this.documentDetails.filter(
          (el) => el.parentId == line.id
        );
        workLineElements.forEach((element) => {
          arrayIds.push(element.id);
        });
      }
      arrayIds.push(line.id);

      if (this.document.nature == 5 || this.document.nature == 6) {
        await this.deleteCreditDetails({
          creditDetailIds: arrayIds,
          reviewIndex: true,
          line: line,
        }).then(() => {
          this.$nextTick(() => {
            _this.$refs["fakeInputDocumentDetail" + this.index]?.focus({
              preventScroll: true,
            });
          });
        });
        this.applyDeduction();
      } else {
        await this.deleteDocumentDetails({
          documentDetailIds: arrayIds,
          reviewIndex: true,
          line: line,
        }).then(() => {
          this.$nextTick(() => {
            _this.$refs["fakeInputDocumentDetail" + this.index]?.focus({
              preventScroll: true,
            });
          });
        });
        this.applyDeduction();
      }
      this.$store.commit("SET_DISABLED_OPTIONS", false);
    },
    async toggleOptionLine(index) {
      this.$store.commit("SET_DISABLED_OPTIONS", true);
      if (this.line.type == 13) {
        this.calculateSubTotalLine(
          {
            ...this.line,
            indexArray: index,
            isOption: !this.line.isOption,
            description: "Sous-total des options",
          },
          !this.line.isOption,
          false
        );
        this.applyDeduction();
      } else {
        this.document.nature == 5 || this.document.nature == 6
          ? await this.updateCreditDetail({
              creditDetail: {
                ...this.line,
                indexArray: index,
                isOption: !this.line.isOption,
              },
            })
          : await this.updateDocumentDetail({
              documentDetail: {
                ...this.line,
                indexArray: index,
                isOption: !this.line.isOption,
              },
            });
        this.calculateSubTotalLineBySection(this.line);
        this.applyDeduction();
      }
      this.$store.commit("SET_DISABLED_OPTIONS", false);
    },
    convertion(line) {
      let productType;
      if (line.type >= 14 && line.type <= 18) {
        switch (line.type) {
          case 14:
            productType = 0;
            break;
          case 15:
            productType = 1;
            break;
          case 16:
            productType = 2;
            break;
          case 17:
            productType = 5;
            break;
          case 18:
            productType = 6;
            break;
          default:
            productType = 0;
            break;
        }
      } else {
        switch (line.type) {
          case 4:
            productType = 0;
            break;
          case 7:
            productType = 1;
            break;
          case 8:
            productType = 2;
            break;
          case 6:
            productType = 3;
            break;
          case 1:
            productType = 4;
            break;
          case 5:
            productType = 5;
            break;
          case 9:
            productType = 6;
            break;
          default:
            productType = 6;
            break;
        }
      }
      return productType;
    },

    async registerProduct({ line }) {
      const productType = this.convertion(line);

      if (!line.reference) {
        this.$bvToast.toast("Veuillez renseigner la référence du produit", {
          title: "Erreur",
          variant: "danger",
          solid: true,
        });
      } else {
        let productLocal = {
          id: 0,
          label: line.titre,
          reference: line.reference,
          productType: productType,
          description: line.description,
          quantity: line.quantity,
          packagingQuantity: 0,
          priceHT: line.unitPriceHt,
          dryDisbursed: line.dryDisbursement,
          grossMarginHT: line.marginCoefficient,
          marginRate: line.marginRate,
          brand: null,
          unitId: line.unitId,
          parentId: line.parentId,
          saleCategoryId: null,
          urlImage: null,
          alertQuantityMin: null,
          alertQuantityMax: null,
          destockingType: 0,
          restockingType: 0,
          ecoContribution: null,
          useDegressiveSaleRate: false,
          referencielTvaId: line.referencielTvaId,
          discount: null,
          unAvailableForSale: false,
          manageStock: true,
          currencyId: 1,
          description2: null,
          reference2: null,
          productCategoriesId: [],
          degressiveSalesRates: [],
          productProviders: [],
        };
        await this.createProduct({ product: productLocal }).then((res) => {
          line.productId = res.data.id;
          this.updateDocumentDetail({ documentDetail: line });

          this.$bvToast.toast(
            "Le produit a été enregistré dans le catalogue avec succès",
            {
              title: "Succès",
              variant: "success",
              solid: true,
            }
          );
        });
      }
    },

    async reformProduct({ line }) {
      const productType = this.convertion(line);
      let productData = await this.getProductById({
        productId: line.productId,
      });

      productData = {
        id: productData.id,
        label: line.titre ?? productData.label,
        reference: line.reference ?? productData.reference,
        productType: productType ?? productData.productType,
        description: line.description ?? productData.description,
        quantity: line.quantity ?? productData.quantity,
        packagingQuantity: productData.packagingQuantity,
        priceHT: line.unitPriceHt ?? productData.priceHT,
        dryDisbursed: line.dryDisbursement ?? productData.dryDisbursed,
        grossMarginHT: line.marginCoefficient ?? productData.grossMarginHT,
        marginRate: line.marginRate ?? productData.marginRate,
        brand: productData.brand,
        unitId: line.unitId ?? productData.unitId,
        parentId: line.parentId ?? productData.parentId,
        saleCategoryId: productData.saleCategoryId,
        urlImage: productData.urlImage,
        alertQuantityMin: productData.alertQuantityMin,
        alertQuantityMax: productData.alertQuantityMax,
        destockingType: productData.destockingType,
        restockingType: productData.restockingType,
        ecoContribution: productData.ecoContribution,
        useDegressiveSaleRate:
          line.useDegressiveSaleRate ?? productData.useDegressiveSaleRate,
        referencielTvaId: line.referencielTvaId ?? productData.referencielTvaId,
        discount: productData.discount,
        unAvailableForSale: productData.unAvailableForSale,
        manageStock: productData.manageStock,
        currencyId: productData.currencyId ?? 1,
        description2: productData.description2,
        reference2: productData.reference2,
        productCategoriesId: productData.productCategoriesId,
        degressiveSalesRates: productData.degressiveSalesRates,
        productProviders: productData.productProviders,
      };

      if (line.reference != "" && line.reference != null) {
        this.$bvModal
          .msgBoxConfirm(
            "Êtes vous sûr de vouloir modifier ce produit dans le catalogue ?",
            {
              title: "Attention",
              size: "sm",
              okVariant: "success",
              okTitle: "Modifier",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then(async (res) => {
            if (res) {
              await this.updateProduct({ product: productData });
              this.$bvToast.toast(
                "Le produit a été modifié dans le catalogue avec succès",
                {
                  title: "Succès",
                  variant: "success",
                  solid: true,
                }
              );
            }
          });
      } else {
        this.$bvToast.toast("Veuillez renseigner la référence du produit", {
          title: "Erreur",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  components: {
    DocumentDetailCommonLine,
    DocumentDetailSectionLine,
    DocumentDetailTextLine,
    DocumentDetailBreakPageLine,
    DocumentDetailBreakLine,
    DocumentDetailPriceLine,
    DocumentDetailSubTotalLine,
  },
};
</script>
