<template lang="pug">
  .d-flex.mb-2
    .w-100(v-if="!preview && !disabled")
      h4 Notes
      quill-editor.quill-editor-scroll(v-if="!preview" @blur="saveAction" class="editor editor-notes"  v-model="document.comments" :options="editorOption")
    .w-100.mt-2(v-else)
      h4(v-if="document.comments") Notes
      div(v-html="document.comments")
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { mapActions } from 'vuex'

export default {
  props: {
    preview: {
      default: false,
    },
    disabled: {
      default: false
    }
  },
  data() {
    return {
      edit: false,
      dataOrigine: null,
      editorOption: {
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            ['blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }],
            [{ 'align': [] }],
          ]
        }
      },
    }
  },
  components: {
    quillEditor
  },
  created() {
    this.dataOrigine = JSON.parse(JSON.stringify(this.document.comments));
  },
  computed: {
    document: {
      get() {
        return this.$store.getters.document; 
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      }
    },
  },
  methods:{
    ...mapActions(['updateQuote', 'updateInvoice']),
    saveAction() {
      if (this.document.comments != this.dataOrigine) {
        if (this.document.nature == 0) {
          this.updateQuote({quote: this.document, updateState: false})
        } else if (this.document.nature == 1 || this.document.nature == 2 || this.document.nature == 3 || this.document.nature == 4) {
          this.updateInvoice({invoice: this.document, updateState: false})
        }
      }
    },
  },
}
</script>