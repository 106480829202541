<template lang="pug">
div(style='display:contents')
  div(v-if="isLoadingDocument || isLoadingDocumentPDF || isValidatingDocument" class="h-100")
    .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner 
        .mt-5 
          br
          br
          br
          |  {{ isValidatingDocument ? "Validation du document en cours, veuillez patienter..." : "Génération du PDF..." }}
  div(style='display:contents' v-show="!isLoadingDocument && !isLoadingDocumentPDF && !isValidatingDocument")
    iframe.pdfViewver(:src="documentPDF" style="width: 100%;height: 100%; border: solid 2px #f1f1f1; padding:0px") 
    div(style="display:contents" :key="documentKey" v-if="statusCheck.documentEditable.includes(document.status) || !document.path || document.path=='' || document.path.includes('null.pdf') || documentKey > 0")
      documentPdfContent.pdfViewver(:documentType="documentType" style="width: 100%;height: 100%; border: none; padding: 0px; " v-if="showPreview")
        table.structure
          thead
            tr
              td
                .page-header-space
          tbody
            tr
              td
                div#invoiceContainer.pdfViewver
                  InvoicePreviewHeader()
                  InvoicePreviewTable(:documentType="documentType")
                  InvoicePreviewFooter(:documentType="documentType")
                  div.mt-2(v-if="documentOptions.additionalLegalNotices" v-html="documentOptions.additionalLegalNotices" style="margin-top: 2rem !important")
                  InvoicePreviewTermsAndConditions(v-if="documentOptions.showTermsOfSales")
                  .watermark(v-if="statusCheck.documentDraft.includes(document.status)")
                    .text PROVISOIRE
                  .watermark(v-else-if="document.nature !== 0 && document.status == 1")
                    .text A VALIDER
                  .watermark(v-else-if="document.status == 5")
                    .text ANNULÉ
                  .watermark(v-else-if="documentOptions.showWaterMark && document.status !== 1 && document.status !== 2 && document.status !== 5")
                    .text {{ statusToUpperCaseTranslate(document.status) }}
                  .background-documents(v-if="document.institutionId == 17")
          tfoot
            tr
              td
                .page-footer-space(:style="'height:'+pageFooterHeight()+'px !important'")
                  #pageFooter
</template>

<script>
import DocumentPdfContent from "@/components/invoice/DocumentPdfContent";
import InvoicePreviewHeader from "@/components/invoice/preview/InvoicePreviewHeader.vue";
import InvoicePreviewTable from "@/components/invoice/preview/InvoicePreviewTable.vue";
import InvoicePreviewFooter from "@/components/invoice/preview/InvoicePreviewFooter.vue";
import InvoicePreviewTermsAndConditions from "@/components/invoice/preview/InvoicePreviewTermsAndConditions.vue";
import { SwitchComponent } from "@syncfusion/ej2-vue-buttons";
import { mapGetters, mapActions } from "vuex";
import { statusToUpperCaseTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { statusCheck } from "@/types/api-orisis/enums/enums";

export default {
  props: {
    documentType: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      statusCheck,
      documentKey: 0,
      showPreview: true,
    };
  },
  watch: {
    documentType() {
      this.documentKey++;
    },
  },
  computed: {
    ...mapGetters([
      "document",
      "documentPDF",
      "isLoadingDocumentPDF",
      "isValidatingDocument",
      "institutionSettingsActive",
      "isLoadingDocument",
    ]),
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
    venteSettingBackgroundDocuments() {
      return this.moduleParametersList.find(
        (el) => el.key == "backgroundDocuments"
      )
        ? this.moduleParametersList.find(
            (el) => el.key == "backgroundDocuments"
          ).value
        : false;
    },
    heightFooter() {
      return "100";
    },
  },
  components: {
    DocumentPdfContent,
    InvoicePreviewHeader,
    InvoicePreviewTable,
    InvoicePreviewFooter,
    InvoicePreviewTermsAndConditions,
    "ejs-switch": SwitchComponent,
  },
  methods: {
    statusToUpperCaseTranslate,
    htmlEntities(str) {
      return String(str)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;");
    },
    pageFooterHeight() {
      var count = this.document.footer
        ? ((this.document.footer.match(/\<p/g) || []).length +
            (this.document.footer.match(/\<br/g) || []).length +
            (this.document.footer.match(/\<\/br/g) || []).length) *
          12
        : 0;
      if (count > 50) {
        return count;
      } else {
        return 50;
      }
    },
    decodeHtmlEntities(str) {
      var element = document.createElement("div");
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }
      return str;
    },
  },
};
</script>
