<template lang="pug">
  td
    div(v-if="invoiceHeader[dataKey].inputType == 1")
      span {{invoiceHeader[dataKey].prefix }} 
      span {{invoiceHeader[dataKey].numberToFixed ? formatNumber(line[dataKey]) : line[dataKey]}}
      span &nbsp;{{invoiceHeader[dataKey].unit}}
    div(v-else-if="invoiceHeader[dataKey].inputType == 2 && dataKey == 'discount' && line[dataKey] == 0")
      span {{dataKey == "discount" && line[dataKey] == 0 ? '' : formatNumber(line[dataKey])}}
    div(v-else-if="invoiceHeader[dataKey].inputType == 2")
      span {{formatNumberToString(line[dataKey], (dataKey == 'situationProgressQuantity' || dataKey == 'previousSituationProgressQuantity') ? 4 : 2)}}
      span &nbsp;{{invoiceHeader[dataKey].unit}}
    div(v-else-if="invoiceHeader[dataKey].inputType == 10") 
      SelectCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :invoiceHeader="invoiceHeader" :edit="edit" :editFocus="editFocus" :preview="true") 
    div(v-else-if="invoiceHeader[dataKey].inputType == 23") 
      MarginCol(@emitEditFocus="emitEditFocus" :line="line" :canEdit="true" :dataKey="dataKey" :invoiceHeader="invoiceHeader" :edit="edit" :editFocus="editFocus" :preview="true")
    div(v-else-if="invoiceHeader[dataKey].inputType == 24") 
      span(v-html="line[dataKey]")
</template>

<script>
import SelectCol from "@/components/invoice/builder/table/cols/SelectCol.vue";
import TextCol from "@/components/invoice/builder/table/cols/TextCol.vue";
import NumberCol from "@/components/invoice/builder/table/cols/NumberCol.vue";
import MarginCol from "@/components/invoice/builder/table/cols/MarginCol.vue";
import SelectCatalog from "@/components/invoice/builder/table/cols/SelectCatalog.vue";
import {
  formatNumber,
  formatNumberToString,
} from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  data() {
    return {};
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    invoiceHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    formatNumber,
    formatNumberToString,
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  components: {
    SelectCol,
    TextCol,
    NumberCol,
    MarginCol,
    SelectCatalog,
  },
};
</script>
