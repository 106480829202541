<template lang="pug">
tr(:class="[line.isOption ? 'isOption' : '', (line.type==14 || line.type==15 || line.type==16 || line.type==17 || line.type==18) ? 'isOption' : '', isWorkLineWithElement ? 'isWorkLineWithElement' : '']" v-if="(line.type !== 14 && line.type !== 15 && line.type !== 16 && line.type !== 17 && line.type !== 18) || documentOptions.showDetailWorkLine || documentType" :style="line.type == 2 ? 'page-break-after: always;' : ''")
  DocumentPreviewDetailCommonLine.m-0(v-for="(key, i) in Object.keys(invoiceHeader)" :key="i"  :dataKey="key" v-if="invoiceHeader[key].display && (line.type == 4 || line.type == 5 || line.type == 7 || line.type == 8 || line.type == 9 || line.type==14 ||line.type==15 ||line.type==16 ||line.type==17 ||line.type==18) && (line.type == 4 || line.type == 5 || line.type == 7 || line.type == 8 || line.type == 9 || line.type==14 ||line.type==15 ||line.type==16 ||line.type==17 ||line.type==18 ? !(line.quantity == 0 && documentOptions.hideLinesQuantityEmpty) : true)" :class="invoiceHeader[key].class" :line="line" :invoiceHeader="invoiceHeader" :editFocus="editFocus" :edit="edit")
  DocumentPreviewDetailSectionLine.m-0(v-for="(key, i) in columnTitle" :key="i"  v-if="line.type == 0 || line.type == 11" :line="line" :class="invoiceHeader[key].class" :colspan="setColspanSection(invoiceHeader[key])" :invoiceHeader="invoiceHeader" :dataKey="key" :editFocus="editFocus" :edit="edit")
  DocumentPreviewDetailSubTotalLine.m-0(v-for="(key, i) in ['index', 'description', 'subtotal']" :key="i" :indexArray="index-1" v-if="line.type == 13" :line="line" :class="invoiceHeader[key].class" :colspan="setColspanSubTotalLine(invoiceHeader[key])"  :invoiceHeader="invoiceHeader" :dataKey="key" :editFocus="editFocus" :edit="edit")
  DocumentPreviewDetailTextLine.m-0(v-for="(key, i) in columnText" :key="i"  :dataKey="key" v-if="line.type == 1" :class="invoiceHeader[key].class" :colspan="setColspanSection(invoiceHeader[key])" :line="line" :invoiceHeader="invoiceHeader" :editFocus="editFocus" :edit="edit")
  DocumentPreviewDetailBreakLine.m-0(v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 12"  :line="line" :invoiceHeader="invoiceHeader" :colspan="setColspanAll(invoiceHeader[key])" :edit="edit" :editFocus="editFocus")
  DocumentPreviewDetailWorkLine.m-0(v-for="(key, i) in Object.keys(invoiceHeader)" :key="i"  :dataKey="key" v-if="invoiceHeader[key].display && line.type == 6  && (line.type == 6 ? !(line.quantity == 0 && documentOptions.hideLinesQuantityEmpty) : true)" :class="invoiceHeader[key].class" :line="line" :invoiceHeader="invoiceHeader" :editFocus="editFocus" :edit="edit")
  DocumentPreviewDetailPriceLine.m-0(v-for="(key, i) in ['index', 'description', 'referencielTvaId', 'total']" :key="i"  :dataKey="key" v-if="invoiceHeader[key].display && (line.type == 3)" :class="invoiceHeader[key].class" :line="line" :invoiceHeader="invoiceHeader" :editFocus="editFocus" :edit="edit")
</template>

<script>
import DocumentPreviewDetailCommonLine from "@/components/invoice/preview/table/lines/DocumentPreviewDetailCommonLine.vue";
import DocumentPreviewDetailSectionLine from "@/components/invoice/preview/table/lines/DocumentPreviewDetailSectionLine.vue";
import DocumentPreviewDetailTextLine from "@/components/invoice/preview/table/lines/DocumentPreviewDetailTextLine.vue";
import DocumentPreviewDetailBreakPageLine from "@/components/invoice/preview/table/lines/DocumentPreviewDetailBreakPageLine.vue";
import DocumentPreviewDetailBreakLine from "@/components/invoice/preview/table/lines/DocumentPreviewDetailBreakLine.vue";
import DocumentPreviewDetailWorkLine from "@/components/invoice/preview/table/lines/DocumentPreviewDetailWorkLine.vue";
import DocumentPreviewDetailPriceLine from "@/components/invoice/preview/table/lines/DocumentPreviewDetailPriceLine.vue";
import DocumentPreviewDetailSubTotalLine from "@/components/invoice/preview/table/lines/DocumentPreviewDetailSubTotalLine.vue";
export default {
  data() {
    return {
      edit: false,
      editFocus: false,
    };
  },
  computed: {
    invoiceHeader: {
      get() {
        switch (this.documentType) {
          case "detailedWithoutPrices":
            return {
              ...this.$store.getters.getInvoiceHeader,
              disbursedNetHt: {
                ...this.$store.getters.getInvoiceHeader.disbursedNetHt,
                display: false,
              },
              discount: {
                ...this.$store.getters.getInvoiceHeader.discount,
                display: false,
              },
              marginCoefficient: {
                ...this.$store.getters.getInvoiceHeader.marginCoefficient,
                display: false,
              },
              marginRate: {
                ...this.$store.getters.getInvoiceHeader.marginRate,
                display: false,
              },
              previousSituationProgress: {
                ...this.$store.getters.getInvoiceHeader
                  .previousSituationProgress,
                display: false,
              },
              previousSituationProgressQuantity: {
                ...this.$store.getters.getInvoiceHeader
                  .previousSituationProgressQuantity,
                display: false,
              },
              referencielTvaId: {
                ...this.$store.getters.getInvoiceHeader.referencielTvaId,
                display: false,
              },
              situationProgress: {
                ...this.$store.getters.getInvoiceHeader.situationProgress,
                display: false,
              },
              situationProgressQuantity: {
                ...this.$store.getters.getInvoiceHeader
                  .situationProgressQuantity,
                display: false,
              },
              subtotal: {
                ...this.$store.getters.getInvoiceHeader.subtotal,
                display: false,
              },
              total: {
                ...this.$store.getters.getInvoiceHeader.total,
                display: false,
              },
              unitPriceHt: {
                ...this.$store.getters.getInvoiceHeader.unitPriceHt,
                display: false,
              },
            };
            break;

          default:
            return this.$store.getters.getInvoiceHeader;
            break;
        }
      },
      set(val) {
        this.$store.commit("SET_INVOICE_HEADER", { ...invoiceHeader, val });
      },
    },
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
    columnText() {
      if (this.documentOptions.showReferenceColumn) {
        return ["index", "reference", "editor"];
      } else {
        return ["index", "editor"];
      }
    },
    columnTitle() {
      return ["index", "titre"];
    },
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
    isWorkLineWithElement() {
      if (this.line.type == 6) {
        let workLineElements = this.documentDetails.filter(
          (el) => el.parentId == this.line.id
        );
        if (workLineElements && workLineElements.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    documentType: {
      type: String,
      default: null,
      required: false,
    },
  },

  watch: {
    editFocus(val) {
      if (val == false) {
        this.edit = false;
      }
    },
  },
  methods: {
    setColspanAll(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return Object.entries(this.invoiceHeader).filter(
          (el) => el[1].display == true
        ).length;
      } else {
        return 1;
      }
    },
    setColspanSection(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return (
          Object.entries(this.invoiceHeader).filter(
            (el) => el[1].display == true
          ).length - 1
        );
      } else {
        return 1;
      }
    },
    setColspanSubTotalLine(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return (
          Object.entries(this.invoiceHeader).filter(
            (el) => el[1].display == true
          ).length - 2
        );
      } else {
        return 1;
      }
    },
  },
  components: {
    DocumentPreviewDetailCommonLine,
    DocumentPreviewDetailSectionLine,
    DocumentPreviewDetailTextLine,
    DocumentPreviewDetailBreakPageLine,
    DocumentPreviewDetailBreakLine,
    DocumentPreviewDetailWorkLine,
    DocumentPreviewDetailPriceLine,
    DocumentPreviewDetailSubTotalLine,
  },
};
</script>
