<template lang="pug">
    div.d-flex.justify-content-end.no-printting
      span.cursor-pointer.text-primary(v-if="!documentOptions.showDiscount" @click='activeDiscountGlobal') + Ajouter une remise
      span.cursor-pointer.text-primary(v-else @click='desactiveDiscountGlobal') - Supprimer la remise
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { applyDeduction } from "@/types/api-orisis/library/DeductionOperations.ts";

export default {
  props: {
    preview: {
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["document"]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "updateQuote",
      "updateInvoice",
      "updateQuoteOption",
      "updateInvoiceOption",
    ]),
    applyDeduction,
    activeDiscountGlobal() {
      this.documentOptions = {
        ...this.documentOptions,
        showDiscount: true,
      };
      this.document = {
        ...this.document,
        discountGlobalType: 0,
        discountGlobalNumber: 0,
      };
      this.saveAction();
    },
    desactiveDiscountGlobal() {
      this.documentOptions = {
        ...this.documentOptions,
        showDiscount: false,
      };
      this.document = {
        ...this.document,
        discountGlobalType: 0,
        discountGlobalNumber: 0,
      };
      this.saveAction();
    },
    saveAction() {
      this.applyDeduction();
      if (this.document.nature == 0) {
        this.updateQuoteOption({
          quoteOption: this.documentOptions,
          updateState: false,
        });
        this.updateQuote({ quote: this.document, updateState: false });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.updateInvoiceOption({
          invoiceOption: this.documentOptions,
          updateState: false,
        });
        this.updateInvoice({ invoice: this.document, updateState: false });
      }
    },
  },
};
</script>
