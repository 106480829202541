<template lang="pug">
.builder.pb-4
  //
    b-alert.mt-2(variant='primary' show  style='margin-top:0px; margin-bottom:10px; box-shadow: none !important;')
      p.alert-heading(style="font-size: 12px; font-weight: 500; line-height: 14px; text-align: center;    box-shadow: none !important;")
          | De nouvelles fonctionnalités arrivent aujourd'hui. Afin de vous en faire bénéficiez ORISIS devra être interrompu ce jour de 11h30 à 12h30. Merci de votre compréhension.
  
  b-alert.mt-2(v-if="mandatoryInformationMissingNumber" variant='warning' show='')
    p.alert-heading
      | Certaines mentions légales sont manquantes. <a  class="cursor-pointer" style="font-weight: bold" @click="redirectToInstitution">Cliquez ici pour les ajouter</a>
  b-alert.mt-2(v-if="institutionInformationsNotUpToDate && statusCheck.documentEditable.includes(document.status)" variant='warning' show='')
    p.alert-heading
      | Les informations de votre document ne sont pas à jour. <a class="cursor-pointer" style="font-weight: bold"  @click="updateDocumentInstitutionInformations">Cliquez ici pour les mettres à jour</a>
  .builder-area
    InvoiceHeader(:disabled="disabled")
    InvoiceTable(:disabled="disabled")
    InvoiceBTools(:disabled="disabled" v-if="document.nature == 0 || document.nature == 1 || document.nature == 4 || (document.nature == 6 && !document.invoiceId)" v-show="!disabled")
    InvoiceFooter(:disabled="disabled")
    //- pre {{documentDetails}}
    //- pre {{document}}
    //- pre {{documentDownPaymentsRequest}}
    //- pre {{deduction}}
    //- pre {{documentOptions}}
    //- pre {{invoiceHeader}}
</template>

<script>
import InvoiceHeader from "@/components/invoice/builder/InvoiceHeader.vue";
import InvoiceTable from "@/components/invoice/builder/InvoiceTable.vue";
import InvoiceBTools from "@/components/invoice/builder/InvoiceBTools.vue";
import InvoiceFooter from "@/components/invoice/builder/InvoiceFooter.vue";
import { mapGetters, mapActions } from "vuex";
import { statusCheck } from "@/types/api-orisis/enums/enums";

export default {
  data() {
    return {
      statusCheck,
    };
  },
  components: {
    InvoiceHeader,
    InvoiceTable,
    InvoiceBTools,
    InvoiceFooter,
  },
  props: {
    mode: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["institutionSelected", "institutionSettingsActive"]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    // documentDetails: {
    //   get() {
    //     return this.$store.getters["getDocumentDetails"];
    //   },
    //   set(value) {
    //     return this.$store.commit("SET_DOCUMENT_DETAILS", value);
    //   },
    // },
    // documentDownPaymentsRequest: {
    //   get() {
    //     return this.$store.getters["quoteDownPaymentsRequestsList"];
    //   },
    //   set(value) {
    //     return this.$store.commit(
    //       "SET_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST",
    //       value
    //     );
    //   },
    // },
    // documentOptions: {
    //   get() {
    //     if (this.document.nature == 0) {
    //       return this.$store.getters.quoteOption;
    //     } else if (
    //       this.document.nature == 1 ||
    //       this.document.nature == 2 ||
    //       this.document.nature == 3 ||
    //       this.document.nature == 4
    //     ) {
    //       return this.$store.getters.invoiceOption;
    //     } else if (this.document.nature == 5 || this.document.nature == 6) {
    //       return this.$store.getters.creditOption;
    //     }
    //   },
    //   set(value) {
    //     if (this.document.nature == 0) {
    //       return this.$store.commit("SET_QUOTE_OPTION", value);
    //     } else if (
    //       this.document.nature == 1 ||
    //       this.document.nature == 2 ||
    //       this.document.nature == 3 ||
    //       this.document.nature == 4
    //     ) {
    //       return this.$store.commit("SET_INVOICE_OPTION", value);
    //     } else if (this.document.nature == 5 || this.document.nature == 6) {
    //       return this.$store.commit("SET_CREDIT_OPTION", value);
    //     }
    //   },
    // },
    // deduction: {
    //   get() {
    //     return this.$store.getters["deduction"];
    //   },
    //   set(value) {
    //     return this.$store.commit("SET_DEDUCTION", value);
    //   },
    // invoiceHeader: {
    //   get() {
    //     return this.$store.getters.getInvoiceHeader;
    //   },
    //   set(val) {
    //     this.$store.commit("SET_INVOICE_HEADER", val);
    //   },
    // },
    // },
    disabled() {
      return !this.statusCheck.documentEditable.includes(this.document.status);
    },
    mandatoryInformationMissingNumber() {
      if (
        this.document.institutionLegalForm == 0 ||
        this.document.institutionLegalForm == 1
      ) {
        var mandatoryInformation = [
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      } else if (
        this.document.institutionLegalForm == 2 ||
        this.document.institutionLegalForm == 3
      ) {
        var mandatoryInformation = [
          "institutionSiret",
          "institutionApeCode",
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      } else {
        var mandatoryInformation = [
          "institutionSiret",
          "institutionRcsCity",
          "institutionCapital",
          "institutionApeCode",
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      }
      let missing = 0;
      for (let i = 0; i < mandatoryInformation.length; i++) {
        const mandatory = mandatoryInformation[i];
        if (
          this.document[mandatory] === null ||
          this.document[mandatory] === ""
        ) {
          missing++;
        }
      }
      return missing;
    },
    institutionInformationsNotUpToDate() {
      return (
        this.institutionSelected.name != this.document.institutionName ||
        this.institutionSelected.legalForm !=
          this.document.institutionLegalForm ||
        this.institutionSelected.address != this.document.institutionAddress ||
        this.institutionSelected.addressComplement !=
          this.document.institutionAddressComplement ||
        this.institutionSelected.zipCode != this.document.institutionZipCode ||
        this.institutionSelected.city != this.document.institutionCity ||
        this.institutionSelected.country != this.document.institutionCountry ||
        this.institutionSelected.phoneNumber !=
          this.document.institutionPhoneNumber ||
        this.institutionSelected.secondaryPhoneNumber !=
          this.document.institutionSecondaryPhoneNumber ||
        this.institutionSelected.email != this.document.institutionEmail ||
        this.institutionSelected.webSite != this.document.institutionWebSite ||
        this.institutionSelected.registrationNumber !=
          this.document.institutionRegistrationNumber ||
        this.institutionSelected.isSubjectTVA !=
          this.document.institutionIsSubjectTVA ||
        this.institutionSelected.tvaNumber !=
          this.document.institutionTvaNumber ||
        this.institutionSelected.siret != this.document.institutionSiret ||
        this.institutionSelected.rcsCity != this.document.institutionRcsCity ||
        this.institutionSelected.capital != this.document.institutionCapital ||
        this.institutionSelected.apeCode != this.document.institutionApeCode ||
        this.institutionSelected.guaranteeType !=
          this.document.institutionGuaranteeType ||
        this.institutionSelected.insuranceName !=
          this.document.institutionInsuranceName ||
        this.institutionSelected.insuranceCoverage !=
          this.document.institutionInsuranceCoverage ||
        this.institutionSelected.insuranceAddress !=
          this.document.institutionInsuranceAddress ||
        this.institutionSelected.insuranceAddressComplement !=
          this.document.institutionInsuranceAddressComplement ||
        this.institutionSelected.insuranceCity !=
          this.document.institutionInsuranceCity ||
        this.institutionSelected.insuranceZipCode !=
          this.document.institutionInsuranceZipCode ||
        this.institutionSelected.insuranceCountry !=
          this.document.institutionInsuranceCountry ||
        this.institutionSelected.color != this.document.institutionColor ||
        this.institutionSelected.secondaryColor !=
          this.document.institutionSecondaryColor ||
        this.institutionSettingsActive.footerType != this.document.footerType ||
        this.institutionSettingsActive.footer != this.document.footer
      );
    },
  },
  methods: {
    ...mapActions(["updateQuote", "updateInvoice", "updateCredit"]),
    redirectToInstitution() {
      this.$router.push({
        name: "edit-institution",
        params: {
          id: this.document.institutionId,
          title: "Edit : " + this.document.institutionName,
          tips: "Editer la société : " + this.document.institutionName,
          routeOrigine: "institutions",
        },
      });
    },
    saveAction(bool) {
      if (this.document.nature == 0) {
        this.updateQuote({ quote: this.document, updateState: bool });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.updateInvoice({ invoice: this.document, updateState: bool });
      } else {
        this.updateCredit({ credit: this.document, updateState: bool });
      }
    },
    updateDocumentInstitutionInformations() {
      this.document.institutionName = this.institutionSelected.name;
      this.document.institutionLegalForm = this.institutionSelected.legalForm;
      this.document.institutionAddress = this.institutionSelected.address;
      this.document.institutionAddressComplement =
        this.institutionSelected.addressComplement;
      this.document.institutionZipCode = this.institutionSelected.zipCode;
      this.document.institutionCity = this.institutionSelected.city;
      this.document.institutionCountry = this.institutionSelected.country;
      this.document.institutionPhoneNumber =
        this.institutionSelected.phoneNumber;
      this.document.institutionSecondaryPhoneNumber =
        this.institutionSelected.secondaryPhoneNumber;
      this.document.institutionEmail = this.institutionSelected.email;
      this.document.institutionWebSite = this.institutionSelected.webSite;
      this.document.institutionRegistrationNumber =
        this.institutionSelected.registrationNumber;
      this.document.institutionIsSubjectTVA =
        this.institutionSelected.isSubjectTVA;
      this.document.institutionTvaNumber = this.institutionSelected.tvaNumber;
      this.document.institutionSiret = this.institutionSelected.siret;
      this.document.institutionRcsCity = this.institutionSelected.rcsCity;
      this.document.institutionCapital = this.institutionSelected.capital;
      this.document.institutionApeCode = this.institutionSelected.apeCode;
      this.document.institutionGuaranteeType =
        this.institutionSelected.guaranteeType;
      this.document.institutionInsuranceName =
        this.institutionSelected.insuranceName;
      this.document.institutionInsuranceCoverage =
        this.institutionSelected.insuranceCoverage;
      this.document.institutionInsuranceAddress =
        this.institutionSelected.insuranceAddress;
      this.document.institutionInsuranceAddressComplement =
        this.institutionSelected.insuranceAddressComplement;
      this.document.institutionInsuranceCity =
        this.institutionSelected.insuranceCity;
      this.document.institutionInsuranceZipCode =
        this.institutionSelected.insuranceZipCode;
      this.document.institutionInsuranceCountry =
        this.institutionSelected.insuranceCountry;
      this.document.institutionColor = this.institutionSelected.color;
      this.document.institutionSecondaryColor =
        this.institutionSelected.secondaryColor;
      this.document.footerType = this.institutionSettingsActive.footerType;
      this.document.footer = this.institutionSettingsActive.footer;
      this.saveAction(false);
    },
  },
};
</script>

<style scoped>
#invoiceContainer {
  height: calc(100% - 23px) !important;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.builder-area {
  /* margin-top: 20px; */
  padding: 20px;
  background-color: white !important;
  border: solid 2px #f1f1f1;
}
</style>
