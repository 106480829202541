import { QuoteBTPRequestDto, QuoteCEERequestDto } from "Api";
export function QuoteDefaultProperties(user, institution) {
  return {
    id: undefined,
    affairId: null,
    affairName: null,
    affairAddress: null,
    affairAddressComplement: null,
    affairCity: null,
    affairZipCode: null,
    affairCountry: null,

    type: null,
    nature: 0,

    /** @format double */
    discountGlobalNumber: 0.0,
    discountGlobalType: 0,
    showDiscountColumn: false,

    /** @format date-time */
    documentDate: new Date().toISOString(),

    /** @format date-time */
    limitDate: undefined,
    comments: null,
    description: null,

    /** @format int32 */
    userId: user.id,
    userLastName: user.lastName,
    userFirstName: user.firstName,
    userPhoneNumber: user.phoneNumber,
    userSecondaryPhoneNumber: null,
    userMail: user.email,

    calculateMargin: false,
    calculateUnitPriceHtByUnitPurchasePriceHt: false,
    isReverseCharge: false,
    hideWasteCollection: false,
    hideUnitColumn: false,
    hideQuantityColumn: false,
    showReferenceColumn: false,
    hideReferencielTvaColumn: false,
    showCompanyPhoneNumber: true,
    showCompanyMail: true,
    isShowTvaCompany: false,
    showCompanySiret: false,
    status: 0,

    /** @format int32 */
    companyId: null,
    companyName: null,
    companyAddress: null,
    companyAddressComplement: null,
    companyZipCode: null,
    companyCity: null,
    companyCountry: null,
    companyPhoneNumber: null,
    companyMail: null,

    /** @format double */
    totalHt: 0.0,

    /** @format double */
    totalTtc: 0.0,
    quoteBTP: QuoteBTP(),
    quoteCEE: QuoteCEE(),

    /** @format int32 */
    institutionId: undefined,
    institutionName: null,
    institutionLegalForm: undefined,
    institutionAddress: null,
    institutionAddressComplement: null,
    institutionZipCode: null,
    institutionCity: null,
    institutionCountry: null,
    institutionPhoneNumber: null,
    institutionSecondaryPhoneNumber: null,
    institutionEmail: null,
    institutionWebSite: null,
    institutionRegistrationNumber: null,
    institutionIsSubjectTVA: undefined,
    institutionTvaNumber: null,
    institutionSiret: null,
    institutionRcsCity: null,

    /** @format double */
    institutionCapital: null,
    institutionApeCode: institution.apeCode,
    institutionGuaranteeType: institution.guaranteeType,
    institutionInsuranceName: institution.insuranceName,
    institutionInsuranceCoverage: institution.insuranceCoverage,
    institutionInsuranceAddress: institution.insuranceAddress,
    institutionInsuranceAddressComplement:
      institution.insuranceAddressComplement,
    institutionInsuranceZipCode: institution.insuranceZipCode,
    institutionInsuranceCity: institution.insuranceCity,
    institutionInsuranceCountry: institution.insuranceCountry,
    institutionLogo: institution.logo,
    institutionColor: institution.color,

    documentReference: null,
    internalNote: null,
  };
}

export function QuoteBTP(): QuoteBTPRequestDto {
  return {
    id: -1,

    /** @format date-time */
    workStartDate: null,

    estimatedDurationNumber: null,
    estimatedDurationType: null,

    /** @format double */
    wasteVolumeFrom: 0.0,

    /** @format double */
    wasteVolumeTo: 0.0,
    wasteVolumeUnity: 0,

    /** @format double */
    wastePriceTtc: 0.0,
    quoteBTPWasteCollectionPointIds: [],
    quoteBTPWasteTypeIds: [],
  };
}
export function QuoteCEE(): QuoteCEERequestDto {
  return {
    /** @format int32 */
    id: -1,
    precarityType: null,
    parceNumber: null,
  };
}
