<template lang="pug"> 
  div(v-if="!preview" style='max-width:400px')
    div.d-flex(:class="edit ? 'justify-content-end' : ''")
      span(v-if="!edit") {{ text }}
        span(v-if="document[keyValue] && !edit" @mouseover="showTools = true" @mouseleave="showTools = false") {{ begginingWorkDate }} 
          span.cursor-pointer.bold(v-if="showTools && canChangeDate && !disabled") 
            feather-icon(icon="Edit3Icon" size="18"  @click="edit=true" v-b-tooltip.hover.top="'Modifier la date'") 
            a(v-if="document[keyValue] == null" @click="edit = !edit") définir
      span.cursor-pointer.text-primary(v-if="edit" @click="edit = false") Fermer
    div.mb-1.d-flex(v-if="edit")
      div.w-100
        date-range-picker(style="width:200px" :showDropdowns='true' :maxDate="today" ref="picker" id="dates" :show-week-numbers="true" :timePicker="false" :single-date-picker="true" :opens="'right'" :append-to-body="true" :locale-data="config" :ranges="defaultRange" v-model="datePicker" :auto-apply="true")
  div(v-else)
    div.d-flex
      span {{ text }} {{ begginingWorkDate }} 
</template>
<script>
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { statusCheck } from "@/types/api-orisis/enums/enums";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);
const config = {
  direction: "ltr",
  format: "dd/mm/yyyy",
  separator: " - ",
  applyLabel: "Appliquer",
  cancelLabel: "Annuler",
  weekLabel: "S",
  customRangeLabel: "Période personnalisée",
  daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  firstDay: 1,
};
var defaultRange = {
  "Aujourd'hui": [new Date(dayjs()), new Date(dayjs())],
  Demain: [new Date(dayjs().add(1, "day")), new Date(dayjs().add(1, "day"))],
  Hier: [
    new Date(dayjs().subtract(1, "day")),
    new Date(dayjs().subtract(1, "day")),
  ],
  "Dans 1 mois": [
    new Date(dayjs().add(1, "month")),
    new Date(dayjs().add(1, "month")),
  ],
};

export default {
  components: {
    DateRangePicker,
  },
  props: {
    preview: {
      default: false,
    },
    documentDetailKey: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: "Date :",
    },
    keyValue: {
      type: String,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      config: config,
      defaultRange: defaultRange,
      edit: false,
      date: null,
      showTools: false,
      today: new Date(),
      statusCheck,
    };
  },
  computed: {
    ...mapGetters(["quotesList", "invoicesList", "creditsList"]),
    begginingWorkDate() {
      return dayjs(this.document[this.keyValue]).format("DD/MM/YYYY");
    },
    datePicker: {
      get() {
        return {
          startDate: dayjs(this.document[this.keyValue]),
          endDate: dayjs(this.document[this.keyValue]),
        };
      },
      set(value) {
        this.$emit("setValue", new Date(value.startDate));
        this.edit = false;
      },
    },
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    // lastDocumentDate() {
    //   if (this.document.nature == 0) {
    //     let quotesListFilter = this.quotesList.filter(
    //       (el) => el.status == 1 || el.status == 2 || el.status == 3
    //     );
    //     return quotesListFilter.length > 0
    //       ? quotesListFilter.reduce(
    //           (max, obj) => (obj.documentDate > max ? obj.documentDate : max),
    //           quotesListFilter[0].documentDate
    //         )
    //       : null;
    //   } else if (
    //     this.document.nature == 1 ||
    //     this.document.nature == 2 ||
    //     this.document.nature == 3 ||
    //     this.document.nature == 4
    //   ) {
    //     let invoicesListFilter = this.invoicesList.filter(
    //       (el) =>
    //         el.status == 1 || el.status == 2 || el.status == 6 || el.status == 7
    //     );
    //     return invoicesListFilter.length > 0
    //       ? invoicesListFilter.reduce(
    //           (max, obj) => (obj.documentDate > max ? obj.documentDate : max),
    //           invoicesListFilter[0].documentDate
    //         )
    //       : null;
    //   } else if (this.document.nature == 5 || this.document.nature == 6) {
    //     let creditsListFilter = this.creditsList.filter(
    //       (el) =>
    //         el.status == 1 || el.status == 2 || el.status == 6 || el.status == 7
    //     );
    //     return creditsListFilter.length > 0
    //       ? creditsListFilter.reduce(
    //           (max, obj) => (obj.documentDate > max ? obj.documentDate : max),
    //           creditsListFilter[0].documentDate
    //         )
    //       : null;
    //   }
    // },
    canChangeDate() {
      return this.statusCheck.documentEditable.includes(this.document.status);
    },
  },
};
</script>
