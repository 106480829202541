<template lang="pug">
    div.mb-2(v-if="!preview || useForm")
        .d-flex.text-primary(v-if="!disabled")
            h4 Gestion des déchets
            div.ml-1.cursor-pointer(v-if="!useForm && !preview" @click='toggleWasteManagement')
                span.text-primary
                | {{ edit == true ? '- Masquer' : '+ Définir' }}
            div.ml-1(v-else-if="useForm && !edit && !preview && !disabled")
                feather-icon.feather(icon="Edit3Icon", size="18", @click="edit = true", v-b-tooltip.hover.top="'Modifier'")
                feather-icon.feather(icon="XIcon", size="18", @click="toggleWasteManagement", v-b-tooltip.hover.top="'Supprimer'")
        div.mt-1(v-if='edit && !preview && !disabled')
            div.mb-1.d-flex.align-items-center
                | Type de d&eacute;chets collect&eacute;s
                v-select.select-waste-types.ml-1.w-100(placeholder='Sélectionner les types de déchets' v-model="document.quoteBTP.quoteBTPWasteTypeIds" multiple :closeOnSelect="false" :loading="isLoadingWasteTypesList" :options="wasteTypesList" :reduce="(el) => el.id")
                    template(#option='{ label, description }')
                        div
                            span(style='font-size:16px;white-space:normal') {{ label }}
                            br
                            div(style='white-space:normal')
                                div.w-100(style='color:#a2a3a8;font-size:13px') {{ description }}
                    template(v-slot:no-options)
                        template Aucun résultat trouvé
            div.mb-1
                | Point de collecte :
            div
                .d-flex(v-if="document.quoteBTP.quoteBTPWasteCollectionPointIds.length > 0")
                    div
                        p(v-for='(item, index) in document.quoteBTP.quoteBTPWasteCollectionPointIds') {{ quoteBTPWasteCollectionPointIdTranslate(item) }}
                v-select.mb-1#quoteBTPWasteCollectionPointIds(@input="setWasteCollectionPoints" v-model="wasteCollecionPointSelected" :loading='isLoadingWasteCollectionPointsList' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='wasteCollectionPointsList' placeholder='Ajouter un point de collecte')
                    template(#option='{ id, label, wasteCollectionPointType, address, addressComplement, zipCode, city }')
                        div
                            span(style='font-size:16px;white-space:normal') {{ label }} ({{ wasteCollectionPointType.label }})
                            br
                            div(style='white-space:normal')
                                div.w-100(style='color:#a2a3a8;font-size:13px') {{ address }} {{ addressComplement }}, {{ zipCode }} {{ city }}
                    template(v-slot:no-options='')
                        template  Aucun r&eacute;sultat trouv&eacute; 
                    li.border-bottom.p-1.py-50(slot='list-header')
                        b-button.w-100.d-flex.justify-content-center.align-items-center(@click='newWasteCollectionPoint()' variant='primary' size='sm')
                            | Gérer les points de collecte
                            span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                                | open_in_new
            .d-flex.align-items-center.mb-1
                label.col-form-label Volume estim&eacute; entre
                div.content-input-waste-management
                    input.input-number-waste-management.not-autocomplete(v-model="document.quoteBTP.wasteVolumeFrom" type='number' autocomplete='nope' aria-autocomplete='nope')
                label.col-form-label et
                div.content-input-waste-management
                    input.input-number-waste-management.not-autocomplete(v-model="document.quoteBTP.wasteVolumeTo" type='number' autocomplete='nope' aria-autocomplete='nope')
                div
                    select.form-control.form-control-sm.ml-1(v-model="document.quoteBTP.wasteVolumeUnity" name='waste_volume_unity' style='width: 80px;')
                        option.discount-option-percentage(value='0' selected='selected') m³
                        option.discount-option-no-tax(value='1') Kg
                        option.discount-option-with-tax(value='2') T
            .d-flex.align-items-center
                label.col-form-label Co&ucirc;ts associ&eacute;s de
                div.content-input-waste-management
                    input.input-number-waste-management.not-autocomplete(v-model='document.quoteBTP.wastePriceTtc' type='number' autocomplete='nope' aria-autocomplete='nope')
                label.col-form-label
                    | &euro; TTC par {{ wasteVolumeUnityTranslate(document.quoteBTP.wasteVolumeUnity) }}
            .mt-1.text-right
                b-button.form-control-sm.p-0(style="padding:0px 5px !important" variant="primary" type="small" @click="saveWasteManagement") 
                    | Enregistrer
                b-button.form-control-sm.p-0.ml-1(style="padding:0px 5px !important" variant="outline-primary" @click="edit=!edit") 
                    feather-icon(icon='XIcon' size="20")
        div(v-else-if="!edit && useForm")
            div.mb-1(v-if="document.quoteBTP.quoteBTPWasteTypeIds.length > 0" style='flex-wrap: wrap;') 
                span(style="margin-right:5px") Type de déchets collectés :
                span(style="margin-right:5px" v-for="(item, index) in document.quoteBTP.quoteBTPWasteTypeIds" :key="index") {{quoteBTPWasteTypeIdTranslate(item)}}{{index != document.quoteBTP.quoteBTPWasteTypeIds.length - 1 ? ', ' : '.'}}
            div.mb-1
                span.w-100 Point de collecte : 
                div(v-if='document.quoteBTP.quoteBTPWasteCollectionPointIds.length > 0')
                    ul
                        li.ml-2(v-for='(item, index) in document.quoteBTP.quoteBTPWasteCollectionPointIds') {{ quoteBTPWasteCollectionPointIdTranslate(item) }}
            div
                span Volume estimé entre {{ document.quoteBTP.wasteVolumeFrom }} et {{ document.quoteBTP.wasteVolumeTo }} {{ wasteVolumeUnityTranslate(document.quoteBTP.wasteVolumeUnity) }}
            div
                span Coûts associés de {{ formatCurrency(document.quoteBTP.wastePriceTtc) }} TTC par {{ wasteVolumeUnityTranslate(document.quoteBTP.wasteVolumeUnity) }}
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import {
  wasteVolumeUnityTranslate,
  quoteBTPWasteCollectionPointIdTranslate,
  quoteBTPWasteTypeIdTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  directives: {
    Ripple,
  },
  props: {
    preview: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      wasteTypes: [],
      wasteVolumeFrom: 0,
      wasteVolumeTo: 0,
      wasteVolumeUnity: 0,
      wastePriceTtc: 0,
      wasteCollecionPointSelected: null,
    };
  },
  components: {
    "v-select": vSelect,
  },
  computed: {
    ...mapGetters([
      "wasteTypesList",
      "isLoadingWasteTypesList",
      "wasteCollectionPointsList",
      "isLoadingWasteCollectionPointsList",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    useForm() {
      return (
        this.document.quoteBTP &&
        this.document.quoteBTP.wasteVolumeFrom >= 0 &&
        this.document.quoteBTP.wasteVolumeTo > 0 &&
        this.document.quoteBTP.quoteBTPWasteCollectionPointIds &&
        this.document.quoteBTP.quoteBTPWasteCollectionPointIds.length > 0 &&
        this.document.quoteBTP.quoteBTPWasteTypeIds &&
        this.document.quoteBTP.quoteBTPWasteTypeIds.length > 0
      );
    },
  },
  created() {
    this.getWasteTypes({});
    this.getWasteCollectionPoints({});
  },
  methods: {
    ...mapActions(["getWasteTypes", "getWasteCollectionPoints", "updateQuote"]),
    wasteVolumeUnityTranslate,
    quoteBTPWasteCollectionPointIdTranslate,
    quoteBTPWasteTypeIdTranslate,
    formatCurrency,
    saveAction() {
      this.updateQuote({ quote: this.document, updateState: false });
    },
    toggleWasteManagement() {
      this.edit = !this.edit;
      this.document.quoteBTP = {
        ...this.document.quoteBTP,
        wasteVolumeFrom: 0,
        wasteVolumeTo: 0,
        wasteVolumeUnity: 0,
        wastePriceTtc: 0,
        quoteBTPWasteCollectionPointIds: [],
        quoteBTPWasteTypeIds: [],
      };
      this.saveAction();
    },
    saveWasteManagement() {
      if (
        this.document.quoteBTP &&
        this.document.quoteBTP.wasteVolumeFrom >= 0 &&
        this.document.quoteBTP.wasteVolumeTo > 0 &&
        this.document.quoteBTP.quoteBTPWasteCollectionPointIds &&
        this.document.quoteBTP.quoteBTPWasteCollectionPointIds.length > 0 &&
        this.document.quoteBTP.quoteBTPWasteTypeIds &&
        this.document.quoteBTP.quoteBTPWasteTypeIds.length > 0
      ) {
        this.edit = false;
        this.saveAction();
      } else {
        this.$bvToast.toast(
          "Veuillez compléter tous les champs correspondant à la gesiton des déchets.",
          {
            title: `Gestion des dechets`,
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    setWasteCollectionPoints(value) {
      this.document.quoteBTP.quoteBTPWasteCollectionPointIds.push(value.id);
      this.wasteCollecionPointSelected = null;
    },
    newWasteCollectionPoint() {
      this.$router.push({
        name: "new-waste-collection-point",
      });
    },
  },
};
</script>

<style>
.vs-dropdown--menu {
  margin-bottom: 0 !important;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.content-input-waste-management {
  margin: 0 1rem;
  width: 77px;
}

.select-waste-types {
  max-width: 473px;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style scoped>
.input-number-waste-management {
  border: solid 1px #ccc !important;
  background-color: white !important;
  padding: 8px;
  height: 30px;
  border-radius: 0.357rem;
  width: 77px;
}
</style>
