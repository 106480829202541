<template lang="pug">
    table.documentDetails   
      thead
        InvoicePreviewHeader(:documentType="documentType")
      tbody
        DocumentPreviewDetails(v-for="(line, index) in getDocumentDetails" :key="index" :line="line" :index="index" :documentType="documentType")
</template>
<script>
import InvoicePreviewHeader from "@/components/invoice/preview/table/InvoicePreviewHeader.vue";
import DocumentPreviewDetails from "@/components/invoice/preview/table/DocumentPreviewDetails.vue";
import { EDocumentTypeLine, EInputType } from "@/types/api-orisis/enums/enums";
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    documentType: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getDocumentDetails"]),
  },
  components: {
    InvoicePreviewHeader,
    DocumentPreviewDetails,
    draggable,
  },
};
</script>
