<template lang="pug">
  div 
    b-row
      b-col(cols="12").col-lg-6
        ul
          li 
            h3.text-primary {{ natureSimplifiedTranslate(document.nature) }} n° {{ document.documentReference ?  document.documentReference : 'en attente' }}
              span(style='font-weight:300')
          li(v-if="document.nature == 2 || document.nature == 3 || document.nature == 4" style="margin-bottom:5px")
            span(v-if="(document.nature==3 || document.nature==4)  && isLastSituationInvoice") Facture finale
            span(v-else) {{ natureTranslate(document.nature)}}{{ document.nature == 2 || document.nature == 3 || document.nature == 4  ? (document.documentStep ? ` #${document.documentStep}` : '') : '' }}
          li(v-if="document.quoteId && initialDocument && (document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4)")
            span Provenant du devis : {{ initialDocument.initialDocumentNature === 'quote' ? initialDocument.documentReference : initialDocument.quote.documentReference }}
          li(v-if="document.orderFormCustomerId && initialDocument && (document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4)")
            span Provenant de la commande : {{ initialDocument.initialDocumentNature === 'order-form-customer' ? initialDocument.documentReference : initialDocument.orderFormCustomer.documentReference }}
          li.d-flex.align-items-center 
            Status(:statusId="document.status" :nature="document.nature" :static="true")
            SelectDate(:disabled="disabled" keyValue="documentDate" @setValue="setDocumentDate" documentDetailKey="documentDate" text="En date du ")
          li(v-if="document.nature == 0" style="margin-bottom:5px")
            SelectCounterDate(:disabled="disabled" keyValue="limitDate" @setValue="saveAction" text="Valable jusqu'au ")
          li(v-if="document.type == 2 && document.nature == 0" style="margin-bottom:5px")
            SelectDateCEE(:disabled="disabled" keyValue="technicalVisitDate" @setValue="setDocumentTechnicalVisitDate" text="Visite technique le ")
          li(v-if="(document.type == 3 || document.type == 2) && (document.nature == 0 || document.nature == 1)" style="margin-bottom:5px")
            SelectDateWork(:disabled="disabled" keyValue="workStartDate" @setValue="setWorkStartDate" text="Début des travaux le ")
          li(v-if="(document.type == 3 || document.type == 2) && (document.nature == 0 || document.nature == 1)")
            SelectPeriod(:disabled="disabled" keyValue="workDateEnd" @setValue="setWorkEndDate" :text="document.nature == 0 ? 'Durée estimée à ' : 'Durée des travaux '")
          li(v-if="!documentOptions.hideUser").mt-2
            UserInfoDocument(:disabled="disabled")
          li(v-if="collaboratorInfo").mt-2
            div
              span.text-primary.font-weight-bold Dossier suivi par : 
            div
              span.font-weight-bold {{ collaboratorInfo.firstName }} {{ collaboratorInfo.lastName }}
            div
              span {{ collaboratorInfo.email ? "E-mail : " + collaboratorInfo.email : "" }}
            div
              span {{ collaboratorInfo.phoneNumber ? "Tél : " + collaboratorInfo.phoneNumber : "" }}
      b-col(cols="12").offset-lg-1.col-lg-5
        div
          SelectCompany(:disabled="disabled" @setValue="setSelectCompany" @setSelectCompanyAddress="setSelectCompanyAddress" :placeholder="document.type==4 ? 'Sélectionner un locataire' : 'Sélectionner un client'" :options='companyFilter')
        div
          SelectAffair(:disabled="disabled" :affairId='document.affairId' @saveAction="saveAction" @setValue="setSelectAffair" :placeholder="document.type==4 ? 'Sélectionner un bail' : 'Sélectionner une affaire'" :options='affairsList')
    b-row
      b-col.mb-0(cols="12" class="align-items-center")
        InvoiceDescription(:disabled="disabled" @setValue="setInvoiceDescription" :key="'InvoiceDescription_'+document.id")

            
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Status from "@/components/global/status/Status";
import SelectCompany from "@/components/invoice/builder/SelectCompany";
import SelectAffair from "@/components/invoice/builder/SelectAffair";
import SelectDate from "@/components/invoice/builder/SelectDate";
import SelectDateCEE from "@/components/invoice/builder/SelectDateCEE";
import SelectDateWork from "@/components/invoice/builder/SelectDateWork";
import SelectPeriod from "@/components/invoice/builder/SelectPeriod";
import InvoiceDescription from "@/components/invoice/builder/InvoiceDescription";
import SelectCounterDate from "@/components/invoice/builder/SelectCounterDate";
// import SelectValidityDate from '@/components/invoice/builder/SelectValidityDate'
import UserInfoDocument from "@/components/invoice/builder/UserInfoDocument";
import {
  natureTranslate,
  natureSimplifiedTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  props: {
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      collaboratorInfo: null,
    };
  },
  mounted() {
    this.setCollaboratorInfo();
  },
  methods: {
    ...mapActions([
      "updateQuote",
      "updateInvoice",
      "getCompanyById",
      "getCollaboratorById",
    ]),
    natureTranslate,
    natureSimplifiedTranslate,
    setDocumentDate(date) {
      this.document = {
        ...this.document,
        documentDate: date,
        startDate: date,
        endDate: date,
      };
      this.saveAction();
    },
    setDocumentTechnicalVisitDate(date) {
      this.document.quoteCEE = {
        ...this.document.quoteCEE,
        technicalVisitDate: date,
      };
      this.saveAction();
    },

    setWorkStartDate(date) {
      if (this.document.nature == 0) {
        this.document.quoteBTP = {
          ...this.document.quoteBTP,
          workStartDate: date,
        };
      } else {
        this.document.invoiceBTP = {
          ...this.document.invoiceBTP,
          workStartDate: date,
        };
      }
      this.saveAction();
    },
    setWorkEndDate(durationNumber, durationType) {
      if (this.document.nature == 0) {
        this.document.quoteBTP = {
          ...this.document.quoteBTP,
          estimatedDurationNumber: durationNumber,
          estimatedDurationType: durationType,
        };
      } else {
        this.document.invoiceBTP = {
          ...this.document.invoiceBTP,
          estimatedDurationNumber: durationNumber,
          estimatedDurationType: durationType,
        };
      }
      this.saveAction();
    },
    getFacturableAddressCompany(company) {
      return new Promise((resolve, reject) => {
        const facturableAddress = company.addresses.find(
          (address) => address.isFacturable
        );
        if (
          facturableAddress &&
          this.document.nature !== 0 &&
          this.document.nature !== 5 &&
          this.document.nature !== 6
        ) {
          const adressReturn = {
            ...facturableAddress,
            name: company.name,
            firstName: company.firstName,
            lastName: company.lastName,
          };
          resolve(adressReturn);
        } else {
          company.addresses.find((adress) => {
            if (adress.isDefault) {
              let adressReturn = {
                ...adress,
                name: company.name,
                firstName: company.firstName,
                lastName: company.lastName,
              };
              resolve(adressReturn);
            }
          });
        }
      });
    },
    async setCollaboratorInfo() {
      if (this.document.companyId) {
        await this.getCompanyById({ companyId: this.document.companyId }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
          }
        );
      } else {
        this.collaboratorInfo = null;
      }
    },
    setSelectCompanyAddress(company) {
      this.document = {
        ...this.document,
        companyId: company.id,
        companyCode: company.code,
        companyName: company.name,
        companyAddress: company.address,
        companyAddressId: company.addressId,
        companyAddressComplement: company.addressComplement,
        companyCity: company.city,
        companyZipCode: company.zipCode,
        companyCountry: company.country,
        companySiret: company.siret,
        companyTva: company.tvaNumber,
        companyMail: company.email,
        companyPhoneNumber: company.phoneNumber,
        companyAddressLabel: company.label,
      };
      this.saveAction();
    },
    async setSelectCompany(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.document = {
          ...this.document,
          companyId: company.id,
          companyCode: company.code,
          companyName: company.name,
          companyFirstName: company.firstName,
          companyLastName: company.lastName,
          companyAddress: company.address,
          companyAddressComplement: company.addressComplement,
          companyCity: company.city,
          companyZipCode: company.zipCode,
          companyCountry: company.country,
          companyMail: company.email,
          companyPhoneNumber: company.phoneNumber,
          companyTva: company.tvaNumber,
          companySiret: company.siret,
          companyAddressLabel: company.addressLabel,
        };
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getFacturableAddressCompany(company).then((res) => {
                this.document = {
                  ...this.document,
                  companyId: company.id,
                  companyCode: company.code,
                  companyName: company.name,
                  companyFirstName: company.firstName,
                  companyLastName: company.lastName,
                  companyAddress: res.address,
                  companyAddressComplement: res.addressComplement,
                  companyCity: res.city,
                  companyZipCode: res.zipCode,
                  companyCountry: res.country,
                  companyMail: company.email,
                  companyPhoneNumber: company.phoneNumber,
                  companyTva: company.tvaNumber,
                  companySiret: res.siret,
                  companyAddressLabel: res.label,
                  companyAddressId: res.id,
                };
              });
            } else {
              this.document = {
                ...this.document,
                companyId: company.id,
                companyCode: company.code,
                companyName: company.name,
                companyFirstName: company.firstName,
                companyLastName: company.lastName,
                companyAddress: null,
                companyAddressComplement: null,
                companyCity: null,
                companyZipCode: null,
                companyCountry: null,
                companyMail: company.email,
                companyPhoneNumber: company.phoneNumber,
                companyTva: company.tvaNumber,
                companySiret: company.siret,
                companyAddressLabel: null,
                companyAddressId: null,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    setSelectAffair(affair) {
      this.document = {
        ...this.document,
        affairId: affair.id,
        affairName: affair.name,
        affairAddress: affair.address,
        affairAddressComplement: affair.addressComplement,
        affairZipCode: affair.zipCode,
        affairCity: affair.city,
        affairCountry: affair.country,
        affairCode: affair.code,
      };
      this.saveAction();
    },
    setInvoiceDescription(description) {
      this.document = {
        ...this.document,
        description: description,
      };
      this.saveAction();
    },
    saveAction() {
      if (this.document.nature == 0) {
        this.updateQuote({ quote: this.document, updateState: false });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.updateInvoice({ invoice: this.document, updateState: false });
      }
    },
  },
  computed: {
    ...mapGetters([
      "companiesList",
      "affairsList",
      "natureList",
      "getDocumentDetails",
      "initialDocument",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
    isLastSituationInvoice() {
      return this.getDocumentDetails
        .filter(
          (el) =>
            el.type != 0 &&
            el.type != 1 &&
            el.type != 2 &&
            el.type != 11 &&
            el.type != 12 &&
            el.type != 13 &&
            el.type != 18 &&
            el.type != 17 &&
            el.type != 16 &&
            el.type != 15 &&
            el.type != 14 &&
            !el.isOption
        )
        .every(
          (el) =>
            el.situationProgress == 100 ||
            el.quantity == el.situationProgressQuantity
        );
    },
    companyFilter() {
      return this.document.type == 4
        ? this.companiesList.filter((company) => company.companyTypeId === 7)
        : this.companiesList.filter((company) => company.companyTypeId === 5);
    },
  },
  components: {
    "v-select": vSelect,
    Status,
    SelectCompany,
    SelectAffair,
    SelectDate,
    SelectPeriod,
    InvoiceDescription,
    SelectCounterDate,
    // SelectValidityDate,
    SelectDateWork,
    UserInfoDocument,
    SelectDateCEE,
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>
