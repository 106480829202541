<template lang="pug">
  div(v-if="!preview && !disabled")
    div.d-flex.mb-1(v-if="quoteDownPaymentsRequest.length > 0 && isDisplayQuoteDownPaymentRequest" :class="editQuoteDownPaymentRequest ? 'justify-content-between' : ''" style="margin-bottom:5px" v-for="(item, index) in quoteDownPaymentsRequest" :key="index")
      span(@mouseover="showTools = true" @mouseleave="showTools = false") Acompte de {{ fixedNumber(item.number) }} {{ downPaymentRequestTypeTranslate(item.downPaymentRequestType) }} {{ item.downPaymentRequestDue == 3 ? item.label : downPaymentRequestDueTranslate(item.downPaymentRequestDue) }} {{ item.downPaymentRequestType == 0 ? 'soit ' + priceDownPaymentRequestTtc(index) + '  € TTC' : '' }}
        feather-icon.feather-icon-quote-down-payments-request.ml-1.feather.cursor-pointer(v-if="showTools" icon="Edit3Icon" size="18"  @click="showUpdateQuoteDownPaymentRequest(index)" v-b-tooltip.hover.top="'Modifier'") 
        feather-icon.feather.cursor-pointer(v-if="showTools" icon="XIcon" size="18" @click="deleteQuoteDownPaymentsRequest({quoteDownPaymentsRequestIds: [item.id], route: 'quoteDownPaymentsRequests'})" v-b-tooltip.hover.top="'Retirer'") 
    div.mb-1.mt-1.d-flex(v-if="editQuoteDownPaymentRequest")
      div.w-100.d-flex.align-items-center
        div(style='min-width: fit-content;') Accompte de
        input.input-number-down-payments-request.form-control.form-control-sm.ml-1(v-model='number' type="number" min='0' @input='isNegative') 
        select.form-control.form-control-sm.ml-1(v-model="downPaymentRequestType" name='down_payment_request_type' style='width: 80px;')
          option.discount-option-percentage(value='0' selected='selected') %
          option.discount-option-no-tax(value='1') &euro; HT
          option.discount-option-with-tax(value='2') &euro; TTC
        select.form-control.form-control-sm.ml-1(v-model="downPaymentRequestDue" v-if="downPaymentRequestDue != 3" name='down_payment_request_due' style='width: 180px; min-width: 180px;')
          option.discount-option-percentage(value='0' selected='selected') à la signature
          option.discount-option-no-tax(value='1') au début des travaux
          option.discount-option-with-tax(value='2') à la moitié des travaux
          option.discount-option-with-tax(value='3') texte libre
        div.d-flex.align-items-center.ml-1(v-else style='width: 180px; min-width: 180px;')
          input.input-label-down-payments-request.form-control.form-control-sm.w-100( v-model='label' placeholder="Texte libre")
          feather-icon(icon="XIcon" size="18" style="margin-left:-25px" @click="downPaymentRequestDue = 0" v-b-tooltip.hover.top="'Changer'") 
        b-button.form-control-sm.p-0.ml-1(style="padding:0px 5px !important" variant="primary" type="small" @click="pushQuoteDownPaymentRequest") 
          feather-icon(icon='SaveIcon' size="20") 
        b-button.form-control-sm.p-0.ml-1(style="padding:0px 5px !important" variant="outline-primary" @click="toggleQuoteDownPaymentsRequest(false)") 
          feather-icon(icon='XIcon' size="20")
  div(v-else-if="quoteDownPaymentsRequest.length > 0")
    div.d-flex(:class="editQuoteDownPaymentRequest ? 'justify-content-between' : ''" style="margin-bottom:5px" v-for="(item, index) in quoteDownPaymentsRequest")
      span Acompte de {{ fixedNumber(item.number) }} {{ downPaymentRequestTypeTranslate(item.downPaymentRequestType) }} {{ item.downPaymentRequestDue == 3 ? item.label : downPaymentRequestDueTranslate(item.downPaymentRequestDue) }} {{ item.downPaymentRequestType == 0 ? 'soit ' + priceDownPaymentRequestTtc(index) + '  € TTC' : '' }}
</template>
<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import {
  downPaymentRequestTypeTranslate,
  downPaymentRequestDueTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {
      isDisplayQuoteDownPaymentRequest: true,
      id: 0,
      number: null,
      downPaymentRequestType: 0,
      downPaymentRequestDue: 0,
      label: null,
      index: null,
      isUpdateStatus: false,
      showTools: false,
    };
  },
  props: {
    preview: {
      default: false,
    },
    editQuoteDownPaymentRequest: {
      type: Boolean,
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  components: {
    "v-select": vSelect,
  },
  computed: {
    quoteDownPaymentsRequest: {
      get() {
        return this.$store.getters["quoteDownPaymentsRequestsList"];
      },
      set(value) {
        return this.$store.commit(
          "SET_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST",
          value
        );
      },
    },
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "createQuoteDownPaymentsRequest",
      "updateQuoteDownPaymentsRequest",
      "updatePaymentMethodQuote",
      "deleteQuoteDownPaymentsRequest",
    ]),
    downPaymentRequestTypeTranslate,
    downPaymentRequestDueTranslate,
    toggleQuoteDownPaymentsRequest(bool = false) {
      this.id = 0;
      this.number = null;
      this.downPaymentRequestType = 0;
      this.downPaymentRequestDue = 0;
      this.isDisplayQuoteDownPaymentRequest = !bool;
      this.$emit("toggleQuoteDownPaymentsRequest", bool);
    },
    pushQuoteDownPaymentRequest() {
      if (this.isUpdateStatus == true) {
        let quoteDownPaymentsRequest = {
          id: this.id,
          quoteId: this.document.id,
          number: this.number,
          downPaymentRequestType: this.downPaymentRequestType,
          downPaymentRequestDue: this.downPaymentRequestDue,
          label: this.label,
          institutionSettingsId: null,
        };
        this.updateQuoteDownPaymentsRequest({ quoteDownPaymentsRequest });
        this.isUpdateStatus = false;
      } else {
        let quoteDownPaymentsRequest = {
          id: 0,
          quoteId: this.document.id,
          number: this.number,
          label: this.label,
          downPaymentRequestType: this.downPaymentRequestType,
          downPaymentRequestDue: this.downPaymentRequestDue,
          institutionSettingsId: null,
        };
        this.createQuoteDownPaymentsRequest({
          quoteDownPaymentsRequest: quoteDownPaymentsRequest,
        });
      }
      this.isDisplayQuoteDownPaymentRequest = true;
      this.toggleQuoteDownPaymentsRequest(false);
    },
    showUpdateQuoteDownPaymentRequest(index) {
      this.isUpdateStatus = true;
      this.index = index;
      this.isDisplayQuoteDownPaymentRequest = false;
      this.id = this.quoteDownPaymentsRequest[index].id;
      this.number = this.quoteDownPaymentsRequest[index].number;
      this.downPaymentRequestType =
        this.quoteDownPaymentsRequest[index].downPaymentRequestType;
      this.downPaymentRequestDue =
        this.quoteDownPaymentsRequest[index].downPaymentRequestDue;
      this.label = this.quoteDownPaymentsRequest[index].label;
      this.$emit("toggleQuoteDownPaymentsRequest", true);
    },
    isNegative(e) {
      setTimeout(() => {
        this.number = Math.round(e.target.value * 100) / 100;
        if (this.downPaymentRequestType == 0) {
          if (e.target.value < 0) {
            this.number = 0;
          } else if (e.target.value > 100) {
            this.number = 100;
          }
        } else {
          if (e.target.value < 0) {
            this.number = 0;
          }
        }
      }, 1000);
    },
    fixedNumber(number) {
      return parseFloat(number).toFixed(2);
    },
    priceDownPaymentRequestTtc(index) {
      return (
        Math.round(
          this.document.totalTtc *
            (this.quoteDownPaymentsRequest[index].number / 100) *
            100
        ) / 100
      ).toFixed(2);
    },
  },
};
</script>

<style scoped>
.input-number-down-payments-request {
  width: 100px;
  border: solid 1px #ccc !important;
  background-color: white !important;
  border-radius: 3px;
  padding: 8px;
  width: 65px;
  height: 30px;
  border-radius: 0.357rem;
}

.input-label-down-payments-request {
  border: solid 1px #ccc !important;
  background-color: white !important;
  border-radius: 3px;
  padding: 8px;
  height: 30px;
  border-radius: 0.357rem;
}
</style>
