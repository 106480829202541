<template lang="pug">
div(class="invoiceHeader")
    b-row
      b-col.col-sm-6(class="invoiceHeaderLeft")
        img.logo.mb-2(v-if="document.institutionLogo && !documentOptions.hideInstitutionLogo" :src='document.institutionLogo' :style="'width:' + institutionSelected.logoWidth + 'px !important;'")
        ul
          //- li.mb-2(v-if='!documentOptions.hideInstitutionDetails')
          li.mb-2
            h3(:style="'color:'+document.institutionColor+' !important'" v-if="(!documentOptions.hideInstitutionName) || workspaceSelected.id == 14 ") {{document.institutionName}}
            div(v-if="!documentOptions.hideInstitutionAddress")
              div {{document.institutionAddress}}
              div(v-if="document.institutionAddressComplement") {{document.institutionAddressComplement}}
              div(v-if="document.institutionZipCode || document.institutionCity") {{document.institutionZipCode}} {{document.institutionCity}}
          li(v-if="!documentOptions.hideUser").mt-2
            UserInfoDocument(:preview="true")
          li(v-if="collaboratorInfo && !documentOptions.hideCollaboratorInCharge").mt-2
            div
              span.text-primary.font-weight-bold Dossier suivi par : 
            div
              span.font-weight-bold {{ collaboratorInfo.firstName }} {{ collaboratorInfo.lastName }}
            div
              span {{ collaboratorInfo.email ? "E-mail : " + collaboratorInfo.email : "" }}
            div
              span {{ collaboratorInfo.phoneNumber ? "Tél : " + collaboratorInfo.phoneNumber : "" }}
      b-col.col-sm-6(class="invoiceHeaderRight")
        div(style="min-height:135px")
          ul(style="height:fit-content")
            li 
              h3(:style="'color:'+document.institutionColor+' !important'") {{ natureSimplifiedTranslate(document.nature) }} n° {{ document.documentReference ?  document.documentReference : 'en attente' }}
                span(style='font-weight:300')
            li(v-if="document.nature == 2 || document.nature == 3 || document.nature == 4")
              div(v-if="(document.nature==3 || document.nature==4)  && isLastSituationInvoice") Facture finale
              div(v-else) {{ natureTranslate(document.nature)}}{{ document.nature == 2 || document.nature == 3 || document.nature == 4  ? (document.documentStep ? ` #${document.documentStep}`: '') : '' }}
            li 
              SelectDate(keyValue="documentDate" @setValue="setDocumentDate" documentDetailKey="documentDate" :preview="true" text="En date du ")
            li(v-if="initialDocument && document.quoteId && !documentOptions.hideInitialQuote && (document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4)")
              span Provenant du devis : {{ initialDocument.initialDocumentNature === 'quote' ? initialDocument.documentReference : initialDocument.quote.documentReference }}
            li(v-if="initialDocument && document.orderFormCustomerId && !documentOptions.hideInitialOrderFormCustomer && (document.nature == 1 || document.nature == 2 || document.nature == 3 || document.nature == 4)")
              span Provenant de la commande : {{ initialDocument.initialDocumentNature === 'order-form-customer' ? initialDocument.documentReference : initialDocument.orderFormCustomer.documentReference }}
            li(v-if="document.nature == 0")
              SelectCounterDate(keyValue="limitDate" @setValue="setSelectCounterDate" :preview="true" text="Valable jusqu'au ")
            li(v-if="document.type == 2 && document.nature == 0")
              SelectDateCEE(keyValue="technicalVisitDate" @setValue="setDocumentTechnicalVisitDate" :preview="true" text="Visite technique le ")
            li.mt-1(v-if="(document.type == 3 || document.type == 2) && document.nature == 0 && document.quoteBTP && (document.quoteBTP.workStartDate || document.quoteBTP.estimatedDurationNumber || document.quoteBTP.estimatedDurationType)")
              p {{ document.quoteBTP.workStartDate ? 'Début des travaux : ' + formatDate(document.quoteBTP.workStartDate) : '' }}{{ document.quoteBTP.estimatedDurationNumber ? ' - Durée estimée : ' + document.quoteBTP.estimatedDurationNumber + ' ' + translateDurationType(document.quoteBTP.estimatedDurationType) + (document.quoteBTP.estimatedDurationNumber > 1 && document.quoteBTP.estimatedDurationType != 3 ? 's' : '') : ''}}
        div(v-if="!documentOptions.hideCompany")
          SelectCompany(:preview="true")
        div(v-if="!documentOptions.hideAffair")
          SelectAffair(:preview="true")
    b-row
      b-col.mb-0(cols="12" class="align-items-center invoiceDescription" style="margin-top: 10px;")
        InvoiceDescription(:key="'InvoiceDescription_'+document.id" :preview="true")
    v-style
      | table:after { background-color: {{document.institutionColor}} !important;}
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import SelectCompany from "@/components/invoice/builder/SelectCompany";
import SelectAffair from "@/components/invoice/builder/SelectAffair";
import SelectDate from "@/components/invoice/builder/SelectDate";
import SelectDateCEE from "@/components/invoice/builder/SelectDateCEE";
import SelectDateWork from "@/components/invoice/builder/SelectDateWork";
import SelectPeriod from "@/components/invoice/builder/SelectPeriod";
import InvoiceDescription from "@/components/invoice/builder/InvoiceDescription";
import SelectCounterDate from "@/components/invoice/builder/SelectCounterDate";
// import SelectValidityDate from '@/components/invoice/builder/SelectValidityDate'
import UserInfoDocument from "@/components/invoice/builder/UserInfoDocument";
import {
  natureTranslate,
  natureSimplifiedTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";
export default {
  data() {
    return {
      collaboratorInfo: null,
      options: [
        { label: "Heure", id: 0 },
        { label: "Jour", id: 1 },
        { label: "Semaine", id: 2 },
        { label: "Mois", id: 3 },
        { label: "Année", id: 4 },
      ],
    };
  },
  mounted() {
    this.setCollaboratorInfo();
  },
  methods: {
    ...mapActions([
      "updateQuote",
      "updateInvoice",
      "getCompanyById",
      "getCollaboratorById",
    ]),
    natureTranslate,
    natureSimplifiedTranslate,
    formatDate,
    translateDurationType(id) {
      if (id != null) {
        return this.options[id].label.toLowerCase();
      } else {
        return null;
      }
    },
    setDocumentDate(date) {
      this.document = {
        ...this.document,
        documentDate: date,
      };
      this.saveAction();
    },
    setDocumentTechnicalVisitDate(date) {
      this.document.quoteCEE = {
        ...this.document.quoteCEE,
        technicalVisitDate: date,
      };
      this.saveAction();
    },
    setSelectCounterDate(date) {
      this.document = {
        ...this.document,
        limitDate: date,
      };
      this.saveAction();
    },
    setWorkStartDate(date) {
      this.document.quoteBTP = {
        ...this.document.quoteBTP,
        workStartDate: date,
      };
      this.saveAction();
    },
    setWorkEndDate(durationNumber, durationType) {
      this.document.quoteBTP = {
        ...this.document.quoteBTP,
        estimatedDurationNumber: durationNumber,
        estimatedDurationType: durationType,
      };
      this.saveAction();
    },
    getDefaultAddressCompany(company) {
      return new Promise((resolve, reject) => {
        company.addresses.find((adress) => {
          if (adress.isDefault) {
            let adressReturn = {
              ...adress,
              name: company.name,
              firstName: company.firstName,
              lastName: company.lastName,
            };
            resolve(adressReturn);
          }
        });
      });
    },
    async setCollaboratorInfo() {
      if (this.document.companyId) {
        await this.getCompanyById({ companyId: this.document.companyId }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
          }
        );
      } else {
        this.collaboratorInfo = null;
      }
    },
    async setSelectCompany(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.document = {
          ...this.document,
          companyId: company.id,
          companyName: company.name,
          companyFirstName: company.firstName,
          companyLastName: company.lastName,
          companyAddress: company.address,
          companyCity: company.city,
          companyZipCode: company.zipCode,
          companyCountry: company.country,
          companyMail: company.email,
          companyPhoneNumber: company.phoneNumber,
          companyTva: company.tvaNumber,
          companySiret: company.siret,
        };
        //this.saveAction();
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.document = {
                  ...this.document,
                  companyId: company.id,
                  companyName: company.name,
                  companyFirstName: company.firstName,
                  companyLastName: company.lastName,
                  companyAddress: res.address,
                  companyAddressComplement: res.addressComplement,
                  companyCity: res.city,
                  companyZipCode: res.zipCode,
                  companyCountry: res.country,
                  companyMail: company.email,
                  companyPhoneNumber: company.phoneNumber,
                  companyTva: company.tvaNumber,
                  companySiret: company.siret,
                };
              });
            } else {
              this.document = {
                ...this.document,
                companyId: company.id,
                companyName: company.name,
                companyFirstName: company.firstName,
                companyLastName: company.lastName,
                companyAddress: null,
                companyAddressComplement: null,
                companyCity: null,
                companyZipCode: null,
                companyCountry: null,
                companyMail: company.email,
                companyPhoneNumber: company.phoneNumber,
                companyTva: company.tvaNumber,
                companySiret: company.siret,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    setSelectAffair(affair) {
      this.document = {
        ...this.document,
        affairId: affair.id,
        affairName: affair.name,
        affairAddress: affair.address,
        affairAddressComplement: affair.addressComplement,
        affairZipCode: affair.zipCode,
        affairCity: affair.city,
        affairCountry: affair.country,
      };
      this.saveAction();
    },
    setInvoiceDescription(description) {
      this.document = {
        ...this.document,
        description: description,
      };
      this.saveAction();
    },
    saveAction() {
      if (this.document.nature == 0) {
        this.updateQuote({ quote: this.document, updateState: false });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.updateInvoice({ invoice: this.document, updateState: false });
      }
    },
  },
  computed: {
    ...mapGetters([
      "institutionSelected",
      "companiesList",
      "affairsList",
      "natureList",
      "workspaceSelected",
      "getDocumentDetails",
      "initialDocument",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
    isLastSituationInvoice() {
      return this.getDocumentDetails
        .filter(
          (el) =>
            el.type != 0 &&
            el.type != 1 &&
            el.type != 2 &&
            el.type != 11 &&
            el.type != 12 &&
            el.type != 13 &&
            el.type != 18 &&
            el.type != 17 &&
            el.type != 16 &&
            el.type != 15 &&
            el.type != 14 &&
            !el.isOption
        )
        .every(
          (el) =>
            el.situationProgress == 100 ||
            el.situationProgressQuantity == el.quantity
        );
    },
  },
  components: {
    "v-select": vSelect,
    SelectCompany,
    SelectAffair,
    SelectDate,
    SelectPeriod,
    InvoiceDescription,
    SelectCounterDate,
    // SelectValidityDate,
    SelectDateWork,
    UserInfoDocument,
    SelectDateCEE,
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>
