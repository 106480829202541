<template>
  <div
    v-if="!preview && !disabled"
    class="cursor-pointer"
    @mouseover="mouseOn = true"
    @mouseleave="mouseOn = false"
  >
    <div @click="toggleDescription(!documentOptions.showDescription)">
      <span class="text-primary font-weight-bold no-printting">
        {{
          documentOptions.showDescription == true
            ? "- Masquer la description"
            : "+ Ajouter une description"
        }}
      </span>
    </div>
    <quill-editor
      @focus="focusFunction(true)"
      @blur="setValue"
      :key="'description' + document.id"
      v-show="documentOptions.showDescription"
      class="editor quill-object"
      :class="editFocus || mouseOn ? 'edit' : 'no-edit'"
      v-model="document.description"
      :options="editorOption"
    />
  </div>
  <div v-else>
    <span
      v-if="documentOptions.showDescription"
      v-html="document.description"
    ></span>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    preview: {
      default: false,
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      mouseOn: false,
      dataOrigine: null,
      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
      editFocus: false,
    };
  },
  created() {
    this.dataOrigine = this.document.description
      ? JSON.parse(JSON.stringify(this.document.description))
      : null;
  },
  computed: {
    ...mapGetters(["document"]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "updateQuote",
      "updateInvoice",
      "updateCredit",
      "updateQuoteOption",
      "updateInvoiceOption",
      "updateCreditOption",
    ]),
    focusFunction(val) {
      this.editFocus = true;
    },
    setValue() {
      if (this.document.description != this.dataOrigine) {
        this.$emit("setValue", this.document.description);
        this.editFocus = false;
      }
    },
    toggleDescription(val) {
      this.documentOptions.showDescription = val;
      if (this.document.nature == 0) {
        this.updateQuoteOption({
          quoteOption: this.documentOptions,
          updateState: false,
        });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.updateInvoiceOption({
          invoiceOption: this.documentOptions,
          updateState: false,
        });
      } else if (this.document.nature == 5 || this.document.nature == 6) {
        this.updateCreditOption({
          creditOption: this.documentOptions,
          updateState: false,
        });
      }
    },
  },
  components: {
    quillEditor,
  },
};
</script>
