<template lang="pug">
  div(style='page-break-inside: avoid')
    span.mt-1(v-html="institutionSettingsActive.termsOfSales")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["institutionSettingsActive"]),
  },
};
</script>
