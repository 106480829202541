<template lang="pug">
  <div v-if="isLoadingDocumentPDF" class="h-100">
    <div class="text-center flex-center h-100">
      <div class="loading-bg-inner">
        <div class="loader">
          <div class="outer"></div>
          <div class="middle"></div>
          <div class="inner"></div>
        </div>
      </div>
      <div class="mt-5">
        <br /><br /><br />
        | Génération du PDF
      </div>
    </div>
  </div>
  iframe.pdfViewver(:src="documentPDF" v-else style="width: 100%;height: 100%; border: none;") 
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
  computed: {
    ...mapGetters([
      "documentPDF",
      "isLoadingDocumentPDF"
    ])
  },
}
</script>