<template lang="pug">
  div(v-if="isWorkLineWithElement() || disabled")
    div.content-span-document-detail(:style="marginNegativeStyle()")
      span(v-model="line[dataKey]") 
      span {{invoiceHeader[dataKey].prefix }} 
      span {{invoiceHeader[dataKey].numberToFixed ? (line[dataKey]).toFixed(invoiceHeader[dataKey].numberToFixed) : line[dataKey]}}
      span {{invoiceHeader[dataKey].unit}}
  .marginCol(v-else-if="!preview")
    .marginCol-content
      input.w-100.margin-detail(@focus="emitEditFocus(true)" @blur="actionsOnBlur" :style="line[dataKey] < 0 ? 'color:red!important' : ''" :disabled="line.isDisabled" :state="line['disbursedNetHt'] >= 0 && line['disbursedNetHt'] != null ? null : false" type='number' :class="edit ? 'edit' : 'no-edit' " v-model="line[dataKey]")
      .hide-margin-detail
        div.d-flex
          .w-50.mr-1
            b-form-group.standard(label='Déboursé Sec U. HT' label-for='disbursedNetHt')
              b-form-input#disbursedNetHt.edit(@blur="change" type='number' min='0' v-model="line['disbursedNetHt']" :style="marginNegativeStyle()")
          .w-50
            b-form-group.standard(label='Marge brute U. HT' label-for='marginBrutHt')
              b-form-input#marginBrutHt.edit(@blur="change" type='number' min='0' v-model="line['marginBrutHt']" :style="marginNegativeStyle()")
  div(v-else)
      span {{line[dataKey]}}
</template>

<script>
import { formatNumber } from "@/types/api-orisis/library/FormatOperations.ts";
import {
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPopover,
  BRow,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["document", "deduction"]),
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
  },
  components: {
    BPopover,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    preview: {
      default: false,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    invoiceHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    disabled: {
      default: false,
    },
  },
  mounted() {
    // this.change(false)
    this.checkMarginStatus();
  },
  methods: {
    ...mapActions(["checkMarginStatus", "updateDocumentDetail"]),
    formatNumber,
    isWorkLineWithElement() {
      if (this.dataKey == "marginRate") {
        if (this.line.type == 6) {
          let workLineElements = this.documentDetails.filter(
            (el) => el.parentId == this.line.id
          );
          if (workLineElements && workLineElements.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    change(bool = true) {
      this.line["disbursedNetHt"] = formatNumber(this.line["disbursedNetHt"]);
      this.line["marginBrutHt"] = formatNumber(
        this.line["unitPriceHt"] - this.line["disbursedNetHt"]
      );
      this.line["marginRate"] = formatNumber(
        this.line["unitPriceHt"] == 0
          ? 0
          : (this.line["marginBrutHt"] / this.line["unitPriceHt"]) * 100
      );
      this.line["total"] = formatNumber(
        this.line["unitPriceHt"] * this.line["quantity"]
      );
      if (bool) {
        this.updateDocumentDetail({ documentDetail: this.line });
      }

      // On traite les lignes de composant d'un ouvrage
      if (
        this.line.type == 14 ||
        this.line.type == 15 ||
        this.line.type == 16 ||
        this.line.type == 17 ||
        this.line.type == 18
      ) {
        let workLine = this.documentDetails.find(
          (el) => el.id == this.line.parentId
        );
        let workLineElements = this.documentDetails.filter(
          (el) => el.parentId == this.line.parentId
        );
        let totalPriceHt = 0;
        let totalDryDisbursed = 0;
        for (let index = 0; index < workLineElements.length; index++) {
          const element = workLineElements[index];
          totalPriceHt += formatNumber(element.unitPriceHt * element.quantity);
          totalDryDisbursed += formatNumber(
            element.disbursedNetHt * element.quantity
          );
        }
        workLine.unitPriceHt = formatNumber(totalPriceHt);
        workLine.disbursedNetHt = formatNumber(totalDryDisbursed);
        workLine.marginBrutHt = formatNumber(
          workLine.unitPriceHt - workLine.disbursedNetHt
        );
        workLine.marginRate = formatNumber(
          workLine.unitPriceHt == 0
            ? 0
            : (workLine.marginBrutHt / workLine.unitPriceHt) * 100
        );

        // On met à jour le total
        workLine.total = formatNumber(workLine.unitPriceHt * workLine.quantity);
        this.checkMarginStatus();
        if (bool) {
          this.updateDocumentDetail({ documentDetail: workLine });
        }
      }
    },
    marginRateChange() {
      if (this.line["marginRate"]) {
        // Ici on change le prix d'achat en fonction du de la marge par défaut
        // Arrondi à 2 chiffre après la virgule
        this.line["marginRate"] =
          Math.round(this.line["marginRate"] * 100) / 100;

        // Change la marge brute HT
        this.line["marginBrutHt"] =
          Math.round(
            this.line["unitPriceHt"] * (this.line["marginRate"] / 100) * 100
          ) / 100;

        // Change le prix de revient HT
        this.line["disbursedNetHt"] =
          Math.round(
            (this.line["unitPriceHt"] - this.line["marginBrutHt"]) * 100
          ) / 100;

        this.checkMarginStatus();
        // this.emitEditFocus(true);
        this.updateDocumentDetail({ documentDetail: this.line });
      } else {
        this.line["disbursedNetHt"] = null;
        this.line["marginBrutHt"] = null;
        this.checkMarginStatus();
        // this.emitEditFocus(true);
        this.updateDocumentDetail({ documentDetail: this.line });
      }
    },
    actionsOnBlur() {
      this.$emit("emitEditFocus", false);
      this.marginRateChange();
    },
    disbursedNetHtChange(val) {
      if (val) {
        // Change le prix unitaire HT
        //this.line['unitPriceHt'] = Math.round((this.line['disbursedNetHt'] + this.line['marginBrutHt']) * 100) / 100;
        this.line["disbursedNetHt"] = Math.round(val * 100) / 100;
        // Change la marge brute HT
        this.line["marginBrutHt"] =
          Math.round(
            (this.line["unitPriceHt"] - this.line["disbursedNetHt"]) * 100
          ) / 100;
        // Change le pourcentage de marge
        this.line["marginRate"] = (
          (Math.round(
            ((this.line["unitPriceHt"] - this.line["disbursedNetHt"]) * 100) /
              100
          ) /
            this.line["unitPriceHt"]) *
          100
        ).toFixed(2);
      } else {
        this.line["marginRate"] = 0;
        this.line["marginBrutHt"] = 0;
        this.line["disbursedNetHt"] = 0;
      }

      this.checkMarginStatus();
      this.updateDocumentDetail({ documentDetail: this.line });
    },
    marginBrutHtChange(val) {
      // Change le prix unitaire
      this.line["unitPriceHt"] =
        Math.round(
          (Number(this.line["disbursedNetHt"]) +
            Number(this.line["marginBrutHt"])) *
            100
        ) / 100;
      // Change le pourcentage de marge
      this.line["marginRate"] = (
        (Math.round(
          ((Number(this.line["unitPriceHt"]) -
            Number(this.line["disbursedNetHt"])) *
            100) /
            100
        ) /
          Number(this.line["unitPriceHt"])) *
        100
      ).toFixed(2);

      this.checkMarginStatus();
      this.updateDocumentDetail({ documentDetail: this.line });
    },
    marginNegativeStyle() {
      if (
        this.dataKey == "disbursedNetHt" ||
        this.dataKey == "unitPriceHt" ||
        this.dataKey == "marginRate"
      ) {
        if (this.line.unitPriceHt - this.line.disbursedNetHt < 0) {
          return "color:red !important;";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
  },
};
</script>
