<template lang="pug">
    .row
        .col-md-6.toolbar.w-100.d-flex
            b-button.mr-1.button-tools(@click="newLine({type:4, unit : 1, indexArray: lastDocumentLine, review: false})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'" :style="disabled ? 'pointer-events: none;' : ''" ) 
                div(v-if="isLoadingLine4")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Fourniture
            b-button.mr-1.button-tools(@click="newLine({type: 5, unit: 2, indexArray: lastDocumentLine, review: false})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'" :style="disabled ? 'pointer-events: none;' : ''") 
                div(v-if="isLoadingLine5")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Main d'oeuvre
            b-button.mr-1.button-tools(@click="newLine({type : 6, unit: 1, indexArray: lastDocumentLine, review: false})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'" :style="disabled ? 'pointer-events: none;' : ''") 
                div(v-if="isLoadingLine6")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Ouvrage
            b-dropdown.mr-1(text="..." :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'" :style="disabled ? 'pointer-events: none;' : ''") 
                b-dropdown-item(@click="newLine({type: 7, unit: 1, indexArray: lastDocumentLine, review: false})") Sous-traitance
                b-dropdown-item(@click="newLine({type: 8, unit: 1, indexArray: lastDocumentLine, review: false})") Matériel
                b-dropdown-item(@click="newLine({type: 9, unit: 1, indexArray: lastDocumentLine, review: false})") Autre
        .col-md-6.toolbar.w-100.d-flex.align-right.justify-content-end
            b-dropdown.mr-1(v-if="includeSection" text="Section ..." :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'" :style="disabled ? 'pointer-events: none;' : ''") 
                b-dropdown-item(@click="newLine({type: 0, indexArray: lastDocumentLine, review: false})") Section
                b-dropdown-item(@click="newLine({type: 11, indexArray: lastDocumentLine, review: false})") Sous-Section
            b-button.mr-1.button-tools(v-else @click="newLine({type:0, indexArray: lastDocumentLine, review: false})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-dark'" :style="disabled ? 'pointer-events: none;' : ''") 
                div(v-if="isLoadingLine0")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    span Section
            b-button.mr-1.button-tools(@click="addSubTotalLine({indexArray: lastDocumentLine})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-dark'" :style="disabled ? 'pointer-events: none;' : ''") 
                div(v-if="isLoadingLine13")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    span Sous-total
            b-button.mr-1.button-tools(@click="newLine({type:1, indexArray: lastDocumentLine, review: false})" :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-dark'" :style="disabled ? 'pointer-events: none;' : ''") 
                div(v-if="isLoadingLine1")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    span Texte
            b-dropdown( text="Saut ..." :disabled="disabled" :variant="disabled ? 'outline-secondary' : 'outline-primary'" :style="disabled ? 'pointer-events: none;' : ''") 
                b-dropdown-item(@click="newLine({type: 12, indexArray: lastDocumentLine, review: false})") Saut de ligne
                b-dropdown-item(@click="newLine({type: 2, indexArray: lastDocumentLine, review: false})") Saut de page
</template>
<script>
import { calculateSubTotalLine } from "@/types/api-orisis/library/DocumentOperations";
import { BButton, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      isLoadingLine0: false,
      isLoadingLine1: false,
      isLoadingLine2: false,
      isLoadingLine3: false,
      isLoadingLine4: false,
      isLoadingLine5: false,
      isLoadingLine6: false,
      isLoadingLine7: false,
      isLoadingLine8: false,
      isLoadingLine9: false,
      isLoadingLine10: false,
      isLoadingLine11: false,
      isLoadingLine12: false,
      isLoadingLine13: false,
    };
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters(["document", "institutionSettingsActive"]),
    lastDocumentLine() {
      return this.$store.getters.getDocumentDetails.length - 1;
    },
    includeSection() {
      return (
        this.$store.getters.getDocumentDetails.filter((el) => el.type == 0)
          .length > 0
      );
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
  },
  methods: {
    calculateSubTotalLine,
    ...mapActions(["addLine"]),
    async addSubTotalLine(line) {
      this.isLoadingLine13 = true;
      if (this.document.nature == 0) {
        await this.calculateSubTotalLine(
          {
            ...line,
            type: 13,
            review: false,
            quoteId: this.document.id,
            invoiceId: null,
            referencielTvaId: 5,
            quantity: 1,
            isOption: false,
            description: "Sous-total",
          },
          false,
          true
        );
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        await this.calculateSubTotalLine(
          {
            ...line,
            type: 13,
            review: false,
            invoiceId: this.document.id,
            quoteId: null,
            referencielTvaId: 5,
            quantity: 1,
            isOption: false,
            description: "Sous-total",
          },
          false,
          true
        );
      }
      this.isLoadingLine13 = false;
    },
    newLine(line) {
      if (line.type == 0) this.isLoadingLine0 = true;
      if (line.type == 1) this.isLoadingLine1 = true;
      if (line.type == 2) this.isLoadingLine2 = true;
      if (line.type == 3) this.isLoadingLine3 = true;
      if (line.type == 4) this.isLoadingLine4 = true;
      if (line.type == 5) this.isLoadingLine5 = true;
      if (line.type == 6) this.isLoadingLine6 = true;
      if (line.type == 7) this.isLoadingLine7 = true;
      if (line.type == 8) this.isLoadingLine8 = true;
      if (line.type == 9) this.isLoadingLine9 = true;
      if (line.type == 10) this.isLoadingLine10 = true;
      if (line.type == 11) this.isLoadingLine11 = true;
      if (line.type == 12) this.isLoadingLine12 = true;

      if((line.type == 4 || line.type == 14) && this.institutionSettingsActive.supplyMarginDefault && this.institutionSettingsActive.supplyMarginDefault>0){
        line.marginCoefficient = this.institutionSettingsActive.supplyMarginDefault
      }else if((line.type == 5 || line.type == 17) && this.institutionSettingsActive.manPowerMarginDefault && this.institutionSettingsActive.manPowerMarginDefault>0){
        line.marginCoefficient = this.institutionSettingsActive.manPowerMarginDefault
      }else if(line.type == 6 && this.institutionSettingsActive.laborMarginDefault && this.institutionSettingsActive.laborMarginDefault>0){
        line.marginCoefficient = Number(this.institutionSettingsActive.laborMarginDefault)
      }else if((line.type == 7 || line.type == 15) && this.institutionSettingsActive.subContractingMarginDefault && this.institutionSettingsActive.subContractingMarginDefault>0){
        line.marginCoefficient = this.institutionSettingsActive.subContractingMarginDefault
      }else if((line.type == 8 || line.type == 16) && this.institutionSettingsActive.equipementMarginDefault && this.institutionSettingsActive.equipementMarginDefault>0){
        line.marginCoefficient = this.institutionSettingsActive.equipementMarginDefault
      }else if((line.type == 9 || line.type == 18) && this.institutionSettingsActive.variousMarginDefault && this.institutionSettingsActive.variousMarginDefault>0){
        line.marginCoefficient = this.institutionSettingsActive.variousMarginDefault
      }else{
        line.marginCoefficient = 1
      }

      if (this.document.nature == 0) {
        this.addLine({
          documentDetail: {
            ...line,
            quoteId: this.document.id,
            invoiceId: null,
            referencielTvaId: this.documentOptions.isReverseCharge
              ? 5
              : this.institutionSettingsActive.referencielTvas.find(
                  (elem) => elem.isDefault == true
                ).referencielTva.id,
          },
          indexArray: line.indexArray,
          isCredit: false,
          review: line.review,
        })
          .then((res) => {
            if (line.type == 0) this.isLoadingLine0 = false;
            if (line.type == 1) this.isLoadingLine1 = false;
            if (line.type == 2) this.isLoadingLine2 = false;
            if (line.type == 3) this.isLoadingLine3 = false;
            if (line.type == 4) this.isLoadingLine4 = false;
            if (line.type == 5) this.isLoadingLine5 = false;
            if (line.type == 6) this.isLoadingLine6 = false;
            if (line.type == 7) this.isLoadingLine7 = false;
            if (line.type == 8) this.isLoadingLine8 = false;
            if (line.type == 9) this.isLoadingLine9 = false;
            if (line.type == 10) this.isLoadingLine10 = false;
            if (line.type == 11) this.isLoadingLine11 = false;
            if (line.type == 12) this.isLoadingLine12 = false;
            if (line.type == 13) this.isLoadingLine13 = false;
          })
          .catch((err) => {
            if (line.type == 0) this.isLoadingLine0 = false;
            if (line.type == 1) this.isLoadingLine1 = false;
            if (line.type == 2) this.isLoadingLine2 = false;
            if (line.type == 3) this.isLoadingLine3 = false;
            if (line.type == 4) this.isLoadingLine4 = false;
            if (line.type == 5) this.isLoadingLine5 = false;
            if (line.type == 6) this.isLoadingLine6 = false;
            if (line.type == 7) this.isLoadingLine7 = false;
            if (line.type == 8) this.isLoadingLine8 = false;
            if (line.type == 9) this.isLoadingLine9 = false;
            if (line.type == 10) this.isLoadingLine10 = false;
            if (line.type == 11) this.isLoadingLine11 = false;
            if (line.type == 12) this.isLoadingLine12 = false;
            if (line.type == 13) this.isLoadingLine13 = false;
          });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.addLine({
          documentDetail: {
            ...line,
            invoiceId: this.document.id,
            quoteId: null,
            referencielTvaId: this.documentOptions.isReverseCharge
              ? 5
              : this.institutionSettingsActive.referencielTvas.find(
                  (elem) => elem.isDefault == true
                ).referencielTva.id,
          },
          indexArray: line.indexArray,
          isCredit: false,
          review: line.review,
        })
          .then((res) => {
            if (line.type == 0) this.isLoadingLine0 = false;
            if (line.type == 1) this.isLoadingLine1 = false;
            if (line.type == 2) this.isLoadingLine2 = false;
            if (line.type == 3) this.isLoadingLine3 = false;
            if (line.type == 4) this.isLoadingLine4 = false;
            if (line.type == 5) this.isLoadingLine5 = false;
            if (line.type == 6) this.isLoadingLine6 = false;
            if (line.type == 7) this.isLoadingLine7 = false;
            if (line.type == 8) this.isLoadingLine8 = false;
            if (line.type == 9) this.isLoadingLine9 = false;
            if (line.type == 10) this.isLoadingLine10 = false;
            if (line.type == 11) this.isLoadingLine11 = false;
            if (line.type == 12) this.isLoadingLine12 = false;
            if (line.type == 13) this.isLoadingLine13 = false;
          })
          .catch((err) => {
            if (line.type == 0) this.isLoadingLine0 = false;
            if (line.type == 1) this.isLoadingLine1 = false;
            if (line.type == 2) this.isLoadingLine2 = false;
            if (line.type == 3) this.isLoadingLine3 = false;
            if (line.type == 4) this.isLoadingLine4 = false;
            if (line.type == 5) this.isLoadingLine5 = false;
            if (line.type == 6) this.isLoadingLine6 = false;
            if (line.type == 7) this.isLoadingLine7 = false;
            if (line.type == 8) this.isLoadingLine8 = false;
            if (line.type == 9) this.isLoadingLine9 = false;
            if (line.type == 10) this.isLoadingLine10 = false;
            if (line.type == 11) this.isLoadingLine11 = false;
            if (line.type == 12) this.isLoadingLine12 = false;
            if (line.type == 13) this.isLoadingLine13 = false;
          });
      } else if (this.document.nature == 6) {
        this.addLine({
          documentDetail: {
            ...line,
            invoiceId: null,
            quoteId: null,
            creditId: this.document.id,
            referencielTvaId: this.documentOptions.isReverseCharge
              ? 5
              : this.institutionSettingsActive.referencielTvas.find(
                  (elem) => elem.isDefault == true
                ).referencielTva.id,
          },
          indexArray: line.indexArray,
          isCredit: true,
          review: line.review,
        })
          .then((res) => {
            if (line.type == 0) this.isLoadingLine0 = false;
            if (line.type == 1) this.isLoadingLine1 = false;
            if (line.type == 2) this.isLoadingLine2 = false;
            if (line.type == 3) this.isLoadingLine3 = false;
            if (line.type == 4) this.isLoadingLine4 = false;
            if (line.type == 5) this.isLoadingLine5 = false;
            if (line.type == 6) this.isLoadingLine6 = false;
            if (line.type == 7) this.isLoadingLine7 = false;
            if (line.type == 8) this.isLoadingLine8 = false;
            if (line.type == 9) this.isLoadingLine9 = false;
            if (line.type == 10) this.isLoadingLine10 = false;
            if (line.type == 11) this.isLoadingLine11 = false;
            if (line.type == 12) this.isLoadingLine12 = false;
            if (line.type == 13) this.isLoadingLine13 = false;
          })
          .catch((err) => {
            if (line.type == 0) this.isLoadingLine0 = false;
            if (line.type == 1) this.isLoadingLine1 = false;
            if (line.type == 2) this.isLoadingLine2 = false;
            if (line.type == 3) this.isLoadingLine3 = false;
            if (line.type == 4) this.isLoadingLine4 = false;
            if (line.type == 5) this.isLoadingLine5 = false;
            if (line.type == 6) this.isLoadingLine6 = false;
            if (line.type == 7) this.isLoadingLine7 = false;
            if (line.type == 8) this.isLoadingLine8 = false;
            if (line.type == 9) this.isLoadingLine9 = false;
            if (line.type == 10) this.isLoadingLine10 = false;
            if (line.type == 11) this.isLoadingLine11 = false;
            if (line.type == 12) this.isLoadingLine12 = false;
            if (line.type == 13) this.isLoadingLine13 = false;
          });
      }
    },
  },
  mounted() {
    this.isLoadingLine0 = false;
    this.isLoadingLine1 = false;
    this.isLoadingLine2 = false;
    this.isLoadingLine3 = false;
    this.isLoadingLine4 = false;
    this.isLoadingLine5 = false;
    this.isLoadingLine6 = false;
    this.isLoadingLine7 = false;
    this.isLoadingLine8 = false;
    this.isLoadingLine9 = false;
    this.isLoadingLine10 = false;
    this.isLoadingLine11 = false;
    this.isLoadingLine12 = false;
    this.isLoadingLine13 = false;
  },
};
</script>
